import { createSlice, current } from '@reduxjs/toolkit'

const filterValues = (array: any, value: string) => {
  const newFilters = array.filter((info: any) => info !== value)
  return newFilters
}

interface I_profileFilterState {
  status: []
  eventType: []
  chains: []
  categories: []
  collections: []
  price: {
    priceType: string
    minPrice: number | string
    maxPrice: number | string
    priceLabel: string
  }
  onSaleIn: []
  isBundle: string
  order: string
  layout: number
  search: string
  isKycModalOpen: boolean
}

const initialState: I_profileFilterState = {
  status: [],
  eventType: [],
  chains: [],
  categories: [],
  collections: [],
  price: {
    priceType: 'usd',
    minPrice: '',
    maxPrice: '',
    priceLabel: 'USD',
  },
  onSaleIn: [],
  isBundle: 'All_Items',
  order: '',
  layout: 4,
  search: '',
  isKycModalOpen: false,
}

const profileFilterSlice = createSlice({
  name: 'profileFilters',
  initialState,
  reducers: {
    setStatusValues: (state: any, { payload }: any) => {
      const status = current(state.status)
      const response = status?.includes(payload)
      if (response) {
        const res = filterValues(status, payload)
        state.status = [...res]
      } else {
        state.status = [...state.status, payload]
      }
    },
    setEventTypeValues: (state: any, { payload }: any) => {
      const eventType = current(state.eventType)
      const response = eventType?.includes(payload)
      if (response) {
        const res = filterValues(eventType, payload)
        state.eventType = [...res]
      } else {
        state.eventType = [...state.eventType, payload]
      }
    },
    setChainValues: (state: any, { payload }: any) => {
      const chains = current(state.chains)
      const response = chains?.includes(payload)

      if (response) {
        const res = filterValues(chains, payload)
        state.chains = [...res]
      } else {
        state.chains = [...state.chains, payload]
      }
    },
    setCategoryValues: (state: any, { payload }: any) => {
      const categories = current(state.categories)
      const response = categories?.includes(payload)
      if (response) {
        const res = filterValues(categories, payload)
        state.categories = [...res]
      } else {
        state.categories = [...state.categories, payload]
      }
    },
    setCollectionValues: (state: any, { payload }: any) => {
      const collections = current(state.collections)
      const response = collections?.includes(payload)
      if (response) {
        const res = filterValues(collections, payload)
        state.collections = [...res]
      } else {
        state.collections = [...state.collections, payload]
      }
    },
    setPriceValues: (state: any, { payload }: any) => {
      state.price.priceType = payload.priceType
      state.price.minPrice = payload.minPrice
      state.price.maxPrice = payload.maxPrice
      state.price.priceLabel = payload.priceLabel
    },
    removePriceValues: (state: any, { payload }: any) => {
      state.price.minPrice = ''
      state.price.maxPrice = ''
      state.price.priceType = 'usd'
      state.price.priceLabel = 'USD'
    },
    setOnSaleInValues: (state: any, { payload }: any) => {
      const onSaleIn = current(state.onSaleIn)
      const response = onSaleIn?.includes(payload)

      if (response) {
        const res = filterValues(onSaleIn, payload)
        state.onSaleIn = [...res]
      } else {
        state.onSaleIn = [...onSaleIn, payload]
      }
    },
    setBundles: (state: any, { payload }: any) => {
      state.isBundle = payload
    },
    setFilterDropdown: (state: any, { payload }: any) => {
      state.order = payload
    },
    setFilterLayout: (state: any, { payload }: any) => {
      state.layout = payload
    },
    setSearchFilterValues: (state: any, { payload }: any) => {
      state.search = payload
    },
    setKycModalOpen: (state: any, { payload }: any) => {
      state.isKycModalOpen = payload
    },
    clearAllFilterValues: (state: any, { payload }: any) => {
      state.status = []
      state.eventType = []
      state.chains = []
      state.categories = []
      state.collections = []
      state.price.priceType = 'usd'
      state.price.minPrice = ''
      state.price.maxPrice = ''
      state.price.priceLabel = 'USD'
      state.onSaleIn = []
      state.search = ''
    },
    clearFilterforActivity: (state: any, { payload }: any) => {
      state.status = []
      state.eventType = []
      state.categories = []
      state.price.priceType = 'usd'
      state.price.minPrice = ''
      state.price.maxPrice = ''
      state.price.priceLabel = 'USD'
      state.onSaleIn = []
      state.search = ''
    },
    clearEventTypeFilter: (state: any) => {
      state.eventType = []
    },
  },
})

export const {
  setStatusValues,
  setEventTypeValues,
  setChainValues,
  setCategoryValues,
  setCollectionValues,
  setPriceValues,
  setOnSaleInValues,
  setBundles,
  setFilterDropdown,
  setFilterLayout,
  setSearchFilterValues,
  removePriceValues,
  clearAllFilterValues,
  clearFilterforActivity,
  clearEventTypeFilter,
  setKycModalOpen,
} = profileFilterSlice.actions
export default profileFilterSlice.reducer
