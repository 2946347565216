import { useGetPlatformStatusQuery } from 'logic/reactQuery/sharedService'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import NewsLetterNavbar from 'shared/components/newsLetterNavbar/NewsLetterNavbar'
import SignUpFooter from 'shared/components/signUpFooter/SignUpFooter'
import Status from 'shared/components/status/Status'
import { Footer } from '../components/footer/Footer'
import { Header } from '../components/header/Header'
interface I_Layout {
  children: React.ReactNode
}
const Layout = ({ children }: I_Layout) => {
  const router = useRouter()
  const { pathname } = router
  const { data, isLoading } = useGetPlatformStatusQuery()
  const [closeNotification, setCloseNotification] = React.useState(false)
  const [showFooter, setShowFooter] = useState(true)
  useEffect(() => {
    if (pathname === '/assets') {
      setShowFooter(false)
    } else {
      setShowFooter(true)
    }
  }, [pathname])
  return (
    <>
      {pathname === '/newsLetter' ? <NewsLetterNavbar /> : <Header />}
      {!isLoading && !closeNotification && data?.length > 0 && data[0]?.isActive && (
        <Status info={data[0]?.content} setCloseNotification={setCloseNotification} />
      )}
      {children}
      {showFooter && (
        <>
          <SignUpFooter />
          <Footer />
        </>
      )}
    </>
  )
}

export default Layout
