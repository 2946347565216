import CoinGecko from 'coingecko-api' // documentation: https://github.com/miscavage/CoinGecko-API
import { useQuery } from 'react-query'
import { CacheKeys } from './cacheKeys'
const CoinGeckoClient: any = new CoinGecko()

interface ICoinGeckoSimplePriceOptions {
  ids: string[] // ids of coins, refers to https://www.coingecko.com/en/api/documentation to check list of id
  vs_currencies: string[] //vs_currency of coins, default ['usd']
  include_24hr_vol?: boolean // [default: false] - To include 24hr volume
  include_last_updated_at?: boolean //[default: false] - To include last_updated_at of price
}

export const useCGSimplePriceQuery = (params: ICoinGeckoSimplePriceOptions) => {
  return useQuery([CacheKeys.coingecko, params], () => CoinGeckoClient.simple.price(params), {
    enabled: !!params.ids.length,
  })
}

interface ICoinGeckoSimpleTokenPriceOptions {
  assetPlatform: string // default ethereum
  contract_addresses: string[] //token's contract addresses
  vs_currencies: string[]
  include_market_cap?: boolean
  include_24hr_vol?: boolean
  include_24hr_change?: boolean
  include_last_updated_at?: boolean
}

// Omitting assetPlatform key as only ethereum is supported

export const useCGSimpleTokenPriceQuery = (
  params: Omit<ICoinGeckoSimpleTokenPriceOptions, 'assetPlatform'>,
) => {
  return useQuery([CacheKeys.coingecko, params], () =>
    // @ts-ignore
    CoinGeckoClient.simple.fetchTokenPrice(params),
  )
}
