import React, { useRef, useState } from 'react'
import NextImage from 'shared/components/nextImage/NextImage'
import Link from 'next/link'
import { useRouter } from 'next/router'
import algoliasearch from 'algoliasearch/lite'
import { AutocompleteState, createAutocomplete } from '@algolia/autocomplete-core'
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'
import config from 'config'
import { darkSearchIcon, profileUser, ethereum_icon, search_light } from 'shared/icons'

const searchClient = algoliasearch(
  config.algolia.appId || 'latency',
  config.algolia.apiKey || '6be0576ff61c053d5f9a3225e2a90f76',
)

import {
  StyledName,
  StyledBtn,
  StyledForm,
  StyledImgWrapper,
  StyledInput,
  StyledItem,
  StyledList,
  StyledPanel,
  StyledSource,
  StyledTitle,
  StyledWrapper,
  StyledCount,
} from './Autocomplete.style'
import { useAppSelector } from 'hooks/useAppSelector'
import useOutsideClick from 'hooks/useOutsideClick'
import TippyToolTip from 'shared/components/tippyTooltip/TippyToolTip'
import { handlePrecision } from 'utilities/helpers'

type TItem = {
  [key: string]: any
}

enum SourceId {
  Collections = 'Collections',
  Accounts = 'Accounts',
  Items = 'Items',
}

const getPath = (
  sourceId: string,
  options: { walletAddress?: string; collectionSlug?: string },
) => {
  switch (sourceId) {
    case SourceId.Collections:
      return `/collections/${options.collectionSlug}`
    case SourceId.Accounts:
      return `/profile/${options.walletAddress}`
    case SourceId.Items:
      return `/assets/${options.collectionSlug}`
    default:
      return '/'
  }
}

export const Autocomplete = () => {
  const router = useRouter()
  // (1) Create a React state.
  // @ts-ignore
  const [autocompleteState, setAutocompleteState] = React.useState<AutocompleteState<TItem>>({})
  const inputRef = React.useRef(null)
  const autocomplete: any = React.useMemo(
    () =>
      createAutocomplete({
        onStateChange({ state }) {
          // (2) Synchronize the Autocomplete state with the React state.
          setAutocompleteState(state)
        },

        // @ts-ignore
        getSources() {
          return [
            // (3) Use an Algolia index source.
            {
              sourceId: SourceId.Collections,
              getItems({ query }) {
                // return algolia results only if query length is greater than or equal to 3
                if (query.trim().length < 3) return []
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: config.algolia.collectionsIndex || 'instant_search',
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: '<mark>',
                        highlightPostTag: '</mark>',
                      },
                    },
                  ],
                })
              },
            },
            {
              sourceId: SourceId.Accounts,
              getItems({ query }) {
                // return algolia results only if query length is greater than or equal to 3
                if (query.trim().length < 3) return []
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: config.algolia.accountsIndex || 'instant_search',
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: '<mark>',
                        highlightPostTag: '</mark>',
                      },
                    },
                  ],
                })
              },
            },
            {
              sourceId: SourceId.Items,
              getItems({ query }) {
                // return algolia results only if query length is greater than or equal to 3
                if (query.trim().length < 3) return []
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: config.algolia.nftIndex || 'instant_search',
                      query,
                      params: {
                        hitsPerPage: 4,
                        highlightPreTag: '<mark>',
                        highlightPostTag: '</mark>',
                      },
                    },
                  ],
                })
              },
            },
          ]
        },
      }),
    [],
  )
  const { theme } = useAppSelector(state => state.theme)
  const [search, setSearch] = useState(false)
  const SearchBar = () => {
    setSearch(!search)
  }
  const searchOpenRef = useRef<any>()
  useOutsideClick({
    isOpen: autocompleteState.isOpen,
    node: searchOpenRef,
    onOutsideClick: () => {
      autocomplete.setIsOpen(false)
      autocomplete.setQuery('')
    },
  })
  return (
    <StyledWrapper {...autocomplete.getRootProps({})}>
      {/* @ts-ignore */}
      <StyledForm
        {...autocomplete.getFormProps({
          inputElement: inputRef.current,
          onSubmit: (e: SubmitEvent) => {
            e.preventDefault()
            if (!autocompleteState.query.trim().length) return
            autocomplete.setIsOpen(false)
            router.push(`/assets?search=${autocompleteState.query}`)
            autocomplete.setQuery('')
          },
        })}
      >
        {/* @ts-ignore */}
        <StyledInput
          themeMode={theme}
          ref={inputRef}
          {...autocomplete.getInputProps({
            inputElement: inputRef.current,
            placeholder: 'Search NFTs, collections and accounts',
            type: 'text',
          })}
        />

        <StyledBtn>
          <NextImage
            src={theme === 'light' ? search_light : darkSearchIcon}
            alt="search"
            layout="fill"
          />
        </StyledBtn>
      </StyledForm>

      {autocompleteState.isOpen && (
        // @ts-ignore
        <StyledPanel themeMode={theme} {...autocomplete.getPanelProps({})} ref={searchOpenRef}>
          {autocompleteState.collections?.map((collection, index) => {
            const { source, items } = collection

            return (
              <StyledSource key={`source-${index}`}>
                {items.length > 0 && (
                  <>
                    <StyledTitle>{source.sourceId}</StyledTitle>
                    <StyledList {...autocomplete.getListProps()}>
                      {items.map(item => {
                        const logoImage =
                          source.sourceId === SourceId.Collections
                            ? item.logo
                            : item?.imageUrl?.length
                            ? item.imageUrl
                            : source.sourceId === SourceId.Items
                            ? item?.previewImage
                              ? item?.previewImage
                              : item?.fileUrl
                            : profileUser
                        const blockchainImage = item?.blockchain?.imageUrl || ethereum_icon
                        return (
                          // @ts-ignore
                          <StyledItem
                            themeMode={theme}
                            key={item.objectID}
                            {...autocomplete.getItemProps({
                              item,
                              source,
                            })}
                          >
                            <Link
                              href={getPath(source.sourceId, {
                                walletAddress: item?.walletAddress,
                                collectionSlug: item?.slug || item?.id,
                              })}
                              passHref
                            >
                              <a>
                                <div className="flex items-center">
                                  <StyledImgWrapper>
                                    <NextImage
                                      loader={() => logoImage}
                                      src={logoImage}
                                      alt="collection"
                                      width={30}
                                      height={30}
                                    />
                                  </StyledImgWrapper>
                                  <StyledName>
                                    {source.sourceId === SourceId.Collections ? (
                                      item.name.length > 24 ? (
                                        <TippyToolTip
                                          placement="bottom"
                                          toolTipContent={<div>{item.name}</div>}
                                        >
                                          <div>
                                            <span>{handlePrecision(item.name, 24)}</span>
                                            {/* {item?.owner && item?.owner?.firstName && (
                                              <div>
                                                by &nbsp;
                                                {handlePrecision(item?.owner?.firstName, 24)}
                                              </div>
                                            )} */}
                                          </div>
                                        </TippyToolTip>
                                      ) : (
                                        <>
                                          <div>{item.name}</div>
                                          {/* {item?.owner && item?.owner?.firstName && (
                                            <div>by {item?.owner?.firstName}</div>
                                          )} */}
                                        </>
                                      )
                                    ) : source.sourceId === SourceId.Items ? (
                                      item?.fileName
                                    ) : (
                                      item?.userName || item?.walletAddress
                                    )}
                                  </StyledName>
                                </div>
                                {source.sourceId === SourceId.Collections && (
                                  <div className="flex items-center">
                                    <NextImage
                                      loader={() => blockchainImage}
                                      src={blockchainImage}
                                      alt="collection"
                                      width={20}
                                      height={25}
                                    />
                                    <StyledCount>{item?.totalCount || 0} Items</StyledCount>
                                  </div>
                                )}
                              </a>
                            </Link>
                          </StyledItem>
                        )
                      })}
                    </StyledList>
                  </>
                )}
              </StyledSource>
            )
          })}
          <StyledTitle>Press Enter to search all items</StyledTitle>
        </StyledPanel>
      )}
    </StyledWrapper>
  )
}
