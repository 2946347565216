import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { fromWei } from 'web3-utils'
import NextImage from 'shared/components/nextImage/NextImage'
// import jwt_decode from 'jwt-decode'
import BigNumber from 'bignumber.js'
import {
  fortmatic,
  injected,
  walletConnect,
  walletLink,
  portis,
  torus,
  // authereum,
  ETHEREUM_TESTNET_CHAIN_ID,
  POLYGON_TESTNET_CHAIN_ID,
} from '../../wallet/connectors'
import {
  darkSearchIcon,
  logo_dark,
  logo_light,
  jungleToken,
  white_arrow,
  white_arrow_left,
  search_light,
  left_arrow_light,
  right_arrow_light,
  allNfts,
  header_walletIcon,
  collection,
} from '../../icons'
import {
  ArrowWrap,
  ExploreHover,
  HeaderHover,
  HeaderJungleToken,
  HeaderListIcon,
  HeaderNavigation,
  HeaderUser,
  HeaderWrapper,
  MenuHover,
  MobileFlex,
  MobileTab,
  MobileTabs,
  MobileWrap,
  MobileWrapFooter,
  ModifiedMobileFlex,
  ModifiedMobileWrap,
  NavTabs,
  ResorcesHover,
  SearchIconWrapper,
  SearchWrapper,
  AutoCompleteDiv,
  ResourcesIconsWrapper,
  ResourcesImage,
  TextLeft,
  LogoWrapper,
  ExploreRightBlock,
  ExploreImageDiv,
} from './Header.style'
import { useAppSelector } from 'hooks/useAppSelector'
import Link from 'next/link'
import {
  MenuDropDownsProps,
  menuLinks,
  resourcesDropDownsProps,
  resourcesLinks,
  resourcesShareIcon,
} from './headerDropdownLinks'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from '@web3-react/walletconnect-connector'

import { useRouter } from 'next/router'
import {
  setAccountLoading,
  setChainType,
  setIsLoggedIn,
  setIsUserSwitchedWallet,
  setUserFromLoginPage,
  setUserFromSidebar,
} from 'logic/redux/slices/userSlice'
import { useAppDispatch } from 'hooks/useAppDispatch'
import {
  I_LoginOptions,
  useGetUserWalletBalance,
  useLoginMutation,
  useUserLogoutQuery,
} from 'logic/reactQuery/userService'
import { useGetUserDetailsQuery } from 'logic/reactQuery/userService'
import { Autocomplete } from 'modules/search/Auctocomplete'
import Sidebar from 'shared/sidebar/Sidebar'
import { useGetCategoriesQuery } from 'logic/reactQuery/homePageService'
import Toggle from 'shared/Toggler'
import { setTheme } from 'logic/redux/slices/themeSlice'
import { useDarkMode } from 'hooks/useToggleTheme'
import SwitchNetworkModal from 'modules/login/SwitchNetworkModal'
import { toHex, cgTokenIds, commaNumber } from 'utilities/helpers'
import { IToken, IBalance, ITokenHeader } from 'shared/interface'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import { useDetectOutsideClick } from 'hooks/useDetectOutsideClick'
import { valueTernary } from 'react-select/dist/declarations/src/utils'

import { useCGSimplePriceQuery } from 'logic/reactQuery/coinGeckoService'
import { zeroAddress } from 'logic/contracts/contractAddresses'
import { getErc20Balance, getEthBalance } from 'logic/contracts/getBalance'
import { MdOutlineNightlightRound } from 'react-icons/md'
import { themeDark } from 'styles/theme'
import { Container } from 'styles/defaultStyles'
import { type } from 'os'
import TippyToolTip from '../tippyTooltip/TippyToolTip'
import { resourcesUrl } from 'shared/routes/routes'
import { Spinner } from '../spinner/Spinner'
import Image from 'next/image'
import SuspendedModal from '../suspendedModal/SuspendedModal'
import AuctionWinnerModal from '../auctionWinner/AuctionWinnerModal'
import { useAuth } from 'hooks/useAuth'
import PendingVerification from 'modules/settings/KycModals/PendingVerification'

export const Header = () => {
  let redirect: any
  if (typeof window !== 'undefined') {
    redirect = window.location.pathname
  }
  const [theme, themeToggler, mountedComponent] = useDarkMode()
  const router = useRouter()
  const dispatch = useAppDispatch()

  const [kycPendingModal, setKycPendingModal] = useState<boolean>(false)
  const { user }: any = useAuth()

  const { mutateAsync, isLoading, data: loginInfo }: any = useLoginMutation()
  const { activate, account, active, deactivate, error, chainId, library } = useWeb3React()

  const { data: categories } = useGetCategoriesQuery()
  const { mutateAsync: mutateLogoutUser, isLoading: userLogoutLoading } = useUserLogoutQuery()

  const { isLoggedIn, isAccountLoading, userFromLoginPage, userFromSidebar, isUserSwitchedWallet } =
    useAppSelector(state => state.user)

  const { data: walletTokens } = useGetUserWalletBalance(isLoggedIn)
  const walletTokensAll = walletTokens?.message

  const { data: userData, isLoading: userLoading }: any = useGetUserDetailsQuery(account)
  // const { theme } = useAppSelector(state => state.theme)
  const [clickMenu, setClickMenu] = useState<boolean>(false)
  const dropDownRef = useRef(null)
  const [dropdown, setDropdown] = useDetectOutsideClick(dropDownRef, false)
  const [mobile, setMobile] = useState(false)
  const [explore, setExplore] = useState(false)
  const [stats, setStats] = useState(false)
  const [resources, setResources] = useState(false)
  const [accountTab, setAccountTab] = useState(false)
  const [wallet, setWallet] = useState(false)
  const [search, setSearch] = useState(false)
  const [balances, setBalances] = useState<IBalance[]>([])
  const [tokens, setTokens] = useState<IToken[] | any>([])
  const [exploreDropdown, setExploreDropdown] = useState(false)
  const [statsDropdown, setStatsDropdown] = useState(false)
  const [resourcesDropdown, setResourcesDropdown] = useState(false)
  const [isLoggedInProfileDropdown, setLoggedInProfileDropdown] = useState(false)
  const [isLoggedOutProfileDropdown, setLoggedOutProfileDropdown] = useState(false)
  const [isShowSwitchModal, setIsShowSwitchModal] = useState(false)

  const ids =
    walletTokensAll?.length > 0 &&
    walletTokensAll.map((token: any) => {
      return token?.tokenDetails?.tokenId
    })

  const { data: cgData } = useCGSimplePriceQuery({
    ids,
    vs_currencies: ['usd'],
  })

  const OpenSearch = () => {
    setSearch(!search)
    setMobile(false)
  }
  const OpenExplore = () => {
    setExplore(!explore)
  }
  const OpenStats = () => {
    setStats(!stats)
  }
  const OpenResources = () => {
    setResources(!resources)
  }
  const OpenAccountTab = () => {
    setAccountTab(!accountTab)
  }
  const OpenWallet = () => {
    setWallet(!wallet)
  }
  useEffect(() => {
    dropdown ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto')
  }, [dropdown])

  const OpenDropdown = () => {
    setDropdown(!dropdown)
  }
  const OpenMobile = () => {
    setMobile(!mobile)
  }
  const CloseSearch = () => {
    setSearch(false)
    setMobile(false)
    setClickMenu(false)
  }
  function handleMenuIconClick() {
    setClickMenu(prev => !prev)
    OpenMobile()
    !mobile ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto')
    setExplore(false)
    setStats(false)
    setResources(false)
    setAccountTab(false)
    setWallet(false)
  }

  const disconnectWallet: any = async (e: any) => {
    try {
      e.stopPropagation()
      const response = await mutateLogoutUser()
      if (response?.success) {
        const isExistingUser =
          typeof window !== 'undefined' && window.localStorage.getItem('userInfo')
        const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null
        const userAuthInfoUpdated = userAuthInfo.filter(
          (user: any) => user.walletAddress !== account,
        )
        if (userAuthInfoUpdated) {
          const newUserInfo = [...userAuthInfoUpdated]
          typeof window !== 'undefined' &&
            window.localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
        }
        if (typeof window !== 'undefined') {
          window.localStorage.removeItem('provider')
          window.localStorage.removeItem('accessToken')
          window.localStorage.removeItem('tokenExpiry')
        }
        deactivate()
        dispatch(setUserFromLoginPage(false))
        dispatch(setAccountLoading(false))
        dispatch(setUserFromSidebar(false))
        dispatch(setIsLoggedIn(false))
        dispatch(setChainType(''))
        router.replace('/login')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchBalances = async (
    library: any,
    userAddress: string,
    tokens: ITokenHeader[],
    cgData: any,
  ) => {
    const balances: IBalance[] = []
    if (library && userAddress && tokens && cgData) {
      for (const token of tokens) {
        try {
          const usdPrice = cgData?.data[token?.tokenDetails?.tokenId]?.usd || 0
          if (
            !token?.tokenDetails?.contractAddress ||
            token?.tokenDetails?.contractAddress === zeroAddress ||
            token?.tokenDetails?.contractAddress?.length !== zeroAddress.length
          ) {
            const weiBal = await getEthBalance({ library, userAddress })
            const ethBal = fromWei(weiBal, 'ether')
            const usdBalance = usdPrice * Number(ethBal)
            balances.push({ token, balance: ethBal, usdBalance })
          } else {
            // const bal = await getErc20Balance({
            //   library,
            //   userAddress,
            //   contractAddress: token?.tokenDetails?.contractAddress,
            // })
            const balBn = token?.balance

            // const decimalsBN = new BigNumber(token?.tokenDetails?.decimals)
            // const divisor = new BigNumber(10).pow(decimalsBN)
            // const balInNative = balBn.div(divisor)
            const usdBalance = Number(balBn) * usdPrice
            balances.push({ token, balance: balBn, usdBalance })
          }
        } catch (e) {
          balances.push({ token, balance: 0, usdBalance: 0 })
        }
      }
    }
    setBalances(balances)
  }

  useEffect(() => {
    const tokens = walletTokensAll
    setTokens(tokens)
  }, [chainId, walletTokensAll?.length])

  useEffect(() => {
    if (!account || !library || !cgData) return
    fetchBalances(library, account, tokens, cgData)
  }, [account, library, cgData, tokens])

  useEffect(() => {
    if (
      (chainId && chainId === ETHEREUM_TESTNET_CHAIN_ID) ||
      chainId === POLYGON_TESTNET_CHAIN_ID
    ) {
      setIsShowSwitchModal(false)
      return
    }

    if (
      chainId &&
      chainId !== Number(ETHEREUM_TESTNET_CHAIN_ID || chainId !== Number(POLYGON_TESTNET_CHAIN_ID))
    ) {
      setIsShowSwitchModal(true)
      return
    }

    setIsShowSwitchModal(false)
  }, [chainId, router.pathname])

  function exploreDropDown() {
    setExploreDropdown(true)
    // setExploreClicked(true)
  }
  const providers: any = {
    fortmatic,
    injected,
    walletConnect,
    walletLink,
    portis,
    torus,
    // authereum,
  }

  let provider: any
  if (typeof window !== 'undefined') {
    provider = localStorage.getItem('provider')
  }
  const connectWallet: any = (connector: string) => {
    dispatch(setAccountLoading(true))
    activate(providers[connector], () => {
      dispatch(setAccountLoading(false))
      typeof window !== 'undefined' && window.localStorage.removeItem('provider')
      router.push({
        pathname: '/login',
        query: { redirect },
      })
    })
  }

  if (
    provider &&
    !account &&
    !active &&
    !isAccountLoading &&
    !userFromLoginPage &&
    !userFromSidebar
  ) {
    connectWallet(provider)
  }

  const userSignatureAuthorization = async (userAddress: string) => {
    try {
      const signatureMessage =
        'action= signIn&' +
        'address' +
        userAddress +
        'signUp' +
        '&timestamp=' +
        new Date()?.toISOString()

      const signatureHash = await library.eth.personal.sign(signatureMessage, userAddress)
      const data: I_LoginOptions = {
        walletAddress: userAddress,
        signature: signatureHash,
        signature_message: signatureMessage,
      }
      const response = await mutateAsync(data)
      console.log("response", response);

      if (response?.message?.access_token) {
        dispatch(setIsLoggedIn(true))
        if (chainId) dispatch(setChainType(chainId))

        typeof window !== 'undefined' &&
          window.localStorage.setItem(
            'accessToken',
            JSON.stringify(response?.message?.access_token),
          )
        typeof window !== 'undefined' &&
          window.localStorage.setItem(
            'tokenExpiry',
            JSON.stringify(response?.message?.tokenExpirationDate),
          )

        const userInfo = [
          {
            walletAddress: userAddress,
            accessToken: response?.message?.access_token,
            tokenExpiry: response?.message?.tokenExpirationDate,
          },
        ]

        const isExistingUser =
          typeof window !== 'undefined' && window.localStorage.getItem('userInfo')
        const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null

        if (userAuthInfo !== undefined && userAuthInfo !== null && userAuthInfo?.length > 0) {
          const userAuthInfoUpdated = userAuthInfo.filter(
            (user: any) => user.walletAddress !== userAddress,
          )
          if (userAuthInfoUpdated) {
            const newUserInfo = [...userAuthInfoUpdated, ...userInfo]
            typeof window !== 'undefined' &&
              window.localStorage.setItem('userInfo', JSON.stringify(newUserInfo))
          }
        } else {
          typeof window !== 'undefined' &&
            window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
        }

        // @ts-ignore
        // router.push(router.query.redirect || '/profile')
        router.back()

        // @ts-ignore window.analytics undefined below
        window.analytics.track('User Authorized', {
          data,
        })
      } else {
        // @ts-ignore window.analytics undefined below
        window.analytics.track('User UnAuthorized', {
          data,
        })
      }
    } catch (error: any) {
      console.log(error?.message)
      toast(error?.message)
      // @ts-ignore window.analytics undefined below
      window.analytics.track('User UnAuthorized', {
        account,
      })
      disconnectWallet()
    }
  }

  const checkUserStatus = (address: string): boolean => {
    const isExistingUser = typeof window !== 'undefined' && window.localStorage.getItem('userInfo')
    const userAuthInfo = isExistingUser ? JSON.parse(isExistingUser) : null
    if (userAuthInfo !== undefined && userAuthInfo !== null && userAuthInfo?.length > 0) {
      const userAuthInfoUpdated: any = userAuthInfo.find(
        (user: any) => user?.walletAddress?.toLowerCase() === address?.toLowerCase(),
      )
      if (userAuthInfoUpdated !== undefined && userAuthInfoUpdated !== null) {
        // const userToken: any = jwt_decode(userAuthInfoUpdated?.accessToken)
        // const isExpired = dayjs.unix(userToken?.exp).diff(dayjs()) < 1
        const expirationDate = userAuthInfoUpdated?.tokenExpiry
        // let isExpired = (new Date().getTime()) < expirationDate
        const isExpired = dayjs.unix(expirationDate).diff(dayjs()) < 1
        if (!isExpired) {
          typeof window !== 'undefined' &&
            window.localStorage.setItem(
              'accessToken',
              JSON.stringify(userAuthInfoUpdated?.accessToken),
            )
          return true
        } else {
          disconnectWallet()
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const getErrorMessage = (error: any) => {
    if (error instanceof NoEthereumProviderError) {
      toast(
        'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.',
      )
    } else if (error instanceof UnsupportedChainIdError) {
      toast("You're connected to an unsupported network.")
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorWalletConnect
    ) {
      toast('Please authorize this website to access your Ethereum account.')
    } else {
      console.error(error)
      // toast('An unknown error occurred. Check the console for more details.')
    }
  }

  useEffect(() => {
    getErrorMessage(error)
  }, [error])

  useEffect(() => {
    if (account && !isAccountLoading && !userFromLoginPage && !userFromSidebar) {
      const userHasValidToken = checkUserStatus(account)
      if (userHasValidToken) {
        dispatch(setIsUserSwitchedWallet(false))
        if (chainId) dispatch(setChainType(chainId))
        // @ts-ignore window.analytics undefined below
        window.analytics.track('User Authorized', {
          account,
        })
      }
      if (!userHasValidToken) {
        dispatch(setIsUserSwitchedWallet(false))
        userSignatureAuthorization(account)
      }
    }
  }, [account, active, isAccountLoading, userFromLoginPage, userFromSidebar])

  // useEffect(() => {
  //   if (account && !isLoggedIn && !userFromLoginPage && !userFromSidebar) {
  //     dispatch(setAccountLoading(false))
  //     const userHasValidToken = checkUserStatus(account)
  //     if (userHasValidToken) {
  //       dispatch(setIsLoggedIn(true))
  //       if (chainId) dispatch(setChainType(chainId))
  //       // @ts-ignore window.analytics undefined below
  //       window.analytics.track('User Authorized', {
  //         account,
  //       })
  //       if (router.query.redirect) {
  //         // @ts-ignore
  //         router.push(router.query.redirect || router.asPath)
  //       }
  //     }
  //   }
  // }, [account, userFromLoginPage, userFromSidebar, isLoggedIn, isAccountLoading])

  const handleLogoClick = () => {
    setMobile(false)
    setClickMenu(false)
    router.push('/')
  }
  const userLinks = [
    {
      image: `${isLoggedIn ? '/assets/svg/headerIcons/profile.svg' : '/assets/svg/headerIcons/signUp.svg'
        }`,
      title: `${isLoggedIn ? 'Profile' : 'Sign In'}`,
      link: '/profile',
      isHasLink: true,
    },
    {
      image: '/assets/svg/headerIcons/favorites.svg',
      title: 'Favourites',
      link: '/profile?tab=favourite',
      isHasLink: true,
    },
    {
      image: '/assets/svg/headerIcons/collection.svg',
      title: 'My Collections',
      link: '/collections',
      isHasLink: true,
    },
    {
      image: '/assets/svg/headerIcons/settings.svg',
      title: 'Settings',
      link: '/settings',
      isHasLink: true,
    },
    // {
    //   image: '/assets/svg/header/watchlist_icon.svg',
    //   title: 'Watchlist',
    //   link: '/watchlist',
    //   isHasLink: true,
    // },
    {
      image: '/assets/svg/headerIcons/logout.svg',
      title: 'Log Out',
      link: '/login',
      isHasLink: false,
    },
  ]
  return (
    <>
      <HeaderWrapper themeMode={theme}>
        <LogoWrapper>
          <NextImage
            src={theme === 'light' ? logo_light : logo_dark}
            alt=""
            className="logo-icon"
            priority={true}
            onClick={handleLogoClick}
            style={{ cursor: 'pointer', width: 'max-content' }}
          />
        </LogoWrapper>
        <AutoCompleteDiv>
          <Autocomplete />
        </AutoCompleteDiv>
        <SearchIconWrapper onClick={OpenSearch}>
          <NextImage src={theme === 'light' ? search_light : darkSearchIcon} alt="search" />
        </SearchIconWrapper>
        {search && (
          <SearchWrapper themeMode={theme}>
            <ArrowWrap onClick={CloseSearch}>
              <NextImage src={theme === 'light' ? left_arrow_light : white_arrow_left} />
            </ArrowWrap>
            {/* <SearchInput themeMode={theme} placeholder="Search Jungle" /> */}
            <Autocomplete />
          </SearchWrapper>
        )}
        {/* <HeaderSearch>
          <input
            type="text"
            className="input-search"
            placeholder="Search NFTs, collections, and accounts"
          />
          <NextImage src={darkSearchIcon} alt="" className="" />
        </HeaderSearch> */}
        <HeaderNavigation>
          <NavTabs
            onMouseEnter={() => setExploreDropdown(true)}
            onMouseLeave={() => setExploreDropdown(false)}
          >
            <Link href="/explore-collections">Explore</Link>
            {exploreDropdown && (
              <ExploreHover
                themeMode={theme}
                className="visible"
                onClick={() => setExploreDropdown(false)}
              >
                <div className="block">
                  <Link href="/assets">
                    <div className="gridExploreBlock">
                      {/* <ExploreImageDiv> */}
                      <NextImage src={collection} height={22} width={22} objectFit="contain" />
                      {/* </ExploreImageDiv> */}
                      <ExploreRightBlock>All NFTs</ExploreRightBlock>
                    </div>
                  </Link>
                  {categories?.map(categoriesData => (
                    <div>
                      <Link href={`/collection/${categoriesData.categorySlug}`}>
                        <div className="gridExploreBlock">
                          {/* <ExploreImageDiv> */}
                          <NextImage
                            src={categoriesData?.categoryImageUrl}
                            height={22}
                            width={22}
                            objectFit="contain"
                          />
                          {/* </ExploreImageDiv> */}
                          <ExploreRightBlock>
                            {categoriesData?.categoryName.toUpperCase()}
                          </ExploreRightBlock>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </ExploreHover>
            )}
          </NavTabs>
          <NavTabs
            onMouseEnter={() => setStatsDropdown(true)}
            onMouseLeave={() => setStatsDropdown(false)}
          >
            <Link href="/rankings">Stats</Link>
            {statsDropdown && (
              <MenuHover
                themeMode={theme}
                className="visible"
                onClick={() => setStatsDropdown(false)}
              >
                <div className="block">
                  {menuLinks.map((val: MenuDropDownsProps, index: number) => (
                    <Link href={val.link} key={val.link}>
                      <div className="gridBlock">
                        <TextLeft>{val.title}</TextLeft>
                      </div>
                    </Link>
                  ))}
                </div>
              </MenuHover>
            )}
          </NavTabs>
          <NavTabs
            onMouseEnter={() => setResourcesDropdown(true)}
            onMouseLeave={() => setResourcesDropdown(false)}
          >
            <Link href={resourcesUrl.helpCenter}>Resources</Link>
            {resourcesDropdown && (
              <ResorcesHover
                onClick={() => setResourcesDropdown(false)}
                themeMode={theme}
                className="visible"
              >
                <div className="block">
                  {resourcesLinks.map((val: resourcesDropDownsProps, index: number) =>
                    val?.isHasLink ? (
                      <Link href={val?.link} key={val?.link}>
                        <div className="gridBlock">
                          <TextLeft>{val.title}</TextLeft>
                        </div>
                      </Link>
                    ) : (
                      <a
                        href={val.link}
                        key={val?.link}
                        target={
                          val.link === `${resourcesUrl.helpCenter}` ||
                            val.link === `${resourcesUrl.platformStatusUrl}`
                            ? '_blank'
                            : '_self'
                        }
                      >
                        <div className="gridBlock">
                          <TextLeft>{val.title}</TextLeft>
                        </div>
                      </a>
                    ),
                  )}
                  <ResourcesIconsWrapper>
                    {resourcesShareIcon.map((val, index) => (
                      <a href={val.link} key={val?.link} target="_blank">
                        <ResourcesImage src={val.img} />
                      </a>
                    ))}
                  </ResourcesIconsWrapper>
                </div>
              </ResorcesHover>
            )}
          </NavTabs>
          <NavTabs>
            <Link href={user && (!user.kyc_request_id || !user.kyc_verified) ? '' : '/create'}>
              <p
                onClick={() => {
                  if (user && (!user.kyc_request_id || !user.kyc_verified)) {
                    setKycPendingModal(true)
                  }
                }}
              >
                {' '}
                Create{' '}
              </p>
            </Link>
          </NavTabs>

          {/* @ts-ignore */}
          {isLoggedIn && (
            <HeaderUser
              onClick={(e: any) => {
                e.stopPropagation()
                router.push('/profile')
              }}
              themeMode={theme}
              src={userData?.message?.imageUrl ? true : false}
            >
              {userData?.message?.imageUrl && (
                <NextImage
                  width={60}
                  height={60}
                  objectFit="cover"
                  style={{ borderRadius: '50%', cursor: 'pointer' }}
                  // @ts-ignore
                  src={userData?.message?.imageUrl}
                  alt="user"
                />
              )}

              <HeaderHover themeMode={theme} className="visible-header">
                <div className="block">
                  {userLinks.map(
                    (val: any, index: number) =>
                      val?.isHasLink && (
                        <>
                          <div
                            key={index}
                            onClick={(e: any) => {
                              e.stopPropagation()
                              router.push(`${val.link}`)
                            }}
                          >
                            <div className="gridBlock">
                              <div className="image-icon">
                                <NextImage src={val.image} width={22} height={22} />
                              </div>
                              <div className="text-left">{val.title}</div>
                            </div>
                          </div>
                        </>
                      ),
                  )}

                  <div className="gridBlock" onClick={(e: any) => disconnectWallet(e)}>
                    <div className="image-icon">
                      <NextImage
                        src={userLinks[userLinks?.length - 1].image}
                        width={10}
                        height={10}
                      />
                    </div>
                    <div
                      className="text-left"
                      style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      {userLinks[userLinks?.length - 1].title} {userLogoutLoading && <Spinner />}
                    </div>
                  </div>
                  <div
                    className="gridBlock"
                    onClick={(e: any) => {
                      e.stopPropagation()
                    }}
                  >
                    <div className="image-icon">
                      <MdOutlineNightlightRound color={themeDark.brandColor} />
                    </div>
                    <div className="text-left toggle">
                      {theme === 'light' ? 'Night Mode' : 'Light Mode'}
                      <span>
                        <Toggle theme={theme} toggleTheme={themeToggler} />
                      </span>
                    </div>
                  </div>
                </div>
              </HeaderHover>
            </HeaderUser>
          )}
          {!isLoggedIn && (
            <HeaderUser
              themeMode={theme}
              noImage
              onClick={() => {
                router.push('/login')
              }}
            >
              <HeaderHover themeMode={theme} className="visible-header">
                <div className="block">
                  {userLinks.map(
                    (val: any, index: number) =>
                      val?.isHasLink && (
                        <>
                          <div
                            key={index}
                            className="gridBlock"
                            onClick={(e: any) => {
                              e.stopPropagation()
                              router.push('/login')
                            }}
                          >
                            <div className="image-icon">
                              <NextImage src={val.image} width={16} height={16} />
                            </div>
                            <div className="text-left">{val.title}</div>
                          </div>
                        </>
                      ),
                  )}
                  <div
                    className="gridBlock"
                    onClick={(e: any) => {
                      e.stopPropagation()
                    }}
                  >
                    <div className="image-icon">
                      <MdOutlineNightlightRound color={themeDark.brandColor} />
                    </div>
                    <div className="text-left toggle">
                      Night Mode{' '}
                      <span>
                        <Toggle theme={theme} toggleTheme={themeToggler} />
                      </span>
                    </div>
                  </div>
                </div>
              </HeaderHover>
            </HeaderUser>
          )}

          <HeaderJungleToken ref={dropDownRef}>
            <div className="btn" onClick={OpenDropdown}>
              <Image src={header_walletIcon} alt="" className="img-icon" width={35} height={35} />
            </div>

            {/* <div className="price"> */}
            {/* $ */}
            {/* {account
                ? balances.reduce((acc, cur) => acc + Number(cur.usdBalance), 0).toFixed(2)
                : 0} */}
            {/* {account ? (
              <>
                {Number(balances.reduce((acc, cur) => acc + Number(cur.usdBalance), 0))
                  .toFixed(2)
                  .includes('e') ? (
                  <TippyToolTip
                    placement="bottom"
                    toolTipContent={
                      <div>
                        {Number(
                          balances.reduce((acc, cur) => acc + Number(cur.usdBalance), 0),
                        ).toFixed(2)}
                      </div>
                    }
                  >
                    <div className="price overflowBal">
                      $
                      {commaNumber(
                        new BigNumber(
                          balances.reduce((acc, cur) => acc + Number(cur.usdBalance), 0),
                        ).toFixed(2),
                      )}{' '}
                    </div>
                  </TippyToolTip>
                ) : (
                  <div className="price">
                    $
                    {commaNumber(
                      Number(
                        balances.reduce((acc, cur) => acc + Number(cur.usdBalance), 0),
                      ).toFixed(2),
                    )}{' '}
                  </div>
                )}
              </>
            ) : (
              '$0.00'
            )} */}
            {/* </div> */}
          </HeaderJungleToken>

          <HeaderListIcon onClick={handleMenuIconClick} click={!clickMenu}>
            <span></span>
            <span></span>
            <span></span>
          </HeaderListIcon>
        </HeaderNavigation>
      </HeaderWrapper>
      {dropdown && <Sidebar dropdown={dropdown} setDropdown={setDropdown} balances={balances} />}
      {mobile && (
        <MobileWrap themeMode={theme}>
          <MobileFlex onClick={OpenExplore}>
            <p>Explore</p>
            <NextImage src={theme === 'light' ? right_arrow_light : white_arrow} />
          </MobileFlex>
          {explore && (
            <ModifiedMobileWrap themeMode={theme}>
              <ModifiedMobileFlex themeMode={theme} onClick={() => setExplore(false)}>
                <NextImage src={theme === 'light' ? left_arrow_light : white_arrow_left} />
                <p>Explore</p>
              </ModifiedMobileFlex>
              <MobileTab themeMode={theme}>
                <Link href="/explore-collections">
                  <MobileTabs onClick={handleMenuIconClick}>Explore Collection</MobileTabs>
                </Link>
                <Link href="/assets">
                  <MobileTabs onClick={handleMenuIconClick}>All NFTs</MobileTabs>
                </Link>
                {categories?.map(categoriesData => (
                  <div>
                    <Link href={`/collection/${categoriesData.categorySlug}`}>
                      <MobileTabs onClick={handleMenuIconClick}>
                        {categoriesData.categoryName}
                      </MobileTabs>
                    </Link>
                  </div>
                ))}
                {/* <MobileTabs>{categoriesData.categoryName}</MobileTabs>
                <MobileTabs>Arts</MobileTabs>
                <MobileTabs>Music</MobileTabs>
                <MobileTabs>Photography</MobileTabs> */}
              </MobileTab>
            </ModifiedMobileWrap>
          )}
          <MobileFlex onClick={OpenStats}>
            <p>Stats</p>
            <NextImage src={theme === 'light' ? right_arrow_light : white_arrow} />
          </MobileFlex>
          {stats && (
            <ModifiedMobileWrap themeMode={theme}>
              <ModifiedMobileFlex themeMode={theme} onClick={() => setStats(false)}>
                <NextImage src={theme === 'light' ? left_arrow_light : white_arrow_left} />
                <p>Stats</p>
              </ModifiedMobileFlex>
              <MobileTab themeMode={theme}>
                {menuLinks.map((val: MenuDropDownsProps, index: number) => (
                  <Link href={val.link} key={val.link}>
                    <MobileTabs onClick={handleMenuIconClick}>{val.title}</MobileTabs>
                  </Link>
                ))}
              </MobileTab>
            </ModifiedMobileWrap>
          )}
          <MobileFlex onClick={OpenResources}>
            <p>Resources</p>
            <NextImage src={theme === 'light' ? right_arrow_light : white_arrow} />
          </MobileFlex>
          {resources && (
            <ModifiedMobileWrap themeMode={theme}>
              <ModifiedMobileFlex themeMode={theme} onClick={() => setResources(false)}>
                <NextImage src={theme === 'light' ? left_arrow_light : white_arrow_left} />
                <p>Resources</p>
              </ModifiedMobileFlex>
              <MobileTab themeMode={theme}>
                {resourcesLinks.map((val: resourcesDropDownsProps, index: number) => (
                  <a
                    href={val.link}
                    key={val?.link}
                    target={val.link === `${resourcesUrl.helpCenter}` ? '_blank' : '_self'}
                  >
                    <MobileTabs>{val.title}</MobileTabs>
                  </a>
                ))}
              </MobileTab>
            </ModifiedMobileWrap>
          )}
          <MobileFlex>
            <Link href={user && (!user.kyc_request_id || !user.kyc_verified) ? '' : '/create'}>
              <p
                onClick={() => {
                  if (user && (!user.kyc_request_id || !user.kyc_verified)) {
                    setKycPendingModal(true)
                  } else {
                    handleMenuIconClick()
                  }
                }}
              >
                Create
              </p>
            </Link>
            <NextImage src={theme === 'light' ? right_arrow_light : white_arrow} />
          </MobileFlex>

          <MobileFlex onClick={OpenAccountTab}>
            <p>Account</p>
            <NextImage src={theme === 'light' ? right_arrow_light : white_arrow} />
          </MobileFlex>
          {accountTab && (
            <ModifiedMobileWrap themeMode={theme}>
              <ModifiedMobileFlex themeMode={theme} onClick={() => setAccountTab(false)}>
                <NextImage src={theme === 'light' ? left_arrow_light : white_arrow_left} />
                <p>Account</p>
              </ModifiedMobileFlex>
              <MobileTab themeMode={theme}>
                {userLinks.map((val: any, index: number) =>
                  val?.isHasLink ? (
                    <Link href={val.link}>
                      <MobileTabs onClick={handleMenuIconClick}>{val.title}</MobileTabs>
                    </Link>
                  ) : (
                    <>
                      {isLoggedIn ? (
                        <MobileTabs
                          onClick={() => {
                            setMobile(false)
                            disconnectWallet()
                          }}
                        >
                          {val.title}
                        </MobileTabs>
                      ) : (
                        <Link href="/login">
                          <MobileTabs onClick={handleMenuIconClick}>Login</MobileTabs>
                        </Link>
                      )}
                    </>
                  ),
                )}
              </MobileTab>
            </ModifiedMobileWrap>
          )}
          <MobileFlex onClick={OpenWallet}>
            <p>My Wallet</p>
            <NextImage src={theme === 'light' ? right_arrow_light : white_arrow} />
          </MobileFlex>
          {wallet && (
            <ModifiedMobileWrap themeMode={theme}>
              <ModifiedMobileFlex themeMode={theme} onClick={() => setWallet(false)}>
                <NextImage src={theme === 'light' ? left_arrow_light : white_arrow_left} />
                <p>My Wallet</p>
              </ModifiedMobileFlex>
              <Sidebar balances={balances} />
            </ModifiedMobileWrap>
          )}
          <MobileFlex>
            <p>Night Mode</p>
            <Toggle theme={theme} toggleTheme={themeToggler} />
          </MobileFlex>
          <MobileWrapFooter themeMode={theme}>
            {resourcesShareIcon.map((val, index) => (
              <a key={index} href={val.link} target="_blank">
                <NextImage src={val.img} />
              </a>
            ))}
          </MobileWrapFooter>
        </MobileWrap>
      )}
      {isShowSwitchModal && isLoggedIn && (
        <SwitchNetworkModal
          setShowSwitchModal={setIsShowSwitchModal}
          showSwitchModal={isShowSwitchModal}
          networkId={ETHEREUM_TESTNET_CHAIN_ID}
        />
      )}
      {!userLoading && userData && userData?.message && userData?.message?.isBanned && (
        <SuspendedModal />
      )}
      <AuctionWinnerModal />
      <PendingVerification show={kycPendingModal} toggle={() => setKycPendingModal(false)} />
    </>
  )
}
