import NextImage from 'shared/components/nextImage/NextImage'
import { listBackground } from 'shared/icons'
import styled, { css } from 'styled-components'
import { Container, PrimaryButton } from 'styles/defaultStyles'
import { themeDark, themeLight } from 'styles/theme'
import { screenSizes } from 'utilities/helpers'
import { media } from '../../utilities/helpers'

export const SearchBar = styled.input`
  background: ${themeDark.white};
  box-shadow: 0px 0px 40px rgba(255, 255, 255, 0.2);
  border-radius: 48px;
  display: flex;
  align-items: center;
  width: 500px;
  padding-left: 50px;
  height: 50px;
  outline: none;
  border: none;
  ::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${themeDark.borderColor};
  }
`
export const SearchIcon = styled.img`
  position: absolute;
  left: 15px;
  top: 15px;
`
export const ChatButton = styled(PrimaryButton)<any>`
  background: ${themeDark.brandColor};
  position: sticky;
  right: 0px;
  bottom: 20px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 125px;
  cursor: pointer;
  align-self: flex-end;
  transition: all 0.5s ease-in-out;
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${themeDark.white};
    margin: 0 10px;
  }
  &:hover {
    box-shadow: ${({ theme, disabled }) => (disabled ? '' : theme.buttonShadow)};
  }
`

export const ResultWrapper = styled.div`
  background: ${themeDark.assetDetail.background};
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

export const SearchWrapper = styled.div`
  border: 1px solid ${themeDark.assetDetail.backArrowBg};
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0;
  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${themeDark.white};
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${themeDark.assetDetail.cardTitle};
  }
`
export const LikesButton = styled.div`
  background: ${themeDark.assetDetail.backArrowBg};
  border-radius: 50px;
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: ${themeDark.assetDetail.cardTitle};
    margin: 0 5px;
  }
`
export const SearchDetailDiv = styled.div`
  display: flex;
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${themeDark.assetDetail.cardTitle};
    margin: 0 10px;
  }
`
export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`

export const RequestFormWrapper = styled(ResultWrapper)<any>`
  padding: 20px 10px 20px 50px;
  margin: 50px 0;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
  border: ${({ themeMode }) => (themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none')};
  ${media.md`
    padding: 20px 0px 20px 0px;
  `};
`

export const RequestHeading = styled.div`
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  margin: 30px 0;
`
export const Label = styled.label`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${themeDark.white};
  margin: 10px 0;
`

export const Input = styled.input<any>`
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg};
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 605px;
  border: ${({ themeMode }) => (themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none')};
  outline: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 10px 0;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeDark.textTertiary : themeDark.white)};
  transition: all linear 0.2s;
  :hover {
    border: 1px solid
      ${({ themeMode }) =>
        themeMode === 'light' ? themeDark.textTertiary : themeDark.textSecondary};
  }
  ::placeholder {
    font-weight: 500;
    color: ${({ themeMode }) => (themeMode === 'light' ? themeDark.textTertiary : themeDark.white)};
    font-size: 14px;
    line-height: 21px;
  }
`
export const InputText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 10px 0;
  color: ${themeDark.assetDetail.cardTitle};
  max-width: 700px;
  width: 100%;
  word-break: break-word;
`

export const SuccessText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 20px 0;
`
export const ModalFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
`
export const TwoColumns = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0;
  ${media.md`
    grid-template-columns: repeat(1, 1fr);
  `}
`
export const ThreeColumns = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin: 50px 0;
`

export const DetailWrap = styled.div<any>`
  padding: 20px;
  h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 70px;
    color: ${({ themeMode }) => (themeMode === 'light' ? themeDark.borderColor : themeDark.white)};
    text-align: left;
    ${media.lg`
      font-size: 36px;
      line-height: 50px;
       text-align:center;
    `}
    ${media.sm`
      font-size: 30px;
      line-height: 40px;
      text-align:center;
    `}
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    margin: 20px 0;
    text-align: left;
    ${media.lg`
        text-align: center;
    `}
  }

  ${media.md`
    order: 2;
  `}
`
export const ImageWrap = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.lg`
    order: 1;
    padding: 20px 0;
    align-items:start;
  `}
`

export const ModifiedContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const NewsLetterHeading = styled.div`
  font-weight: 700;
  font-size: 50px;
  line-height: 70px;
  color: ${themeDark.white};
`
export const LetterImage = styled.div`
  display: flex;
  align-items: center;
`
export const TranslateDiv = styled.div`
  display: flex;
  align-items: center;
  transform: translateX(-100px);
`
export const ModifiedSearchBar = styled(SearchBar)`
  width: 100%;
`
export const ErrorText = styled.p`
  color: red !important;
  font-weight: 500;
  font-size: 16px;
  margin: 0 10px !important;
`

export const MarginContainer = styled(Container)`
  margin-top: 150px;
`
