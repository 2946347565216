import { useAppSelector } from 'hooks/useAppSelector'
import NextImage from 'shared/components/nextImage/NextImage'
import Link from 'next/link'
import React, { useState } from 'react'
import {
  discordFooterIcon,
  facebook,
  instagram,
  instagramIcon,
  instagram_logo,
  instagram_white,
  linkedin,
  logo_dark,
  logo_light,
  pinterest,
  social_1,
  social_2,
  tiktok,
  twitter,
  twitter_logo,
  twitter_logo_icon,
  twitter_white,
  youtube,
  youtube_white,
} from 'shared/icons'
import { darkSearchIcon, search_light } from '../../icons'
import { FlexBox } from 'styles/defaultStyles'
import {
  Heading,
  ImageWrapper,
  NavContainer,
  NavTabs,
  SocialImgWrap,
  HeaderListIcon,
  NavTabsMobile,
  ToggleIcon,
  SocialImgWrapMobile,
  SocialImgContainer,
  ModifiedContainer,
  DropDownNewsletter,
  SearchContainer,
  SearchSubContainer,
  InputField,
} from './NewsletterNavbar.style'
import { resourcesUrl } from 'shared/routes/routes'

const NewsLetterNavbar = () => {
  const { theme } = useAppSelector(state => state.theme)
  const [clickMenu, setClickMenu] = useState<boolean>(false)
  const OpenClickMenu = () => {
    setClickMenu(!clickMenu)
    !clickMenu ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto')
  }
  return (
    <ModifiedContainer>
      {clickMenu && (
        <DropDownNewsletter themeMode={theme}>
          <SearchContainer themeMode={theme}>
            <SearchSubContainer>
              <NextImage src={theme === 'light' ? search_light : darkSearchIcon} alt="search" />
              <InputField placeholder="Search..."></InputField>
            </SearchSubContainer>
          </SearchContainer>
          <NavTabsMobile>
            {/* <NavTabMobile>Guide </NavTabMobile>
            <NavTabMobile>Guest Posts</NavTabMobile>
            <NavTabMobile>Spotlights</NavTabMobile>
            <NavTabMobile>Safety & Security</NavTabMobile>
            <NavTabMobile>Announcements</NavTabMobile> */}
          </NavTabsMobile>
          <FlexBox justify="space-between">
            <SocialImgWrapMobile>
              <NextImage src={instagram_white} />
            </SocialImgWrapMobile>
            <SocialImgWrapMobile>
              <NextImage src={twitter_white} />
            </SocialImgWrapMobile>
            <SocialImgWrapMobile>
              <NextImage src={youtube_white} />
            </SocialImgWrapMobile>
            <SocialImgWrapMobile>
              <NextImage src={social_1} />
            </SocialImgWrapMobile>
            <SocialImgWrapMobile>
              <NextImage src={social_2} />
            </SocialImgWrapMobile>
          </FlexBox>
        </DropDownNewsletter>
      )}

      <NavContainer themeMode={theme}>
        <FlexBox>
          <ImageWrapper>
            <Link href="/">
              <NextImage src={theme === 'light' ? logo_light : logo_dark} />
            </Link>
          </ImageWrapper>
          <Heading themeMode={theme}>Help Center</Heading>
        </FlexBox>

        <NavTabs>
          {/* <NavTab>Guide </NavTab>
          <NavTab>Guest Posts</NavTab>
          <NavTab>Spotlights</NavTab>
          <NavTab>Safety & Security</NavTab>
          <NavTab>Announcements</NavTab> */}
        </NavTabs>

        <ToggleIcon>
          <HeaderListIcon click={!clickMenu} onClick={OpenClickMenu}>
            <span></span>
            <span></span>
            <span></span>
          </HeaderListIcon>
        </ToggleIcon>
        <SocialImgContainer>
          <FlexBox justify="space-between">
            <SocialImgWrap>
              <a href={resourcesUrl.twitter} target="_blank">
                <NextImage src={twitter} alt="" />
              </a>
            </SocialImgWrap>
            <SocialImgWrap>
              <a href={resourcesUrl.instagram} target="_blank">
                <NextImage src={instagram} alt="" />
              </a>
            </SocialImgWrap>
            <SocialImgWrap>
              <a href={resourcesUrl.facebook} target="_blank">
                <NextImage src={facebook} alt="" />
              </a>
            </SocialImgWrap>
            <SocialImgWrap>
              <a href={resourcesUrl.youtube} target="_blank">
                <NextImage src={youtube} alt="" />
              </a>
            </SocialImgWrap>
            <SocialImgWrap>
              <a href={resourcesUrl.linkedin} target="_blank">
                <NextImage src={linkedin} alt="" />
              </a>
            </SocialImgWrap>
            <SocialImgWrap>
              <a href={resourcesUrl.discord} target="_blank">
                <NextImage src={discordFooterIcon} alt="" />
              </a>
            </SocialImgWrap>
            <SocialImgWrap>
              <a href={resourcesUrl.tiktok} target="_blank">
                <NextImage src={tiktok} alt="" />
              </a>
            </SocialImgWrap>
          </FlexBox>
        </SocialImgContainer>
      </NavContainer>
    </ModifiedContainer>
  )
}

export default NewsLetterNavbar
