import { useToaster } from 'react-hot-toast'
import { useAppSelector } from 'hooks/useAppSelector'
import { ToasterContainer, ToastWrapper } from './Notification.style'

const Notifications = () => {
  const { toasts, handlers } = useToaster()
  const { theme } = useAppSelector(state => state.theme)
  const { startPause, endPause, calculateOffset, updateHeight } = handlers
  return (
    <ToasterContainer
      style={{
        position: 'fixed',
        zIndex: 10000000000000000000000000,
        bottom: '50px',
        right: '50px',
      }}
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map(toast => {
        const offset = calculateOffset(toast, {
          reverseOrder: false,
          gutter: 8,
          defaultPosition: 'bottom-right',
        })
        const ref = (el: HTMLDivElement) => {
          if (el && !toast.height) {
            const height = el.getBoundingClientRect().height
            updateHeight(toast.id, height)
          }
        }
        return (
          <ToastWrapper
            key={toast.id}
            ref={ref}
            themes={theme}
            visible={toast.visible}
            {...toast.ariaProps}
          >
            <>{toast.message}</>
          </ToastWrapper>
        )
      })}
    </ToasterContainer>
  )
}

export default Notifications
