import { useAppSelector } from 'hooks/useAppSelector'
import React from 'react'
import {
  LottieWrapper,
  ModalBody,
  SuspendedModalClose,
  SuspendedModalHeader,
  SuspendedModalLayout,
  SuspendedTextContent,
} from './SuspendedModal.style'
import suspended from '../../../public/assets/json/suspended.json'
import Lottie from 'react-lottie'
import { modal_close_icon } from 'shared/icons'
import NextImage from '../nextImage/NextImage'

const SuspendedModal = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: suspended,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  const { theme } = useAppSelector(state => state.theme)
  return (
    <>
      <ModalBody>
        <SuspendedModalLayout themeMode={theme}>
          <SuspendedModalHeader themeMode={theme}>Oops</SuspendedModalHeader>
          <LottieWrapper>
            <Lottie options={defaultOptions} width={90} height={90} />
          </LottieWrapper>
          <SuspendedTextContent>Your account has been temporarily suspended.</SuspendedTextContent>
          {/* <SuspendedModalClose onClick={() => setIsUserBan(true)}>
            <NextImage src={modal_close_icon} />
          </SuspendedModalClose> */}
        </SuspendedModalLayout>
      </ModalBody>
    </>
  )
}

export default SuspendedModal
