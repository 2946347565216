import { BsThermometerLow } from 'react-icons/bs'
import NextImage from 'shared/components/nextImage/NextImage'
import styled, { css, keyframes } from 'styled-components'
import { media } from 'utilities/helpers'
import { PrimaryButton } from './defaultStyles'
import { customColors, themeDark, themeLight } from './theme'

const colors = {
  inputField: '#BCBCBC',
  borderDark: '#aaa',
  disabledBorder: '#bbb',
  disabledBg: '#ddd',
  disabledText: '#999',
}
const opacityAnimation = keyframes`
0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`
export const RequiredField = styled.div<any>`
  position: absolute;
  top: 0;
  right: 0;
  padding: 11px 19px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 6px;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.border : 'rgba(255, 255, 255, 0.1)'};
  border-radius: 0px 10px;
  align-items: center;
  ${media.sm`
  padding: 6px 14px;
  right: unset;
  margin-top: 20px;
  `}
  ${media.s`
  padding: 4px 12px;
  `}
  .star {
    font-weight: 600;
    font-size: 18px;
    color: ${customColors.redText};
    transform: translateY(5px);
  }
  .text {
    font-weight: 400;
    font-size: 12px;
    color: ${themeDark.textTertiary};
  }

  ${media.sm`
    position: unset;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    margin: 35px 0;
    display: flex;
  `}
`

export const RequiredFieldStar = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: ${customColors.redText};
  transform: translateY(5px);
`

export const RequiredFieldText = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${themeDark.textTertiary};
`

export const FieldBlock = styled.div<any>`
  position: relative;
  display: block;
  margin-bottom: 25px;
  .logo-text-align {
    ${media.sm`
    margin-top:68px;
    `}
    margin-top: 38px;
  }
  label {
    font-weight: 600;
    display: block;
    font-size: 18px;
    color: ${({ themeMode }) => (themeMode === 'light' ? customColors.greyText : themeDark.white)};
    position: relative;
    margin-bottom: 8px;
    ${props =>
      props.required &&
      css`
        &::after {
          content: '*';
          position: absolute;
          font-weight: 600;
          font-size: 18px;
          color: ${customColors.errorText};
          /* color: ${themeDark.white}; */
        }
      `}
  }
  .input-field {
    margin-top: 4px;
    background: ${({ themeMode }) =>
      themeMode === 'light' ? themeLight.bgTertiary : themeDark.bgTertiary};
    border-radius: 10px;
    padding: 15px;
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    border: 1px solid
      ${({ themeMode }) => (themeMode === 'light' ? themeLight.borderColor : 'transparent')};
    outline: none;
    transition: all linear 0.2s;
    color: ${({ themeMode }) => (themeMode === 'light' ? customColors.greyText : themeDark.white)};
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
      -webkit-text-fill-color: ${({ themeMode }) =>
        themeMode === 'light' ? customColors.greyText : themeDark.white};
      border: 1px solid
        ${({ themeMode }) =>
          themeMode === 'light' ? themeDark.textSecondary : themeDark.bgTertiary};
      -webkit-box-shadow: 0 0 0px 1000px
        ${({ themeMode }) => (themeMode === 'light' ? themeLight.bgTertiary : themeDark.bgTertiary)}
        inset;
      transition: background-color 5000s ease-in-out 0s;
    }
    ${media.sm`
    border-radius: 6px;
    padding: 12px;
    font-weight: 400;
    font-size: 12px;
  `}
    :hover {
      border: 1px solid
        ${({ themeMode }) =>
          themeMode === 'light' ? themeDark.textTertiary : themeDark.textSecondary};
    }
  }
  .red {
    background: ${themeDark.customColors?.background};
  }
  textarea {
    resize: none;
  }
  .description {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.333333px;
    color: ${customColors.textColor};
    margin-top: 8px;
    span {
      color: ${themeDark.brandColor};
    }
  }
  .textarea {
    margin-top: 4px;
    width: 100%;
    height: 118px;
    background: ${({ themeMode }) =>
      themeMode === 'light' ? themeLight.bgTertiary : themeDark.bgTertiary};
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    color: ${({ themeMode }) => (themeMode === 'light' ? customColors.greyText : themeDark.white)};
    padding: 15px;
    border: ${({ themeMode }) =>
      themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none'};
    outline: none;
    transition: all linear 0.2s;
    ${media.sm`
    border-radius: 6px;
    font-size: 12px;
  `}

    :hover {
      border: 1px solid
        ${({ themeMode }) =>
          themeMode === 'light' ? themeDark.textTertiary : themeDark.textSecondary};
    }
  }
  .errorText {
    font-weight: 500;
    font-size: 13px;
    color: ${customColors.errorText};
  }
  .image {
    width: 15px;
    height: 15px;
  }
  .text {
    margin-right: 10px;
  }
`
export const LinkBlock = styled.div<any>`
  margin-bottom: 25px;
  label {
    font-weight: 600;
    display: block;
    font-size: 18px;
    color: ${({ themeMode }) => (themeMode === 'light' ? customColors.greyText : themeDark.white)};
    position: relative;
    margin-bottom: 8px;
  }
  .inputBlock {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 54px;
    margin-bottom: 16px;
    background: ${({ themeMode }) =>
      themeMode === 'light' ? themeLight.bgTertiary : themeDark.bgTertiary};
    border-radius: 10px;
    border: 1px solid
      ${({ themeMode }) => (themeMode === 'light' ? themeDark.textSecondary : 'transparent')};
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    transition: all linear 0.2s;
    :hover {
      border: 1px solid
        ${({ themeMode }) =>
          themeMode === 'light' ? themeDark.textTertiary : themeDark.textSecondary};
    }
    ${media.sm`
    height: 43px;
  `}
    .input-field {
      font-weight: 400;
      width: 80%;
      height: 100%;
      border: none;
      border-right: 1px solid ${themeDark.textTertiary};
      border-right: 1px solid ${({themeMode}) => themeMode === "light" ? themeDark.textSecondary : themeDark.textTertiary};
      padding-right: 10px;
      outline: none;
      background: ${({ themeMode }) =>
        themeMode === 'light' ? themeLight.bgTertiary : 'transparent'};
      color: ${({ themeMode }) => (themeMode === 'light' ? 'none' : themeDark.white)};
      ${media.sm`
     border-radius: 6px;
     font-size: 12px;
  `}
    }
    .image {
      width: 30px;
      height: 30px;
    }
  }
`

export const InputBlock = styled.div<any>`
  width: 100%;
  max-width: 500px;
  height: 50px;
  margin-bottom: 10px;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.bgTertiary : themeDark.bgTertiary};
  border-radius: 10px;
  border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : 'transparent')};
  display: flex;
  justify-content: space-evenly;
  transition: all linear 0.2s;
  :hover {
    border: 1px solid
      ${({ themeMode }) =>
        themeMode === 'light' ? themeDark.textTertiary : themeDark.textSecondary};
  }
  align-items: center;
  ${media.sm`
  height: 43px;
  `}

  .input-field {
    font-weight: 400;
    font-size: 14px;
    width: 80%;
    border: none;
    border-right: 1px solid ${themeDark.textTertiary};
    padding-right: 10px;
    outline: none;
    background: ${({ themeMode }) =>
      themeMode === 'light' ? themeLight.bgTertiary : 'transparent'};
    color: ${({ themeMode }) => (themeMode === 'light' ? themeDark.textTertiary : themeDark.white)};
    ${media.sm`
     border-radius: 6px;
     font-size: 12px;
  `}
  }
  .image {
    width: 30px;
    height: 30px;
  }
`

//properties and levels modal
export const ConstantModalWrapper = styled.div<any>`
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.bgTertiary};
  border: ${({ themeMode }) => (themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none')};
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transition: all linear 0.2s;
  :hover {
    border: 1px solid
      ${({ themeMode }) =>
        themeMode === 'light' ? themeDark.textTertiary : themeDark.textSecondary};
  }
  .input-field {
    font-weight: 600;
    font-size: 16px;
    width: 80%;
    border: none;
    border-right: 1px solid ${themeDark.textTertiary};
    padding-right: 10px;
    outline: none;
    background: transparent;
    color: ${({ themeMode }) => (themeMode === 'light' ? themeDark.textTertiary : themeDark.white)};

    ::placeholder {
      font-weight: 400;
      font-size: 14px;
    }
  }
  .image {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`
export const ModalContentWrapper = styled.div<any>`
  padding: 20px;
  border-bottom: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.borderColor : themeDark.borderColor)};
`

export const HeadingText = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: ${customColors.textColor};
`
export const ModalInputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;

  ${media.sm`
    flex-direction: column;
  `}
`
export const InputContainer = styled.div`
  width: 50%;

  ${media.sm`
    width: 100%;
  `}
`

export const InputTypeLabel = styled.label`
  display: block;
  margin-bottom: 5px;
`
export const InputNameLabel = styled.label`
  display: block;
  margin-bottom: 5px;
`
export const AddMoreButtonWrapper = styled.div`
  margin: 30px 0px;
`
export const SaveButtonWrapper = styled.div<any>`
  height: 100px;
  display: grid;
  place-items: center;
  background: ${({ themeMode }) => (themeMode === 'light' ? themeLight.bgTertiary : 'none')};
`
export const AddMoreButton = styled.button<any>`
  background: ${({ theme }) => theme.bgTertiary};
  color: ${({ theme }) => theme.text};
  font-weight: 600;
  font-size: 16px;
  border: 2px solid ${themeDark.assetDetail.cardTitle};
  border-radius: 40px;
  padding: 10px 30px;
  cursor: pointer;
`

export const SaveButton = styled.button<any>`
  background-color: ${themeDark.brandColor};
  color: ${themeDark.white};
  font-weight: 600;
  font-size: 16px;
  border: none;
  border-radius: 40px;
  padding: 11px 52px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`
//Tooltip Styles

export const CollectionToolTipContainer = styled.div`
  font-size: 12px;
  color: ${themeDark.white};
  font-weight: 500;
  ${media.sm`
    width: 200px;
  `}
`
export const TextContent = styled.span`
  font-weight: 500;
  color: ${themeDark.brandColor};
  font-size: 12px;
  a {
    text-decoration: none;
    color: ${themeDark.brandColor};
  }
`

//Explore Categories
export const ExploreCollectionsBannerLayout = styled.div`
  height: 276px;
`
export const ExploreCollectionsBanner = styled.div<any>`
  height: 100%;
  width: 100%;
  position: relative;
`
export const ExploreCollectionsHeaderContainer = styled.div`
  /* height: 90px; */
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 10px;
`
export const ExploreCollectionsHeaderSubContainer = styled.div<any>`
  min-height: 178px;
  background-color: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.white : themeDark.assetDetail.background};
  border-radius: 10px;
  width: 100%;
  /* position: absolute; */
  position: relative;
  top: -90px;
  z-index: 9;
  box-shadow: ${({ themeMode }) =>
    themeMode === 'light' ? '0px 1px 14px rgba(176, 176, 176, 0.25)' : 'none'};

  ${media.sm`
    width: 90%;
  `}
`
export const ExploreCollectionsHeaderText = styled.div<any>`
  font-weight: 300;
  font-size: 60px;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  text-align: center;
  span {
    text-transform: capitalize;
  }

  padding: 24px 0 0 0;

  ${media.sm`
    font-size: 40px;
    padding: 0 10px;
  `}

  @media screen and (max-width: 300px) {
    font-size: 35px;
  }
`
//sideNav onSale
export const ProfileSearchInputContainer = styled.div<any>`
  width: 91%;
  margin-top: 10px;
  margin-left: 13px;
`
export const CheckboxMainLayout = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  ${media.lg`
   padding:10px 20px;
  `}
`
export const CheckboxContainer1 = styled.div`
  margin: 10px 0px;
  display: flex;
  justify-content: start;
  align-items: center;
  text-transform: capitalize;
`
export const CheckboxContainer2 = styled.div`
  margin: 5px 0px;
`

export const OnSaleMainContainer = styled.div`
  width: 100%;
  min-height: 135px;
`
export const CheckedBox = styled.label<any>`
  display: flex;
  position: relative;
  > input {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: pointer;
    opacity: 0;
  }
  > input + label {
    position: relative; /* permet de positionner les pseudo-éléments */
    padding-left: 35px; /* fait un peu d'espace pour notre case à venir */
    padding-top: 2px;
    cursor: pointer; /* affiche un curseur adapté */
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      width: 26px;
      height: 26px; /* dim. de la case */
      border: ${({ themeMode }) =>
        themeMode === 'light'
          ? `1px solid ${themeLight.borderColor}`
          : `1px solid ${colors.borderDark}`};
      background: ${({ themeMode }) =>
        themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
      border-radius: 50px; /* angles arrondis */
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3); /* légère ombre interne */
    }
    &:after {
      content: '✔';
      position: absolute;
      top: 1px;
      left: 5px;
      font-size: 18px;
      color: ${themeDark.brandColor};
      transition: all 0.2s; /* on prévoit une animation */
    }
  }
  > input:not(:checked) + label {
    &:after {
      opacity: 0; /* coche invisible */
      transform: scale(0); /* mise à l'échelle à 0 */
    }
  }
  > input:disabled:not(:checked) + label {
    &:before {
      box-shadow: none;
      border-color: ${colors.disabledBorder};
      background-color: ${colors.disabledBg};
    }
  }
  > input:checked + label {
    &:after {
      opacity: 1; /* coche opaque */
      transform: scale(1); /* mise à l'échelle 1:1 */
    }
  }
  > input:disabled:checked + label {
    &:after {
      color: ${colors.disabledText};
    }
  }
  > input:disabled + label {
    color: ${colors.borderDark};
  }
  > input:checked:focus + label,
  input:not(:checked):focus + label {
    &:before {
      border: 1px solid ${themeDark.assetDetail.cardTitle};
    }
  }
`
export const OnSaleLayout = styled.div<any>`
  min-height: 166px;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.bgTertiary : themeDark.bgTertiary};
  border-right: none;
  border-left: none;
`
export const PropertyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const PropertyNumber = styled.div`
  margin-right: 25px;
  font-weight: 500;
  font-size: 16px;
  color: ${themeDark.textSecondary};
`
// wrapper for the privacy and tos components
export const SharedWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-gap: 5px;
  min-height: 60vh;
`
export const SharedCenterHeadings = styled.div`
  width: 100%;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  ${media.sm`
  font-size: 36px;
  `}
  ${media.s`
  font-size: 28px;
  `}
`
export const SharedCenterDescriptions = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  ${media.sm`
  font-size: 14px;
  `}
  ${media.s`
  font-size: 12px;
  `}
`
export const SharedBlock = styled.div<any>`
  width: 100%;
  font-weight: 500;
  font-size: 16px;
`
export const SharedParagraph = styled.p`
  margin: 15px 0px;
  opacity: 0.8;
`
//sideNav Status
export const StatusLayout = styled.div<any>`
  height: 143px;
  width: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.bgTertiary : themeDark.bgTertiary};
  border-right: none;
  border-left: none;
`
export const StatusMainContainer = styled.div`
  height: 113px;
  width: 90%;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 150px);
  grid-template-rows: repeat(2, 1fr);
  justify-content: start;
  align-items: center;
`

export const StatusButton = styled.div<any>`
  background-color: ${({ active }) =>
    active ? themeDark.brandColor : themeDark.assetDetail.background};
  border: 1px solid ${themeDark.assetDetail.cardTitle};
  border-radius: 40px;
  outline: none;
  font-size: 14px;
  padding: ${props => props.padding};
  text-align: center;
  color: ${themeDark.white};
  font-weight: 500;
  cursor: pointer;
`
//SideNav Price
export const PriceLayout = styled.div<any>`
  height: 224px;
  width: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.backArrowBg};
  border-right: none;
  border-left: none;
`

export const PriceMainContainer = styled.div`
  width: 90%;
  height: 190px;
`
export const PriceFilterButton = styled.button<any>`
  background-color: ${themeDark.textTertiary};
  border: 1px solid ${themeDark.assetDetail.cardTitle};
  border-radius: 40px;
  outline: none;
  font-size: 14px;
  padding: 12px 55px;
  color: ${themeDark.white};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
export const MinMaxLayout = styled.div`
  display: flex;
  margin: 15px 0px;
  align-items: center;
`
export const SearchInputContainer = styled.div`
  width: 45%;
`
export const MinMaxText = styled.div`
  margin: 0px 5px;
`
//sideNav Chain
export const ChainLayout = styled.div`
  min-height: 125px;
  width: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeDark.bgTertiary};
`
export const ChainMainContainer = styled.div`
  width: 90%;
  min-height: 125px;
`
export const EtherIconContainer = styled.div<any>`
  display: flex;
  align-items: center;
  margin: 15px 0px;
  cursor: pointer;
`
export const EtherIconImage = styled.div`
  display: flex;
  margin-right: 15px;
  border: 1px solid ${themeDark.white};
  border-radius: 50%;
  overflow: hidden;
`
export const Tick = styled.div`
  width: 36px;
  height: 36px;
  border: 1px solid ${themeDark.white};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const CashbackButton = styled.button<any>`
  background: ${themeLight.aiWarning};
  border-radius: 16px;
  width: max-content;
  border: none;
  font-size: 12px;
  line-height: 18px;
  color: ${themeLight.text};
  padding: 5px 12px;
  ${props =>
    props.card &&
    css`
      position: absolute;
      bottom: 10px;
      right: 10px;
    `}
`
export const DropMenu = styled.div<any>`
  position: absolute;
  top: ${props => (props.top ? props.top : '210px')};
  width: ${props => (props.wd ? props.wd : '')};
  height: ${props => (props.ht ? props.ht : 'fit-content')};
  background: ${props => (props.bg ? props.bg : '')};
  border-radius: ${props => (props.br ? props.br : '')};
  padding: ${props => (props.pa ? props.pa : '0')};
  transform: translateX(-40%);
  overflow: hidden;
  z-index: 1000;
  border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.border)};
  box-shadow: ${({ themeMode }) =>
    themeMode === 'light' ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none'};
  p {
    padding: 15px;
    color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
    :hover {
      background: ${themeDark.brandColor};
      color: ${themeDark.white};
    }
  }
  ${media.sm`
      left: ${(props: { left: any }) => (props.left ? props.left : '30px')};
  `}
`
//Privacy and TOS styles
export const TOSWrapper = styled.div`
  margin-bottom: 30px;
  ${media.sm`
  margin-bottom: 25px;
  `}
  ${media.s`
  margin-bottom: 20px;
  `}
`
export const TOSTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${themeDark.white};
  ${media.sm`
  font-size: 14px;
  `}
  ${media.s`
  font-size: 12px;
  `}
`
export const TOSDescription = styled.div`
  font-weight: 500;
  font-size: 14px;
  ${media.sm`
  font-size: 12px;
  `}
  ${media.s`
  font-size: 10px;
  `}
`
//TippyTooltip
export const TippyToolTipContainer = styled.div<any>`
  width: ${props => props.width};
`
export const FilterDropdown = styled.div<any>`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1000000;
  overflow-x: hidden;
  top: 82px;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg};
`
export const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  p {
    font-weight: 600;
    font-size: 18px;
  }
`
export const DropDown = styled.div<any>`
  position: absolute;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.background};
  border: 1px solid
    ${({ themeMode }) =>
      themeMode === 'light' ? themeDark.textTertiary : themeDark.assetDetail.backArrowBg};
  border-radius: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
  padding: 20px;
  width: max-content;
  max-width: 300px;
  top: 20px;
  z-index: 10;
  left: -50px;
`
export const CancelButton = styled(PrimaryButton)<any>`
  border: 2px solid ${themeDark.textSecondary};
  background: none;
  border-radius: 50px;
  outline: none;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  height: 60px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s all;
  color: ${themeDark.textTertiary};
  ${media.sm`
    order: 2;
  `}
`

export const ButtonWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  place-items: center;
  margin: 20px 0;
  ${media.sm`
     grid-template-columns: 1fr;
  `}
`

export const MobileFilterWrapper = styled.div<any>`
  display: none;
  margin: 20px 0;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.background};
  border: ${({ themeMode }) => (themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none')};
  ${media.lg`
   display:flex; 
`}
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
`
//Image Border
export const ImageBorder = styled(NextImage)`
  border-radius: 10px;
`

// Tooltip content

export const ToolTipContent = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${themeDark.white};
`

export const ToolTipLink = styled.a`
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: ${themeLight.assetDetail.propertyBorder};
  text-decoration: none;
`
export const SquareCheckedBox = styled.label<any>`
  display: inline-block;
  position: relative;
  > input {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    cursor: pointer;
    opacity: 0;
  }
  > input + label {
    position: relative; /* permet de positionner les pseudo-éléments */
    padding-left: 35px; /* fait un peu d'espace pour notre case à venir */
    padding-top: 2px;
    cursor: pointer; /* affiche un curseur adapté */
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      width: 26px;
      height: 26px; /* dim. de la case */
      border: ${({ themeMode }) =>
        themeMode === 'light'
          ? `1px solid ${themeLight.borderColor}`
          : `1px solid ${colors.borderDark}`};
      background: ${({ themeMode }) =>
        themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
      border-radius: 0px; /* angles arrondis */
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3); /* légère ombre interne */
    }
    &:after {
      content: '✔';
      position: absolute;
      top: 1px;
      left: 5px;
      font-size: 18px;
      color: ${themeDark.brandColor};
      transition: all 0.2s; /* on prévoit une animation */
    }
  }
  > input:not(:checked) + label {
    &:after {
      opacity: 0; /* coche invisible */
      transform: scale(0); /* mise à l'échelle à 0 */
    }
  }
  > input:disabled:not(:checked) + label {
    &:before {
      box-shadow: none;
      border-color: ${colors.disabledBorder};
      background-color: ${colors.disabledBg};
    }
  }
  > input:checked + label {
    &:after {
      opacity: 1; /* coche opaque */
      transform: scale(1); /* mise à l'échelle 1:1 */
    }
  }
  > input:disabled:checked + label {
    &:after {
      color: ${colors.disabledText};
    }
  }
  > input:disabled + label {
    color: ${colors.borderDark};
  }
  > input:checked:focus + label,
  input:not(:checked):focus + label {
    &:before {
      border: 1px solid ${themeDark.assetDetail.cardTitle};
    }
  }
`
export const OpacityAnimation = styled.div<any>`
  height: ${props => props.height};
  animation: ${opacityAnimation} 1.5s;
`
export const SkeletonLoadingAnimation = keyframes`
  0% {
    background: #2b3641;
  }
  100% {
    background: ${themeDark.textTertiary};
  }
`
export const SkeletonLoadingReverseAnimation = keyframes`
  0% {
    background: ${themeDark.textTertiary};
    
  }
  100% {
    background: #2b3641;
  }
`
