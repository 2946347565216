import { media, screenSizes } from './../../utilities/helpers'
import styled from 'styled-components'
import { Text } from 'modules/sell/Sell.style'
import { themeDark, themeLight } from 'styles/theme'

export const ErrorBoundaryContainer = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`

export const ErrorCardContainer = styled.div<any>`
  width: 80%;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.borderColor : themeDark.assetDetail.background};
  /* margin: 60px 0 60px 60px; */
  padding: 10px;
  border-radius: 10px;
  /* min-height: 600px; */
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${({ themeMode }) => (themeMode === 'light' ? `1px solid ${themeLight.borderColor}` : 'none')};
  justify-content: center;
`
export const ErrorImageContainer = styled.div<any>`
  position: relative;
  height: 378px;
  width: 520px;
  margin-top: 30px;
  padding: 0px 10px;
  ${media.md`
  height: 300px;
  width: 400px;
  margin-top: 20px;
  `}
  ${media.sm`
  height: 210px;
  width: 270px;
  margin-top: 10px;
  `}
  ${media.s`
  height: 178px;
  width: 220px;
  `}
`

export const ErrorText = styled.p<any>`
  font-weight: ${props => (props.fw ? props.fw : 'normal')};
  font-size: ${props => (props.fs ? props.fs : '')};
  color: ${props => (props.clr ? props.clr : '')};
  padding-left: ${props => (props.pl ? props.pl : '')};
  padding: ${props => (props.pa ? props.pa : '10px 20px')};
  margin: ${props => (props.ma ? props.ma : '')};
  margin-bottom: ${props => (props.mb ? props.mb : '')};
  letter-spacing: ${props => (props.ls ? props.ls : '')};
  text-align: ${props => (props.ta ? props.ta : '')};
  display: flex;
  a {
    color: inherit;
    text-decoration: none;
  }

  ${media.sm`
     font-size: ${({ msfs }: any) => (msfs ? msfs : '15px')};
  `}
`
