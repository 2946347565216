import styled, { keyframes } from 'styled-components'
export const animateHover = keyframes`
0% {
  transform :scale(1)
}
50% {
  transform : scale(1.03)
}
100% {
  transform : scale(1)
}
`
export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }`
