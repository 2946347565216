import styled from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'
import { screenSizes } from 'utilities/helpers'
import { media } from '../../../utilities/helpers'

const text = {
  color: '#1b1d20',
}
export const ModalBody = styled.div<any>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000000 !important;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(15px);
  overflow: hidden;
`

export const ModalContent = styled.div<any>`
  width: 80%;
  background-color: ${({ themeMode }) => (themeMode === 'light' ? themeDark.white : text.color)};
  border-radius: 10px;
  border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.borderColor : themeDark.borderColor)};
  position: absolute;
  display: inline-block;
  border-radius: 8px;
  margin: 0 auto;
  border: none;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: ${({overflow}) => !overflow ? 'none' : 'auto'};
  padding: 0px;
  max-height: 90vh;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  border-radius: 8px;
  z-index: 100000 !important;
  @media (min-width: ${screenSizes.lg}px) {
    width: 650px;
  }
  ::-webkit-scrollbar {
    width: 0 !important;
  }

  ${media.sm`
    width: 90%;
    height: calc(100vh - 100px);
  `}
`

export const ModalHeadingWrapper = styled.div<any>`
  min-height: 70px;
  display: grid;
  place-items: center;
  border-bottom: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.textTertiary)};
`

export const ModalHeadingText = styled.div<any>`
  font-size: ${({ headerTextFontSize }) => (headerTextFontSize ? headerTextFontSize : '18px')};
  font-weight: 600;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  overflow-wrap: anywhere;
  text-align: center;
  padding: 24px 55px;
  ${media.sm`
    max-width: 80%;
    text-align: center;
    padding:20px;
  `}
`
export const ModalClose = styled.div`
  position: absolute;
  top: 24px;
  right: 20px;
  cursor: pointer;

  ${media.sm`
    top: 10px;
    right: 10px;
  `}
`
