import React from 'react'
import { IoCloseSharp } from 'react-icons/io5'
import {
  StatusNotificationCloseLogo,
  StatusNotificationContainer,
  StatusNotificationLayout,
} from './Status.style'

const Status = ({ info, setCloseNotification }: any) => {
  return (
    <>
      <StatusNotificationLayout>
        <StatusNotificationContainer>{info}</StatusNotificationContainer>
        <StatusNotificationCloseLogo onClick={() => setCloseNotification(true)}>
          <IoCloseSharp size={20} />
        </StatusNotificationCloseLogo>
      </StatusNotificationLayout>
    </>
  )
}

export default Status
