import React, { useEffect } from 'react'
// default modules
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from '../styles/globalStyles'
import { themeDark, themeLight } from '../styles/theme'
import type { AppProps } from 'next/app'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { useAppSelector } from 'hooks/useAppSelector'
import { Web3ReactProvider } from '@web3-react/core'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import store from '../logic/redux/store'
import Web3 from 'web3'
import NextNProgress from 'nextjs-progressbar'

import Layout from '../shared/layout/Layout'
// css
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../public/assets/fonts/stylesheet.css'
import '../styles/utils.css'
import Notifications from '../shared/components/notifications/Notifications'
//tippyTooltip
import 'tippy.js/dist/tippy.css'
import 'tippy.js/dist/svg-arrow.css'

// theme
import Toggle from '../shared/Toggler'
import { useDarkMode } from '../hooks/useToggleTheme'
import Script from 'next/script'
import * as snippet from '@segment/snippet'
import { Router } from 'next/router'
//date css
import 'react-multi-date-picker/styles/colors/green.css'
import 'react-multi-date-picker/styles/backgrounds/bg-dark.css'
import 'react-multi-date-picker/styles/backgrounds/bg-gray.css'
import ErrorBoundary from 'shared/errorBoundary/ErrorBoundary'
import { Salesiq } from './chatBot'
import ReConnect from 'shared/authentication/ReConnect'
// Create a react query client
// const queryClient = new QueryClient()

// Initializing web3 provider
function getLibrary(provider: any) {
  const library = new Web3(provider)
  return library
}
// handling the theme
function MainApp({ children }: any) {
  const { theme } = useAppSelector(state => state.theme)
  const defaultTheme = theme === 'light' ? themeLight : theme === 'dark' ? themeDark : themeLight
  return <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
}

function renderTwitterSnippet() {
  return `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
  },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
  a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
  twq('config','occdf')`
}

// Segment analytics page trigger
Router.events.on('routeChangeComplete', url => {
  // @ts-ignore window.analytics undefined below
  window.analytics.page(url)
  renderTwitterSnippet()
})

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = React.useState(() => new QueryClient())

  // `Segment` analytics initialization
  function renderSnippet() {
    const opts = {
      apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
      page: true,
    }

    if (process.env.NODE_ENV === 'development') {
      return snippet.max(opts)
    }

    return snippet.min(opts)
  }

  // const clearLocalStorage = (e: any) => {
  //   e.preventDefault()
  //   if (typeof window !== 'undefined') {
  //     window.localStorage.removeItem('provider')
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener('beforeunload', clearLocalStorage)

  //   return () => {
  //     window.removeEventListener('beforeunload', clearLocalStorage)
  //   }
  // }, [])
  return (
    <>
      <Script id="segment-script" dangerouslySetInnerHTML={{ __html: renderSnippet() }} />
      <Script id="twitter-page-view-script" dangerouslySetInnerHTML={{ __html: renderTwitterSnippet() }} />
      <Provider store={store}>
        <Web3ReactProvider getLibrary={getLibrary}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <MainApp>
                <NextNProgress color={themeDark.brandColor} />
                <GlobalStyles />
                <Layout>
                  <ErrorBoundary>
                    <Component {...pageProps} />
                    <Salesiq
                      widgetCode={
                        '64d3b27150a2ba4940bc5c200c98ff31097d4301d97b50da1b8af35ff0b416fd'
                      }
                      domain={'https://salesiq.zoho.com/widget'}
                    />
                  </ErrorBoundary>
                  <ReConnect />
                  <Notifications />
                </Layout>
              </MainApp>
              <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
            </Hydrate>
          </QueryClientProvider>
        </Web3ReactProvider>
      </Provider>
    </>
  )
}

export default MyApp
