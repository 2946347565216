import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: 32px 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
export const Description = styled.div`
  font-size: 24px;
`
export const Content = styled.div`
  margin: 32px 0px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.auctionWinnerModalBorder};
  border-radius: 10px;
`
export const InfoHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  padding: 16px;
  background-color: ${({ theme }) => theme.bgTertiary};
  border-bottom: 1px solid ${({ theme }) => theme.auctionWinnerModalBorder};
  border-radius: 10px 10px 0px 0px;
`
export const InfoHeaderText = styled.div``

export const InfoContainer = styled.div`
  max-height: 270px;
  overflow-y: auto;
`
export const Info = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  padding: 24px 16px;
  background-color: ${({ theme }) => theme.bgTertiary};
  border-bottom: 1px solid ${({ theme }) => theme.auctionWinnerModalBorder};
  :hover {
    background-color: ${({ theme }) => theme.accordionHeaderBg}
  }
`
const alignCenter = css`
  display: flex;
  align-items: center;
  font-size: 14px;
`
export const NftDiv = styled.div`
  ${alignCenter}
  color: ${({ theme }) => theme.brandColor};
  div {
    cursor: pointer;
  }
`
export const PriceDiv = styled.div`
  ${alignCenter}
`
export const TransactionDiv = styled.div`
  ${alignCenter}
  span {
    color: ${({ theme }) => theme.auctionWinnerLink};
    font-size: 16px;
  }
`
export const PrimaryButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0px;
`