import { useMutation, useQueries, useQuery } from 'react-query'
import axios from 'axios'
import toast from 'react-hot-toast'
import { apiEndPoints } from 'utilities/apiUrls'
import { axiosInstance } from 'utilities/axiosInterceptor'
import { CacheKeys } from './cacheKeys'

// Image Upload to backend url
export interface I_uploadImageFn {
  url: any
  data: any
  config?: any
}
const uploadImageFn = async (data: I_uploadImageFn) => {
  const url = data.url
  const config = data?.config
  return await axios.put(url, data?.data, config)
}

export const useUploadImageMutation = () => {
  return useMutation(uploadImageFn, {
    onError: (error: any) => {
      toast.success('Asset Upload Failed')
      console.log(error)
    },
  })
}

const platformStatus = () => {
  const url = apiEndPoints.platform.getPlatformFee
  return axiosInstance.get(url)
}

export const useGetPlatformStatusQuery = () => {
  return useQuery([CacheKeys.platformStatus], platformStatus)
}

//csrfToken
export const getCSRFToken = async () => {
  try {
    const accessToken: any = window.localStorage.getItem('accessToken')
    let authToken: any = typeof window !== 'undefined' ? JSON.parse(accessToken) : null
    if (authToken) {
      const response = await axios.get(
        `${process.env.NEXT_PUBLIC_API_BASE_URL}${apiEndPoints.user.csrfToken}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          withCredentials: true,
        },
      )

      return response
    }
  } catch (error) {
    console.log(error)
  }
}
