export const resourcesUrl = {
  platformStatusUrl: 'https://status-junglenft.dev.rapidinnovation.tech/#',
  helpCenter: 'https://junglenft.zohodesk.com/portal/en/kb/junglenft',
  linkedin: 'https://www.linkedin.com/company/junglenftmarket/',
  twitter: 'https://twitter.com/junglenftmarket/',
  // discord: 'https://discord.com/invite/bDnPrw5tXV',
  discord: 'https://discord.gg/bDnPrw5tXV',
  instagram: 'https://www.instagram.com/junglenftmarket/',
  facebook: 'https://www.facebook.com/junglenftmarket',
  pinterest: 'http://example.com/',
  youtube: 'https://www.youtube.com/channel/UCRYvVRqEQ-E3z-x_cXglI4w',
  reddit: 'http://example.com/',
  raiseTicket: 'https://support.gojungle.xyz/portal/en/newticket',
  ipfs: 'https://ipfs.io/',
  tiktok: 'https://tiktok.com/@junglenftmarket',
}
