import styled from 'styled-components'
import { BsSun, BsMoon } from 'react-icons/bs'
import { useAppDispatch } from 'hooks/useAppDispatch'
import { setTheme } from '../logic/redux/slices/themeSlice'
import { useEffect } from 'react'
import { themeDark } from 'styles/theme'
//Toggle Button
export const ToggleDiv = styled.div<any>`
  /* position: fixed;
  bottom: 20px; */
  max-width: 40px;
  margin-left: 0px;
  background: ${({themeMode}) => themeMode === 'light' ? themeDark.borderColor : '#2e2f36'};

  padding: 2px 4px;
  left: 0;
  border-radius: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer !important;
  gap: 2px;
`
export const LightThemeDiv = styled.div`
  cursor: pointer !important;
`

export const DarkThemeDiv = styled.div`
  cursor: pointer !important;
`
export const WhiteDot = styled.div`
  width: 16px;
  height: 16px;
  background: ${themeDark.white};
  border-radius: 100%;
  margin: 0px 2px;
`

//Toggle theme logic
const Toggle = ({ theme, toggleTheme }: any) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setTheme(theme))
  }, [theme])

  return (
    <ToggleDiv themeMode = {theme} onClick={toggleTheme}>
      <LightThemeDiv>{theme === 'light' ? <BsSun color="white" /> : <WhiteDot />}</LightThemeDiv>
      <DarkThemeDiv>{theme === 'dark' ? <BsMoon /> : <WhiteDot />}</DarkThemeDiv>
    </ToggleDiv>
  )
}

export default Toggle
