import styled, { css, keyframes } from 'styled-components'
import { themeDark } from 'styles/theme'
import { media } from '../../../utilities/helpers'

export const ModifiedContainer = styled.div`
  position: relative;
`
const animateKeyframe = keyframes`
0% {transform : translateY(300px)}
100% {transform-y :translateY(0px)}
`
export const DropDownNewsletter = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg};
  z-index: 100;
  ${({ clickMenu }) =>
    clickMenu === true &&
    css`
      animation: ${animateKeyframe} linear 0.5s;
    `}
  position: fixed;
  overflow: auto;
  top: 100px;
  transition: 0.5s;
  /* box-shadow: 0px 1px 27px rgba(164, 161, 161, 0.2); */
  width: 100%;
  height: calc(100% - 100px);
  padding: 10px;

  @media screen and (min-width: 992px) {
    display: none;
  }
`

export const NavContainer = styled.div<any>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  align-items: center;
  padding: 0 20px;
  box-shadow: ${({ themeMode }) =>
    themeMode === 'light' ? '0 3px 10px rgb(0 0 0 / 0.2)' : ' 0px 0px 15px #5A5858'};
  position: fixed;
  z-index: 100;
  top: 0;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
`
export const ImageWrapper = styled.div`
  border-right: 1px solid #c4c4c4;
  padding: 20px;
  cursor: pointer;
  ${media.sm`
    padding: 20px 0;
    margin: 20px 0px;
  `}
`
export const Heading = styled.h3<any>`
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  padding: 0 10px;
  ${media.xl`
    font-size: 16px;
  `}
`
export const NavTabs = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin-left: -30px;
  @media screen and (max-width: 992px) {
    display: none;
  }
`

export const clickSpan = css<any>`
  span {
    background: ${props => props.theme.text};
    position: absolute;
    left: 0;
    height: 3px;
    width: 100%;
    transition: all linear 0.2s;
    :nth-child(1) {
      top: 0;
    }
    :nth-child(2) {
      top: 43%;
    }
    :nth-child(3) {
      bottom: 0px;
    }
  }
`
export const unClickedSpan = css<any>`
  span {
    background: ${props => props.theme.text};
    position: absolute;
    left: 0;
    height: 3px;
    width: 100%;
    transition: all linear 0.3s;
    :nth-child(1) {
      top: 0px;
      transform: rotate(-45deg) translate(-10px, 7px);
    }
    :nth-child(2) {
      top: 43%;
      transform: rotate(45deg) translate(0px, 2px);
    }
    :nth-child(3) {
      display: none;
    }
  }
`

export const HeaderListIcon = styled.div<any>`
  @media (max-width: 1024px) {
    height: 25px;
    cursor: pointer;
    position: relative;
    width: 30px;
    transition: all linear 0.3s;
    ${props => (props.click ? clickSpan : unClickedSpan)}
  }
`

export const NavTab = styled.li`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0 10px;
  cursor: pointer;
  transition: all linear 0.2s;
  :hover {
    color: ${themeDark.brandColor};
  }
  ${media.xl`
  font-size:12px;
  margin:0 5px;
  `}
  ${media.lg`
  font-size:16px;
  `}
`

export const NavTabsMobile = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const NavTabMobile = styled(NavTab)<any>`
  text-align: left;
  padding: 20px 0;
`

export const SocialImgWrap = styled.div`
  margin: 0 10px;
  cursor: pointer;
  @media screen and (max-width: 992px) {
    display: none;
  }
`

export const SearchContainer = styled.div<any>`
  background: ${({ themeMode }) => (themeMode === 'light' ? 'hsla(0, 0%, 76%,0.15)' : '#262626')};
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 0;
  display: block;
  border-radius: 20px;
`

export const InputField = styled.input`
  background: transparent;
  border: 0;
  width: 100%;
  min-width: 170px;
  box-sizing: border-box;
  padding-left: 10px;

  :focus-visible {
    border: none;
    outline: none;
  }
`
export const SearchSubContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 10px 10px 30px;
  align-items: center;
`

export const ToggleIcon = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (min-width: 992px) {
    display: none;
  }
`

export const SocialImgWrapMobile = styled.div`
  margin: 0 10px;
  cursor: pointer;

  ${media.sm`
    margin: 0 5px;
  `}
`

export const SocialImgContainer = styled.div`
  @media screen and (max-width: 992px) {
    display: none;
  }
`
