import { screenSizes } from './../utilities/helpers'
import { createGlobalStyle } from 'styled-components'
import { media } from 'utilities/helpers'
import { themeDark, themeLight } from './theme'
export const GlobalStyles = createGlobalStyle<any>`
/* 
@font-face {
     font-family: 'Poppins';
       src: url('/assets/fonts/Poppins-ExtraLightItalic.eot');
     src: local('Poppins ExtraLight Italic'), local('Poppins-ExtraLightItalic'),
         url('/assets/fonts/Poppins-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-ExtraLightItalic.woff') format('woff'),
         url('/assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
     font-weight: 200;
     font-style: italic;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-ExtraBoldItalic.eot');
     src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'),
         url('/assets/fonts/Poppins-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-ExtraBoldItalic.woff') format('woff'),
         url('/assets/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
     font-weight: 800;
     font-style: italic;
 }

@font-face {
       font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-ExtraBold.eot');
     src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
         url('/assets/fonts/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-ExtraBold.woff') format('woff'),
         url('/assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
     font-weight: 800;
     font-style: normal;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-Thin.eot');
     src: local('Poppins Thin'), local('Poppins-Thin'),
         url('/assets/fonts/Poppins-Thin.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-Thin.woff') format('woff'),
         url('/assets/fonts/Poppins-Thin.ttf') format('truetype');
     font-weight: 100;
     font-style: normal;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-BoldItalic.eot');
     src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
         url('/assets/fonts/Poppins-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-BoldItalic.woff') format('woff'),
         url('/assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
     font-weight: bold;
     font-style: italic;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-SemiBoldItalic.eot');
     src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'),
         url('/assets/fonts/Poppins-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-SemiBoldItalic.woff') format('woff'),
         url('/assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
     font-weight: 600;
     font-style: italic;
 }

@font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-Black.eot');
     src: local('Poppins Black'), local('Poppins-Black'),
         url('/assets/fonts/Poppins-Black.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-Black.woff') format('woff'),
         url('/assets/fonts/Poppins-Black.ttf') format('truetype');
     font-weight: 900;
     font-style: normal;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-ThinItalic.eot');
     src: local('Poppins Thin Italic'), local('Poppins-ThinItalic'),
         url('/assets/fonts/Poppins-ThinItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-ThinItalic.woff') format('woff'),
         url('/assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
     font-weight: 100;
     font-style: italic;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-Medium.eot');
     src: local('Poppins Medium'), local('Poppins-Medium'),
         url('/assets/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-Medium.woff') format('woff'),
         url('/assets/fonts/Poppins-Medium.ttf') format('truetype');
     font-weight: 500;
     font-style: normal;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-ExtraLight.eot');
     src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
         url('/assets/fonts/Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-ExtraLight.woff') format('woff'),
         url('/assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
     font-weight: 200;
     font-style: normal;
 }
 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-LightItalic.eot');
     src: local('Poppins Light Italic'), local('Poppins-LightItalic'),
         url('/assets/fonts/Poppins-LightItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-LightItalic.woff') format('woff'),
         url('/assets/fonts/Poppins-LightItalic.ttf') format('truetype');
     font-weight: 300;
     font-style: italic;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-Regular.eot');
     src: local('Poppins Regular'), local('Poppins-Regular'),
         url('/assets/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-Regular.woff') format('woff'),
         url('/assets/fonts/Poppins-Regular.ttf') format('truetype');
     font-weight: normal;
     font-style: normal;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-MediumItalic.eot');
     src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'),
         url('/assets/fonts/Poppins-MediumItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-MediumItalic.woff') format('woff'),
         url('/assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
     font-weight: 500;
     font-style: italic;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-SemiBold.eot');
     src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
         url('/assets/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-SemiBold.woff') format('woff'),
         url('/assets/fonts/Poppins-SemiBold.ttf') format('truetype');
     font-weight: 600;
     font-style: normal;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-Light.eot');
     src: local('Poppins Light'), local('Poppins-Light'),
         url('/assets/fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-Light.woff') format('woff'),
         url('/assets/fonts/Poppins-Light.ttf') format('truetype');
     font-weight: 300;
     font-style: normal;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-BlackItalic.eot');
     src: local('Poppins Black Italic'), local('Poppins-BlackItalic'),
         url('/assets/fonts/Poppins-BlackItalic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-BlackItalic.woff') format('woff'),
         url('/assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
     font-weight: 900;
     font-style: italic;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-Italic.eot');
     src: local('Poppins Italic'), local('Poppins-Italic'),
         url('/assets/fonts/Poppins-Italic.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-Italic.woff') format('woff'),
         url('/assets/fonts/Poppins-Italic.ttf') format('truetype');
     font-weight: normal;
     font-style: italic;
 }

 @font-face {
     font-family: 'Poppins';
     src: url('/assets/fonts/Poppins-Bold.eot');
     src: local('Poppins Bold'), local('Poppins-Bold'),
         url('/assets/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/Poppins-Bold.woff') format('woff'),
         url('/assets/fonts/Poppins-Bold.ttf') format('truetype');
     font-weight: bold;
     font-style: normal;
 } */

html,
body {
  padding: 0;
  margin: 0;
  word-break:break-word;
}

*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html, body {
  height: 100%;
}
body {
  line-height: 1.5;
  font-family:'Poppins' ;
  background: ${({ theme }) => theme.body_bg};
  color: ${({ theme }) => theme.text};
  font-style: normal;
  font-weight: 400;
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
#root, #__next {
  isolation: isolate;
}
.slick-dots li button::before{
  opacity: 1 !important;
  color: ${themeDark.textTertiary} !important;
}
.slick-dots .slick-active button::before {
  color: ${({ theme }) => theme.brandColor} !important;
}
 .slick-dots{
 ${media.md`
      bottom:-70px;
     `}
     }
     input[type=number]{
    -moz-appearance: textfield;
}
.rmdp-calendar {
  width : 100%;
}
.rmdp-day-picker {
  display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-gap: 22px;
    @media (max-width:${screenSizes.sm}px){
      grid-template-columns: repeat(1,1fr);
    }
   
}
.rmdp-week, .rmdp-ym {
  justify-content : center !important;
}
.bg-dark.rmdp-wrapper, .bg-dark .rmdp-month-picker, .bg-dark .rmdp-year-picker, .bg-dark .rmdp-time-picker div input, .rmdp-container .bg-dark.ep-arrow::after {
  background: ${themeDark.assetDetail.backArrowBg};
border-radius: 8px;
}
.rmdp-day.rmdp-today span {
  background : transparent !important;
  color : ${themeDark.white};
}
.rmdp-input {
    width:100% !important;
    border: none !important;
    height: 22px;
    margin: 1px 0;
    padding: 2px 5px;
    background: transparent;
    color:${({ theme }) => theme.text};
    outline: none !important;
    :focus{
      outline:none !important;
      border:none !important;
      box-shadow: none !important;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.tooltip-container {
  background : ${themeDark.textSecondary} !important;
  border:none;
  border-radius: 10px ;
  width: 300px;
  padding:15px;
  /* margin-left:10px; */
}
.tooltip-arrow[data-placement*='right']::after {
  /* border-color: transparent grey transparent transparent !important; */
  /* border-width: 0.5rem 0.4rem 0.5rem 0; */
}
//TippyToolTip
.tippy-box[data-theme~='lightGrey'] {
  background-color: ${themeDark.borderColor};
  border: none;
  color: ${themeDark.white};
  border-radius: 10px;
}

.tippy-box[data-theme~='lightGrey'] > .tippy-svg-arrow {
  fill: ${themeDark.borderColor};
}
.tippy-box[data-theme~='lightGrey'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: ${themeDark.borderColor};
}
.tippy-box[data-theme~='lightGrey'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: ${themeDark.borderColor};
}
.tippy-box[data-theme~='lightGrey'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: ${themeDark.borderColor};
}
.tippy-box[data-theme~='lightGrey'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: ${themeDark.borderColor};
}

.tippy-box[data-theme~="lightGrey"] .tippy-content {
  padding: 13px;
}
.rmdp-top-class {
  width : 100%;
}

//date css
.bg-gray.rmdp-wrapper,
.bg-gray .rmdp-month-picker,
.bg-gray .rmdp-year-picker,
.rmdp-container .bg-gray.ep-arrow::after {
  background-color: ${themeLight.bgTertiary};
  color: ${themeLight.text};
}

.bg-gray .rmdp-day,
.bg-gray .rmdp-day.rmdp-today span,
.bg-gray .rmdp-time-picker div .rmdp-am,
.bg-gray .rmdp-header-values,
.bg-gray .rmdp-panel-header {
  color: ${themeLight.text};
}

.bg-gray .rmdp-day.rmdp-deactive {
  color: ${themeLight.bgTertiary};
}

.bg-gray .rmdp-time-picker div input {
  background-color: ${themeLight.bgTertiary};
  color: ${themeLight.text};
}

.rmdp-container .bg-gray.ep-arrow[direction="top"] {
  border-bottom: 1px solid ${themeLight.bgTertiary};
}

.rmdp-container .bg-gray.ep-arrow[direction="left"] {
  border-right: 1px solid ${themeLight.bgTertiary};
}

.rmdp-container .bg-gray.ep-arrow[direction="right"] {
  border-left: 1px solid ${themeLight.bgTertiary};
}

.rmdp-container .bg-gray.ep-arrow[direction="bottom"] {
  border-top: 1px solid ${themeLight.bgTertiary};
}

`
