import React from 'react'
import Script from 'next/script'

export const Salesiq = (props: any) => {
    function renderSnippet () {
        return `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode:"64d3b27150a2ba4940bc5c200c98ff31097d4301d97b50da1b8af35ff0b416fd", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${props.domain}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`
    }
  return (
      props.widgetCode &&
      <Script id="my-script" dangerouslySetInnerHTML={{ __html: renderSnippet() }} />
  )
}

export default Salesiq