export const tableHeader = ['List of NFTs', 'Price', 'Transaction Hash']

export const tempData = [
  {
    "id": "8f4e7feb-70da-4faa-bc31-0c6ef26b9e59",
    "walletAddress": "0x92D981C51c574E300782cB80767eE9aBa75178ec",
    "price": 8312.050000000001,
    "tokenPrice": 5,
    "nftThumbnail": "https://jungle-development-bucket.s3.amazonaws.com/profile/1659682967282-pexels-johannes-plenio-10920905.jpg",
    "nftName": "August5thNft1",
    "tokenSymbol": "https://jungle-development-bucket.s3.amazonaws.com/profile/1657869811277-accae6b6fb3888cbff27a013729c22dc%281%29.png",
    "transactionHash": "0x5038f18210531b850e41fe7a0b8e2a0cff67e1d690666528b2614d3d0c96d7fc"
  },
  {
    "id": "8f4e7feb-70da-4faa-bc31-0c6efb9e59",
    "walletAddress": "0x92D981C51c574E300782cB80767eE9aBa75178ec",
    "price": 8312.050000000001,
    "tokenPrice": 5,
    "nftThumbnail": "https://jungle-development-bucket.s3.amazonaws.com/profile/1659682967282-pexels-johannes-plenio-10920905.jpg",
    "nftName": "August5thNft1",
    "tokenSymbol": "https://jungle-development-bucket.s3.amazonaws.com/profile/1657869811277-accae6b6fb3888cbff27a013729c22dc%281%29.png",
    "transactionHash": "0x5038f18210531b850e41fe7a0b8e2a0cff67e1d690666528b2614d3d0c96d7fc"
  },
  {
    "id": "8f4e7feb-70da-4faa-bc31-0c6ef6b9e59",
    "walletAddress": "0x92D981C51c574E300782cB80767eE9aBa75178ec",
    "price": 8312.050000000001,
    "tokenPrice": 5,
    "nftThumbnail": "https://jungle-development-bucket.s3.amazonaws.com/profile/1659682967282-pexels-johannes-plenio-10920905.jpg",
    "nftName": "August5thNft1",
    "tokenSymbol": "https://jungle-development-bucket.s3.amazonaws.com/profile/1657869811277-accae6b6fb3888cbff27a013729c22dc%281%29.png",
    "transactionHash": "0x5038f18210531b850e41fe7a0b8e2a0cff67e1d690666528b2614d3d0c96d7fc"
  },
  {
    "id": "8f4e7feb-70da-4faa-bc31-0c6ef6b9e59",
    "walletAddress": "0x92D981C51c574E300782cB80767eE9aBa75178ec",
    "price": 8312.050000000001,
    "tokenPrice": 5,
    "nftThumbnail": "https://jungle-development-bucket.s3.amazonaws.com/profile/1659682967282-pexels-johannes-plenio-10920905.jpg",
    "nftName": "August5thNft1",
    "tokenSymbol": "https://jungle-development-bucket.s3.amazonaws.com/profile/1657869811277-accae6b6fb3888cbff27a013729c22dc%281%29.png",
    "transactionHash": "0x5038f18210531b850e41fe7a0b8e2a0cff67e1d690666528b2614d3d0c96d7fc"
  },
  {
    "id": "8f4e7feb-70da-4faa-bc31-0c6ef6b9e59",
    "walletAddress": "0x92D981C51c574E300782cB80767eE9aBa75178ec",
    "price": 8312.050000000001,
    "tokenPrice": 5,
    "nftThumbnail": "https://jungle-development-bucket.s3.amazonaws.com/profile/1659682967282-pexels-johannes-plenio-10920905.jpg",
    "nftName": "August5thNft1",
    "tokenSymbol": "https://jungle-development-bucket.s3.amazonaws.com/profile/1657869811277-accae6b6fb3888cbff27a013729c22dc%281%29.png",
    "transactionHash": "0x5038f18210531b850e41fe7a0b8e2a0cff67e1d690666528b2614d3d0c96d7fc"
  },
]