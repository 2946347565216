import styled from 'styled-components'
import { themeDark } from 'styles/theme'
import { media } from 'utilities/helpers'
export const StatusNotificationLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeDark.brandColor};
  position: fixed;
  z-index: 999;
  width: 100%;
  padding: 5px 10px;
`
export const StatusNotificationContainer = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.text};
  ${media.sm`
  font-size: 14px;
  `}
`
export const StatusNotificationCloseLogo = styled.span`
  cursor: pointer;
  ${media.sm`
  width: 10%;
  `}
  ${media.s`
  width: 20%;
  `}
`
