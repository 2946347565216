import { useQuery } from 'react-query'
import { CacheKeys } from './cacheKeys'
import { axiosInstance } from 'utilities/axiosInterceptor'
import { apiEndPoints } from 'utilities/apiUrls'

const getTermCondition = () => {
  const url = `${apiEndPoints.helpCenter.getTermCondition}`
  return axiosInstance.get(url)
}

export const useTermConditonQuery = () => {
  return useQuery([CacheKeys.termCondition], () => getTermCondition())
}

const getAboutUs = () => {
  const url = `${apiEndPoints.helpCenter.getAboutUs}`
  return axiosInstance.get(url)
}

export const useAboutUsQuery = () => {
  return useQuery([CacheKeys.aboutUs], () => getAboutUs())
}

const getFrequentQuestions = () => {
  const url = `${apiEndPoints.helpCenter.getFrequentQuestions}`
  return axiosInstance.get(url)
}

export const useFrequentQuestionsQuery = () => {
  return useQuery([CacheKeys.frequentQuestion], () => getFrequentQuestions())
}

const getPrivacy = () => {
  const url = `${apiEndPoints.helpCenter.getPrivacy}`
  return axiosInstance.get(url)
}

export const usePrivacyQuery = () => {
  return useQuery([CacheKeys.privacy], () => getPrivacy())
}
