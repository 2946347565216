import styled from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'
import { media } from 'utilities/helpers'
export const SuspendedModalLayout = styled.div<any>`
  width: 550px;
  border-radius: 10px;
  background-color: ${({ themeMode }) => (themeMode === 'light' ? themeDark.white : themeDark.bgColor )};
  position: relative;
  padding: 10px 0;
  ${media.sm`
  width: 450px;
  `}
  ${media.s`
  width: 300px;
  padding: 6px 0;
  `}
`
export const ModalBody = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000 !important;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 0 10px;
`
export const SuspendedModalHeader = styled.div<any>`
  padding-bottom: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: ${({ themeMode }) =>
    themeMode === 'light' ? `1px solid ${themeLight.borderColor}` : `1px solid ${themeDark.borderColor}`};
  ${media.sm`
  font-size: 16px;
  `}
`
export const SuspendedModalClose = styled.button`
  position: absolute;
  top: 22px;
  right: 20px;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  ${media.s`
  top: 17px;
  `}
`
export const SuspendedTextContent = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.text};
  text-align: center;
  margin: 30px 0px;
  ${media.sm`
  margin: 20px 0px;
  font-size: 16px;
  `}
`
export const LottieWrapper = styled.div`
  margin: 30px 0px;
  ${media.sm`
  margin: 20px 0px;
  `}
`
