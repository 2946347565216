export enum CacheKeys {
  categories = 'categories',
  notableDrops = 'notableDrops',
  topTrending = 'topTrending',
  topCollections = 'topCollections',
  notificationSettings = 'notificationSettings',
  user = 'user',
  chains = 'chains',
  tokens = 'tokens',
  getAllActiveTokens = 'getAllActiveTokens',
  collections = 'collections',
  assets = 'assets',
  offers = 'offers',
  nftActivityDetails = 'nftActivityDetails',
  allNftsExceptOne = 'allNftsExceptOne',
  userCreatedNfts = 'userCreatedNfts',
  allNFT = 'allNFT',
  userFavoriteNFts = 'userFavoriteNfts',
  getAllOffers = 'getAllOffers',
  userActivities = 'userActivities',
  collectionActivities = 'collectionActivities',
  getWatchCollections = 'getWatchCollections',
  auctions = 'auctions',
  cancelAuction = 'cancelAuction',
  favourites = 'favourites',
  activities = 'activities',
  coingecko = 'coingecko',
  userHiddenNFts = 'userHiddenNFts',
  activeAuctions = 'activeAuctions',
  allActivities = 'allActivities',
  nftItemCount = 'nftItemCount',
  trendingCollection = 'trendingCollection',
  topCollection = 'topCollection',
  fetchRankings = 'fetchRankings',
  userTokens = 'userTokens',
  priceHistory = 'priceHistory',
  priceHistoryCollection = 'priceHistoryCollection',
  refreshMetaData = 'refreshMetaData',
  platformFee = 'platformFee',
  termCondition = 'termCondition',
  aboutUs = 'aboutUs',
  frequentQuestion = 'frequentQuestion',
  privacy = 'privacy',
  collectionList = 'collectionList',
  userCollections = 'userCollections',
  verifyEmail = 'verifyEmail',
  resendEmail = 'resendEmail',
  getKycStatus = 'getKycStatus',
  kycUpdateSession = 'kycUpdateSession',
  unlockable = 'unlockable',
  fetchFromCollectionForUser = 'fetchFromCollectionForUser',
  getCreatorEarnings = 'getCreatorEarnings',
  platformStatus = 'platformStatus',
  validUser = 'validUser',
  auctionsWon = 'auctionsWon',
  isBlocked = 'isBlocked',
  creatorEarningStats = 'creatorEarningStats',
  cashbackForUser = 'cashbackForUser',
  cashbackAmountForUser = 'cashbackAmountForUser',
  getUserWalletBalance = 'getUserWalletBalance',
  categoriesDetails = 'categoriesDetails',
  syncProfile = 'syncProfile',
  csrfToken = 'csrfToken',
  allNFTs = 'allNFTs',
}
