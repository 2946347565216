import styled from 'styled-components'
import { rotate } from 'styles/animations'
import { customColors } from 'styles/theme'
import { media } from 'utilities/helpers'
export const SignUpFooterLayout = styled.div`
  background-color: ${({ theme }) => theme.phaseTwoColor.brandColor};
`
export const SignUpFooterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  align-content: center;
  min-height: 378px;
  ${media.lg`
  grid-template-columns: 1fr;
  `}
`
export const SignUpFooterLeftHeader = styled.div`
  font-family: 'Stray900';
  font-size: 50px;
  color: ${customColors.white};
  ${media.sm`
  font-size:35px;
  margin-top:10px;
  `}
`
export const SignUpFooterLeftParagraph = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: ${customColors.white};
  margin: 15px 0px;
`
export const InputFieldButtonWrapper = styled.div``

export const SignUpInputField = styled.input`
  width: 45%;
  height: 41px;
  outline: none;
  border: none;
  background-color: ${customColors.white};
  border-radius: 50px;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 25px;
  ::placeholder {
    color: ${({ theme }) => theme.phaseTwoColor.paragraphText};
  }
  ${media.sm`
  width: 57%;
  padding: 10px 15px;
  `}
`
export const SignUpButton = styled.button`
  width: 119px;
  height: 42px;
  background-color: ${customColors.black};
  color: ${customColors.white};
  border-radius: 50px;
  margin: 0px 10px;
  border: none;
  outline: none;
  cursor: pointer;
  ${media.sm`
  margin: 0px 5px;
  `}
`
export const SignUpFooterRightHeader = styled.div`
  font-size: 23px;
  font-weight: 700;
  color: ${customColors.white};
  ${media.sm`
  font-size: 20px;
  `}
`
export const SignUpFooterFAQWrapper = styled.div`
  border-bottom: 1px solid ${customColors.white};
  margin: 5px 0px;
`

export const SignUpFooterFAQContainer = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SignUpFooterFAQQuestion = styled.div`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
  color: ${customColors.white};
`
export const SignUpFooterFAQArrowIcon = styled.div`
  margin-right: 15px;
  cursor: pointer;
`
export const ErrorText = styled.p`
  color: red !important;
  font-weight: 500;
  font-size: 14px;
  margin: 5px 10px !important;
`
export const StyledSpinner = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
`
