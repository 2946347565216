import React, { Component, ErrorInfo, ReactNode } from 'react'
import { ErrorBoundaryContainer } from './ErrorBoundary.style'
import ErrorCard from './ErrorCard'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: any) {
    super(props)

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI
    return { hasError: true }
  }
  componentDidCatch(error: any, errorInfo: any) {
    // You can use your own error logging service here
    console.log('Error', { error, errorInfo })
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorBoundaryContainer>
          {/* <h2>Oops, there is an error!</h2>
          <button type="button" onClick={() => this.setState({ hasError: false })}>
          Try again?
        </button> */}
          <ErrorCard />
        </ErrorBoundaryContainer>
      )
    }

    // Return children components in case of no error

    return this.props.children
  }
}

export default ErrorBoundary
