import { StyledSpinner } from './Spinner.style'
import { ImSpinner2 } from 'react-icons/im'
import { useAppSelector } from 'hooks/useAppSelector'
import { CSSProperties } from 'react'
import { themeDark, themeLight } from 'styles/theme'
interface I_Spinner {
  fontSize?: string
  className?: string
  style?: CSSProperties
}
export const Spinner = ({ fontSize, className, style }: I_Spinner) => {
  const { theme } = useAppSelector(state => state.theme)
  return (
    <StyledSpinner className={className} style={style}>
      <ImSpinner2
        fontSize={fontSize}
        color={theme === 'dark' ? themeDark.white : themeLight.text}
      />
    </StyledSpinner>
  )
}
