import { useEffect, useState } from "react"
import Link from "next/link"
import TagManager from "react-gtm-module"

import { useAcknowledgeAuctionWon, useGetAuctionWinner } from "logic/reactQuery/auctionService"
import { PrimaryButton } from "styles/defaultStyles"
import { copyToClipboard, handlePrecision, shortenAddress } from "utilities/helpers"
import CustomModal from "../customModal/CustomModal"
import NextImage from "../nextImage/NextImage"
import TippyToolTip from "../tippyTooltip/TippyToolTip"
import {
  Container,
  Description,
  Content,
  InfoHeader,
  Info,
  NftDiv,
  PriceDiv,
  TransactionDiv,
  InfoHeaderText,
  InfoContainer,
  PrimaryButtonDiv
} from './AuctionWinnerModal.style'
import { tableHeader, tempData } from './data'
import { useWeb3React } from "@web3-react/core"
import { useAppSelector } from "hooks/useAppSelector"

interface I_ModalInfo {
  id: string,
  walletAddress?: string,
  price?: number,
  tokenPrice?: number,
  nftThumbnail?: string
  nftName?: string
  tokenSymbol?: string
  transactionHash?: string
  tokenImageUrl?: string
  nftItemId?: string
}

const AuctionWinnerModal = () => {

  const { account } = useWeb3React()
  const { isLoggedIn } = useAppSelector(state => state.user)
  const checkUserStatus = (account && isLoggedIn) ? true : false
  const { data, isLoading, isFetching } = useGetAuctionWinner(isLoggedIn);
  const { mutate, isLoading: isUpdatingWonAuction } = useAcknowledgeAuctionWon()

  const [showAuctionModal, setShowAuctionModal] = useState<boolean>(false)
  const [ids, setIds] = useState<Array<string>>([])
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [usdTotalPrice, setUsdTotalPrice] = useState<number>(0)
  const [tokenSymbol, setTokenSymbol] = useState<any>('')

  const tagManagerArgs = {
    gtmId: 'GTM-PV2KTB8',
    dataLayer: {
      event: 'nftAmount',
      amount: `${totalPrice}`,
      type: 'Auction Winner',
      token: `${tokenSymbol}`,
      usd: `${usdTotalPrice}`,
    },
  }


  if (typeof window !== 'undefined') {
    TagManager.initialize(tagManagerArgs)
    TagManager.dataLayer(tagManagerArgs)
  }

  function myFunction() {
    document.getElementById('myCheck')?.click()
  }

  const toggleModal = () => {
    try {
      setShowAuctionModal((prev: boolean) => !prev)
      mutate({ id: ids })
      // myFunction()
    } catch (e) { }
  }

  useEffect(() => {
    setIds([])
    setTotalPrice(0)
    setUsdTotalPrice(0)
    setTokenSymbol('')
  }, [])

  useEffect(() => {
    if (!isLoading && !isFetching && data?.message?.length > 0) {
      setShowAuctionModal(true)
      const temp: Array<string> = []
      let tempTotalPrice = 0
      let tempUsdTotalPrice = 0
      let tempTokenSymbol: any
      data?.message?.map((item: I_ModalInfo, index: number) => {
        temp.push(item?.id)
        // setTotalPrice((prev) => Number(prev) + Number(item?.tokenPrice))
        // setUsdTotalPrice((prev) => Number(prev) + Number(item?.price))
        // setTokenSymbol(item?.tokenSymbol)
        tempTotalPrice += Number(item?.tokenPrice)
        tempUsdTotalPrice += Number(item?.price)
        tempTokenSymbol = item?.tokenSymbol
      })
      setIds([...temp])
      setTotalPrice(tempTotalPrice)
      setUsdTotalPrice(tempUsdTotalPrice)
      setTokenSymbol(tempTokenSymbol)
    }
  }, [isLoading, isFetching, data])

  useEffect(() => {
    if (data && tokenSymbol !== '') {
      myFunction()
    }
  }, [tokenSymbol])

  return (
    (showAuctionModal && data?.message?.length > 0 && checkUserStatus) ?
      <>
        <CustomModal
          show={showAuctionModal}
          toggleModal={toggleModal}
          headerText={'You are the winner!'}
          headerTextFontSize="30px"
        >
          {/* class name to target google ads */}
          <button id="myCheck" className="buyNFT" hidden>
            {' '}
            Google Analytics (Hidden Button){' '}
          </button>
          <Container>
            <Description>Congratulations! you are the winner of the auctions item:</Description>
            <Content>
              <InfoHeader>
                {
                  tableHeader.map((item: string, index: number) => (
                    <InfoHeaderText key={index}>{item}</InfoHeaderText>
                  ))
                }
              </InfoHeader>
              <InfoContainer>
                {
                  data?.message?.map((item: I_ModalInfo, index: number) => (
                    <Info key={index}>
                      <div>
                        <NftDiv onClick={toggleModal}>
                          <NextImage
                            src={item?.nftThumbnail}
                            alt={item?.nftName}
                            width={40}
                            height={40}
                          />
                          <Link
                            href={item?.nftItemId ? `/assets/${item?.nftItemId}` : `/assets`}
                          >
                            <div>&nbsp;&nbsp;{item?.nftName}</div>
                          </Link>
                        </NftDiv>
                      </div>
                      <PriceDiv>
                        <NextImage
                          // src={item?.tokenSymbol}
                          src={item?.tokenImageUrl}
                          alt={''}
                          width={21}
                          height={21}
                        />
                        <div>&nbsp;&nbsp;{item?.tokenPrice}</div>
                      </PriceDiv>
                      <TransactionDiv>
                        {/* <TippyToolTip placement="top" toolTipContent={<div>{item?.transactionHash}</div>}>
                          <span> {handlePrecision(item?.transactionHash, 15)}</span>
                        </TippyToolTip> */}
                        <span
                          onClick={() => copyToClipboard(String(item?.transactionHash))}
                        >{shortenAddress(item?.transactionHash)}</span>
                      </TransactionDiv>
                    </Info>
                  ))
                }
              </InfoContainer>
              <PrimaryButtonDiv>
                <PrimaryButton
                  onClick={toggleModal}
                >
                  <Link href={`/profile`}>
                    View Item
                  </Link>
                </PrimaryButton>
              </PrimaryButtonDiv>
            </Content>
          </Container>
        </CustomModal>
      </> : <span></span>
  )
}

export default AuctionWinnerModal