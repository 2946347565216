const config = {
  algolia: {
    appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
    apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
    accountsIndex: process.env.NEXT_PUBLIC_ALGOLIA_ACCOUNTS_INDEX,
    collectionsIndex: process.env.NEXT_PUBLIC_ALGOLIA_COLLECTIONS_INDEX,
    nftIndex: process.env.NEXT_PUBLIC_ALGOLIA_NFT_INDEX
  },
}

export default config
