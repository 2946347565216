import styled from 'styled-components'
import { customColors, themeDark, themeLight } from 'styles/theme'

export const Container = styled.div<any>`
  padding: 32px 48px;
  ${({ border, themeMode }) =>
    border && `border-bottom: 1px solid ${themeMode === 'light' ? themeLight.border : customColors.bgDark}`};
  margin-bottom: 36px;
  @media (max-width: 600px) {
    padding: 32px 18px;
  }
  .clock {
    // margin: 10px 0px;
    background: ${({ themeMode }) =>
      themeMode === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg};
    border-radius: 10px;
    border: 1px solid
      ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.border)};
    padding: 15px;
    display: flex;
    align-items: center;
    .input-field {
      margin-left: 20px;
      font-weight: 600;
      font-size: 14px;
      border: none !important;
      outline: none !important;
      background: transparent;
      color: ${themeDark.white};
      :focus{
      outline: none !important;
      }
    }
    .rmdp-container{
      width: 100% !important;
    }
  }
  .singledate {
    width: 100%;
    .rmdp-day-picker {
      display: block !important;
    }
    .rmdp-day:not(.rmdp-day-hidden) span:hover {
      background-color: ${themeLight.brandColor} !important;
      color: white;
    }
    .rmdp-day > span {
      font-size: 10px;
    }
    .rmdp-day.rmdp-today span {
      background-color: white !important;
      color: black;
    }
    .green .rmdp-day.rmdp-selected span:not(.highlight) {
      color: white;
    }
  }

  .headerCSS {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    /* text-transform: uppercase; */
    margin-bottom: 12px;
    color: ${({ themeMode }) => (themeMode === 'light' ? customColors.greyText : themeDark.white)};
  }
`

export const Label = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding: 20px 0px 5px 0px;
  span {
    color: ${customColors.redText};
  }
`

export const Text = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: ${themeDark.textTertiary};
  span {
    font-size: 12px;
    line-height: 18px;
    color: #ba9202;
    background: #fdf5db;
    padding: 6px 10px;
    border-radius: 100px;
  }
`

export const Input = styled.input<any>`
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg};
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  border: ${({ themeMode }) => (themeMode === 'light' ? `1px solid ${themeLight.border}` : 'none')};
  outline: none;
  color: ${({ themeMode }) => (themeMode === 'light' ? 'black' : themeDark.text)};
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  // margin: 10px 0;
  ::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
  overflow-wrap: break-word;
  word-break: break-all;
`

export const DocumentContainer = styled.div<any>`
  display: grid;
  grid-template-columns: 5% 80% 5%;
  justify-content: center;
  align-items: center;
  height: 101px;
  background: ${({ themeMode, active }) =>
    active
      ? themeMode === 'light'
        ? '#EEFFFA'
        : '#212129'
      : themeMode === 'light'
      ? themeLight.customColors?.background
      :themeDark.bgTertiary };
  border: ${props => (props.active ? `1px solid ${themeDark.brandColor}`  : '1px solid #70768A')};
  border-radius: 10px;
  margin-bottom: 18px;
  gap: 4px;
  cursor: pointer;
`

export const FlexBox = styled.div<any>`
  display: flex;
  flex-direction: ${({ fd }) => fd || 'row'};
  align-items: ${({ align }) => align || 'center'};
  justify-content: ${({ justify }) => justify || 'center'};
  width: ${({ width }) => width || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  gap: 36px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const SuccessMessage = styled.p<any>`
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  color: ${({ themeMode }) => (themeMode === 'light' ? customColors.greyText : themeDark.white)};
  margin-top: 2rem;
`

export const SuccessText = styled.p<any>`
  margin-top: 28px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: ${({ themeMode }) => (themeMode === 'light' ? customColors.greyText : themeDark.white)};
`
