import styled from 'styled-components'
import { customColors, themeDark, themeLight } from 'styles/theme'

export const Wrapper = styled.div``

export const TabsWrapper = styled.div`
  display: flex;
`

export const TabButton = styled.button<{ active?: boolean }>`
  outline: none;
  border: none;
  background: transparent;
  flex: 1;
  cursor: pointer;
  padding: 8px 0;
  color: ${({ theme }) => theme.text};
  font-size: 18px;
  border-bottom: ${({ theme, active }) => (active ? `2px solid ${theme.brandColor}` : `1px solid ${themeLight.border}`)};
`
export const PaymentAddress = styled.div<any>`
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg};
  border-radius: 10px;
  border: ${({ themeMode }) => (themeMode === 'light' ? `1px solid ${customColors.textSecondary}` : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  padding: 15px;
  margin: 20px 0;
  img {
    cursor: pointer;
  }
`
export const PaymentAddressText = styled.p<any>`
  border-right: 2px solid grey;
  padding-right: 10px;
`

export const PaymentDescriptionTwo = styled.p<any>`
  margin-bottom: 16px;
`

export const PayMentBodyRight = styled.div<any>`
  height: 100%;
`
export const PaymentBodyLeft = styled.div<any>`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
`
export const PaymentWalletIcon = styled.div<any>`
  margin: 15px;
`

export const PaymentDescriptionOne = styled.p<any>`
  font-weight: 600;
  font-size: 16px;
`
