import { useAppSelector } from 'hooks/useAppSelector'
import React, { useState } from 'react'
import Lottie from 'react-lottie'
import CustomModal from 'shared/components/customModal/CustomModal'
import { Container, FlexBox, SuccessMessage, SuccessText } from './PersonalInfo.style'
import alertLight from '../../../public/assets/json/alertLight.json'
import alertDark from '../../../public/assets/json/alertDark.json'
import { PrimaryButton, SecondaryButton } from 'styles/defaultStyles'

interface I_Props {
  show: boolean
  toggle: any
  closePersonalInfoModal?: any
  closeIdentityModal?: any
  closeUploadDocsModal?: any
  setFirstName?: any
  setLastName?: any
  setGenderValue?: any
  setDateSelected?: any
}
const AlertModal = (props: I_Props) => {
  const { theme } = useAppSelector(state => state.theme)
  const {
    show,
    toggle,
    closePersonalInfoModal,
    closeIdentityModal,
    closeUploadDocsModal,
    setFirstName,
    setLastName,
    setGenderValue,
    setDateSelected,
  } = props
  const [alertModal, setAlertModal] = useState<boolean>(false)

  const resetValues = () => {
    closePersonalInfoModal && closePersonalInfoModal()
    closeIdentityModal && closeIdentityModal()
    closeUploadDocsModal && closeUploadDocsModal()

    toggle()
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: theme === 'light' ? alertLight : alertDark,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <>
      {show && (
        <CustomModal show={show} toggleModal={toggle} headerText="Identity Verification">
          <>
            <Container themeMode={theme} border={true}>
              <Lottie options={defaultOptions} width={300} height={280} />
              <SuccessText themeMode={theme} >
                You’re about to exit the Personal Verification process. Are you sure you want to
                exit?
              </SuccessText>
            </Container>
            <FlexBox justify="space-around" margin="0 0 24px 0">
              <SecondaryButton
                onClick={() => {
                  toggle()
                }}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton onClick={resetValues}>Confirm</PrimaryButton>
            </FlexBox>
          </>
        </CustomModal>
      )}
    </>
  )
}

export default AlertModal
