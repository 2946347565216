import styled from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'
import { media } from 'utilities/helpers'

export const StyledWrapper = styled.div`
  width: 100%;
  position: relative;
  .flex {
    display: flex;
  }

  .items-center {
    align-items: center;
  }
`

export const StyledForm = styled.form`
  width: 100%;
  border-radius: 20px;
  position: relative;
`

export const StyledInput = styled.input<any>`
  width: 100%;
  min-width: 350px;
  border: 1px solid transparent;
  min-height: 50px;
  background: ${({ themeMode }) => (themeMode === 'light' ? 'hsla(0, 0%, 76%,0.15)' : '#262626')};
  border-radius: inherit;
  outline: none;
  border: none;
  padding-left: 24px;
  padding-right: 44px;

  color: ${({ themeMode }) => (themeMode === 'light' ? themeDark.textTertiary : themeDark.white)};
  &::placeholder {
    color: ${({ themeMode }) =>
      themeMode === 'light' ? themeDark.textTertiary : themeDark.textSecondary};
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
  transition: all linear 0.2s;
  :hover {
    border: 1px solid
      ${({ themeMode }) =>
        themeMode === 'light' ? themeDark.textSecondary : themeDark.textTertiary};
  }
  ${media.md`
    /* display:none; */
    min-width: 50px;
    &::placeholder {
      font-size: 12px;
    }
  `}
`

export const StyledBtn = styled.button`
  cursor: pointer;
  width: 18px;
  height: 18px;
  position: absolute;
  background: transparent;
  border: none;
  outline: none;
  right: 16px;
  top: calc(50% - 9px);
  ${media.md`
   display:none;
  `}
`

export const StyledPanel = styled.div<any>`
  width: 100%;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
  border-radius: 10px;
  margin: 8px 0 0;
  overflow: hidden;
  position: absolute;
  z-index: 3;
  border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.border)};
  max-height: 85vh;
  overflow-y: auto;
`

export const StyledSource = styled.div``

export const StyledList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const StyledItem = styled.li<any>`
  border-bottom: 1px solid
    ${({ themeMode }) =>
      themeMode === 'light' ? themeLight.border : themeDark.assetDetail.backArrowBg};
  padding: 8px;
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
  }
`

export const StyledTitle = styled.h5`
  color: ${themeDark.textTertiary};
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
`

export const StyledImgWrapper = styled.div`
  border: 1px solid ${themeDark.textTertiary};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  overflow: hidden;
`

export const StyledName = styled.span`
  margin-left: 8px;
  color: ${({ theme }) => theme.text};
  font-size: 15px;
  font-weight: 600;
`

export const StyledCount = styled.span`
  margin-left: 8px;
  color: ${themeDark.textTertiary};
  font-size: 13px;
  font-weight: 400;
`
