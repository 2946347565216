// import { useWeb3React } from '@web3-react/core'
// import { useGetUserDetailsQuery } from 'logic/reactQuery/userService'

// export const useAuth = () => {
//   const { account } = useWeb3React()
//   const { data, isLoading, error } = useGetUserDetailsQuery(account)
//   const user = data?.message
//   const isLoggedIn = !!user
//   return {
//     isLoading,
//     isLoggedIn,
//     user,
//     error,
//   }
// }

import { useWeb3React } from '@web3-react/core'
import { useGetUserDetailsQuery } from 'logic/reactQuery/userService'

export const useAuth = () => {
  const { account } = useWeb3React()

  const { data, isLoading, error } = useGetUserDetailsQuery(account)

  const user = data?.message

  return {
    isLoading,
    isWalletConnected: !!account,
    walletAddress: account,
    isLoggedIn: !!user,
    user,
    error,
  }
}
