import Select, { components } from 'react-select'
import { BsFillCaretDownFill } from 'react-icons/bs'
import NextImage from 'shared/components/nextImage/NextImage'
import { IconOptionWrapper, IconSingleValueWrapper } from './SelectFieldStyle'

interface I_SelectFieldProps {
  handleSelectChange: any
  options: {}[] | any
  isContainsImages?: boolean
  selectedValue: any
  disabled?: boolean
  customStyles: any
  dropdownIndicator?: boolean
  isSearchable?: boolean
  imgHeight?: number
  imgWidth?: number
  isMulti?: boolean
  placeholder?: string
  isFilterValue?: boolean
}

const SelectField = ({
  handleSelectChange,
  options,
  selectedValue,
  disabled,
  isContainsImages,
  customStyles,
  dropdownIndicator,
  isSearchable,
  imgHeight,
  imgWidth,
  isMulti,
  placeholder,
  isFilterValue
}: I_SelectFieldProps) => {
  const { SingleValue, Option } = components
  const IconSingleValue = (props: any) => (
    <SingleValue {...props}>
      {isContainsImages && (
        <IconSingleValueWrapper>
          <NextImage
            src={props.data.image}
            height={imgHeight ? imgHeight : 25}
            width={imgWidth ? imgWidth : 25}
            alt=''
          />
        </IconSingleValueWrapper>
      )}
      {props.data.label}
    </SingleValue>
  )

  const IconOption = (props: any) => (
    <Option {...props}>
      {isContainsImages && (
        <IconOptionWrapper>
          <NextImage
            src={props.data.image}
            height={imgHeight ? imgHeight : 25}
            width={imgWidth ? imgWidth : 25}
            alt=''
          />
        </IconOptionWrapper>
      )}
      {props.data.label}
    </Option>
  )
  const CaretDownIcon = () => {
    return <BsFillCaretDownFill />
  }

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {dropdownIndicator ? <CaretDownIcon /> : null}
      </components.DropdownIndicator>
    )
  }

  return (
    <Select
      styles={customStyles}
      components={{ SingleValue: IconSingleValue, Option: IconOption, DropdownIndicator }}
      // options={options}
      options={
        isFilterValue ? options.filter((item: any, index: number) => item.value !== selectedValue)
        : options
      }
      isSearchable={isSearchable ? isSearchable : false}
      onChange={handleSelectChange}
      value={
        isMulti
          ? options.filter((option: any) => selectedValue.includes(option?.value))
          : options?.filter((option: any) => {
            return option?.value === selectedValue
          })
      }
      isOptionDisabled={(option: any) => option.value === disabled}
      isMulti={isMulti ? isMulti : false}
      placeholder={placeholder ? placeholder : 'Sort by'}
    />
  )
}

export default SelectField
