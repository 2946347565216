export const merkleValidatorAddress_ETHEREUM: string = process.env.NEXT_PUBLIC_merkleValidatorAddress_ETHEREUM as string
export const WyvernAtomicizer_ETHEREUM: string = process.env.NEXT_PUBLIC_WyvernAtomicizer_ETHEREUM as string

export const merkleValidatorAddress_POLYGON: string = process.env.NEXT_PUBLIC_merkleValidatorAddress_POLYGON as string
export const WyvernAtomicizer_POLYGON: string = process.env.NEXT_PUBLIC_WyvernAtomicizer_POLYGON as string

export const ownerAddress: string = process.env.NEXT_PUBLIC_ownerAddress as string
export const zeroAddress: string = process.env.NEXT_PUBLIC_zeroAddress as string

export const paymentTokenApproveAddress =
  process.env.NEXT_PUBLIC_paymentTokenApproveAddress
export const extraMetaDataAddress =
  process.env.NEXT_PUBLIC_extraMetaDataAddress

export const orderType = [
  {
    name: 'exchange',
    type: 'address',
  },
  {
    name: 'maker',
    type: 'address',
  },
  {
    name: 'taker',
    type: 'address',
  },
  {
    name: 'makerRelayerFee',
    type: 'uint256',
  },
  {
    name: 'takerRelayerFee',
    type: 'uint256',
  },
  {
    name: 'takerCashbackFee',
    type: 'uint256',
  },
  {
    name: 'feeRecipient',
    type: 'address',
  },
  {
    name: 'side',
    type: 'uint8',
  },
  {
    name: 'saleKind',
    type: 'uint8',
  },
  {
    name: 'target',
    type: 'address',
  },
  {
    name: 'howToCall',
    type: 'uint8',
  },
  {
    name: 'data',
    type: 'bytes',
  },
  {
    name: 'replacementPattern',
    type: 'bytes',
  },
  {
    name: 'royaltyData',
    type: 'bytes',
  },
  {
    name: 'staticTarget',
    type: 'address',
  },
  {
    name: 'staticExtradata',
    type: 'bytes',
  },
  {
    name: 'paymentToken',
    type: 'address',
  },
  {
    name: 'basePrice',
    type: 'uint256',
  },
  {
    name: 'extra',
    type: 'uint256',
  },
  {
    name: 'listingTime',
    type: 'uint256',
  },
  {
    name: 'expirationTime',
    type: 'uint256',
  },
  {
    name: 'salt',
    type: 'uint256',
  },
  {
    name: 'nonce',
    type: 'uint256',
  },
]
export const domainType = [
  {
    name: 'name',
    type: 'string',
  },
  {
    name: 'version',
    type: 'string',
  },
  {
    name: 'chainId',
    type: 'uint256',
  },
  {
    name: 'verifyingContract',
    type: 'address',
  },
]
