import { ItemHeading } from './../modules/taxes/TaxesScreen'
import toast from 'react-hot-toast'
import { css } from 'styled-components'
import { ISetFiltersFromParams, IAddFiltersToParams } from 'shared/interface'
import { encodeCalldataAbi, encodeHighestBidderAbi } from 'logic/contracts/abi/listingAbi'
import BigNumber from 'bignumber.js'
import { log } from 'console'
import {
  facebook,
  instagram,
  linkedin,
  pinterest,
  telegram_bubble,
  telegram_logo_icon,
  twitter,
} from 'shared/icons'
import { useGetUserDetailsQuery } from 'logic/reactQuery/userService'
// Types
type I_screenSizes = {
  [key: string]: number
}

type I_mediaQueries = {
  [key: string]: string
}
type FixType = any
type ExtraArgs = string[]
type ArgType = [FixType, ...ExtraArgs]

export const shorten = (str: string) => {
  return `${str.toString().slice(0, 6)}...${str?.toString().slice(str.length - 4)}`
}

export const screenSizes: I_screenSizes = {
  xs: 0,
  s: 360,
  sx: 450,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xlr: 1460,
  xxl: 1800,
}

const mediaQueries: I_mediaQueries = {
  xs: `(max-width: ${screenSizes.xs}px)`,
  s: `(max-width: ${screenSizes.s}px)`,
  sx: `(max-width: ${screenSizes.sx}px)`,
  sm: `(max-width: ${screenSizes.sm}px)`,
  md: `(max-width: ${screenSizes.md}px)`,
  lg: `(max-width: ${screenSizes.lg}px)`,
  xl: `(max-width: ${screenSizes.xl}px)`,
  xlr: `(max-width: ${screenSizes.xlr}px)`,
  xxl: `(max-width: ${screenSizes.xxl}px)`,
}

export const media: FixType = Object.keys(mediaQueries).reduce((acc, segment) => {
  const styledMediaFunction = (...args: ArgType) => css`
    @media ${mediaQueries[segment]} {
      ${css(...args)};
    }
  `
  return {
    ...acc,
    [segment]: styledMediaFunction,
  }
}, {})

export const shortenAddress = (str: string | undefined) => {
  if (str) {
    return `${str.toString().slice(0, 6)}...${str?.toString().slice(str.length - 4)}`
  }
  return str
}

export const copyToClipboard = async (text: string) => {
  typeof window !== 'undefined' && (await window.navigator.clipboard.writeText(text))
  toast('Copied to Clipboard')
}

export const getMonthInWords = (monthNumber: number) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'November',
    'December',
  ]
  return months[monthNumber]
}

export const isValidImage = (file: File) => {
  // Modify the regex if need more types
  const regex = /^image\/(jpeg|png|gif|jpg)$/
  const fileNameExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg']

  const fileNameArray = file?.name?.split('.')
  const fileNameType = fileNameArray[fileNameArray.length - 1]

  if (file.type === 'image/svg+xml' && fileNameType === 'svg') {
    return true
  } else {
    return regex.test(file.type) && fileNameExtensions.includes(fileNameType)
  }
}

export const generateQueryString = (options?: { [key: string | number | symbol]: any }) => {
  if (!options) return ''
  let queryString = ''
  for (const key in options) {
    if (options[key] || options[key] === false) {
      queryString += `${key}=${options[key]}&`
    }
  }
  return queryString.slice(0, -1)
}

export const isValidUrl = (url: string) => {
  const regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  return regexp.test(url)
}

export const timeSince = (date: Date) => {
  let seconds = Math.floor((new Date().getTime() - new Date(date).getTime()) / 1000)
  let interval = seconds / 31536000
  if (interval > 1) {
    return Math.floor(interval) + ' Years ago'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + ' Months ago'
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + ' Days ago'
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + ' Hours ago'
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + ' Minutes ago'
  }
  return Math.floor(seconds) + ' Seconds ago'
}

export const debounce = (fn: any, delay: number) => {
  let timer: any

  return (...args: any) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}

export const titleCase = (str?: string | null) => {
  if (!str) return ''
  str = str.replace(/-/g, ' ')
  return str.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
export const getUrlExtension = (url: string) => {
  const res = url?.split(/[#?]/)[0].split('.')?.pop()?.trim()
  return res
}

export const setFiltersFromUrlParams = ({
  filters,
  setFilters,
  query,
  nftStatuses,
  chains,
  currencies,
  tokens,
  collections,
  collectionsMulti,
  eventTypes,
}: ISetFiltersFromParams) => {
  const newFilters = { ...filters }
  for (const key in query) {
    if (key === 'status') {
      newFilters.status = nftStatuses?.filter(status => query[key]?.includes(status.id)) || []
    }

    if (key === 'chains') {
      newFilters.chains = chains?.filter(chain => query[key]?.includes(chain.id)) || []
    }

    if (key === 'onSaleIn') {
      newFilters.onSaleIn = tokens?.filter(token => query[key]?.includes(token.id)) || []
    }

    if (key === 'currency') {
      const currency = currencies?.find(({ id }) => id === query[key])
      if (currency) newFilters.currency = currency
    }

    if (key === 'minPrice') {
      newFilters.minPrice = query[key] as string
    }

    if (key === 'maxPrice') {
      newFilters.maxPrice = query[key] as string
    }

    if (key === 'sortBy') {
      newFilters.sortBy = query[key] as string
    }

    if (key === 'itemType') {
      newFilters.itemType = query[key] as string
    }

    if (key === 'collections') {
      newFilters.collections =
        collections?.filter(collection => query[key]?.includes(collection.collection_id)) || []
    }

    if (key === 'collectionsMulti') {
      newFilters.collectionsMulti =
        collectionsMulti?.filter(collection => query[key]?.includes(collection.id)) || []
    }

    if (key === 'eventTypes') {
      newFilters.eventTypes =
        eventTypes?.filter((eventType: any) => query[key]?.includes(eventType.id)) || []
    }

    if (key === 'search') {
    }
  }
  setFilters(newFilters)
}

export const addFiltersToUrlParams = ({ filters, router }: IAddFiltersToParams) => {
  const newRouter = { ...router }
  for (const key in filters) {
    if (key === 'properties') {
      return
    }
    // @ts-ignore
    if (Array.isArray(filters[key])) {
      // @ts-ignore
      newRouter.query[key] = filters[key].map(({ id }) => id)
    }

    // @ts-ignore
    if ((filters[key] && typeof filters[key] === 'string') || typeof filters[key] === 'number') {
      // @ts-ignore
      newRouter.query[key] = filters[key]
    }

    // @ts-ignore
    if (filters[key] && typeof filters[key] === 'object' && !Array.isArray(filters[key])) {
      // @ts-ignore
      newRouter.query[key] = filters[key].id
    }

    if (
      // @ts-ignore
      filters[key] === null ||
      // @ts-ignore
      filters[key] === undefined ||
      // @ts-ignore
      filters[key] === '' ||
      // @ts-ignore
      filters[key]?.length === 0
    ) {
      delete newRouter.query[key]
    }
  }
  router.replace({ query: newRouter.query })
}

export const generateQueryStringInfiniteParams = (options?: {
  [key: string | number | symbol]: any
}) => {
  if (!options) return ''
  let queryString = ''
  for (const key in options) {
    if (options[key] && options[key].length > 0) {
      queryString += `${key}=${options[key]}&`
    }
  }
  return queryString.slice(0, -1)
}
export const truncateAddress = (address: string) => {
  if (!address) return 'No Account'
  const match = address.match(/^(0x[a-zA-Z0-9]{2})[a-zA-Z0-9]+([a-zA-Z0-9]{2})$/)
  if (!match) return address
  return `${match[1]}…${match[2]}`
}

export const toHex = (num: number | string) => {
  const val = Number(num)
  return '0x' + val?.toString(16)
}

export const cgTokenIds: { [key: string]: string } = {
  eth: 'ethereum',
  weth: 'weth',
  matic: 'matic-network',
  maticWeth: 'matic-aave-weth',
}

export const isOneOfOwners = (
  currentUser?: string | null,
  multipleOwners?: { owner: string }[],
) => {
  if (!multipleOwners || !currentUser) {
    return false
  } else if (Array.isArray(multipleOwners) && multipleOwners?.length > 0) {
    return multipleOwners?.some(owner => owner?.owner?.toLowerCase() === currentUser?.toLowerCase())
  } else {
    return false
  }
}

export const getPriceInUsd = (amount: string | number, tokenSymbol: string, coinGeckoData: any) => {
  // const key = cgTokenIds[tokenSymbol.toLowerCase()]
  const factor = coinGeckoData?.data[tokenSymbol]?.usd
  if (!factor || Number.isNaN(amount)) {
    return 0
  }
  const usdPrice = Number(amount) * factor
  return usdPrice
}

export const getTheBasePrice = (amount: number, decimals: number) => {
  if (amount !== null && decimals) {
    const bnAmount = new BigNumber(amount)
    const bnValue = new BigNumber(bnAmount?.toFixed(decimals))?.times(
      new BigNumber(10)?.pow(decimals),
    )
    const basePrice = bnValue?.toFixed()

    return basePrice
  } else {
    return 0
  }
}

// share on social media

export const shareOnFacebook = (url: string) => {
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`
  window.open(facebookUrl, '_blank')
}

export const shareOnTwitter = (url: string) => {
  const twitterUrl = `https://twitter.com/intent/tweet?text=${url}`
  window.open(twitterUrl, '_blank')
}

export const shareOnTelegram = (url: string) => {
  const telegramUrl = `https://t.me/share/url?url=${url}`
  window.open(telegramUrl, '_blank')
}

export const shareOnEmail = (url: string) => {
  const emailUrl = `mailto:?body=${url}`
  window.open(emailUrl, '_blank')
}

export const shareOnGmail = (url: string) => {
  const gmailUrl =
    'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Your+Subject+here&body=' +
    `${url}` +
    '&ui=2&tf=1&pli=1'
  window.open(gmailUrl, 'sharer', 'toolbar=0,status=0,width=648,height=395')
}

export const shareOnInstagram = (url: string) => {
  const instagramUrl = `https://www.instagram.com/p/{url}`
  window.open(instagramUrl, '_blank')
}

export const shareOnLinkedin = (url: string) => {
  const linkedinUrl = `https://www.linkedin.com/shareArticle?url=${url}`
  window.open(linkedinUrl, '_blank')
}

export const shareOnPinterest = (url: string, media: string) => {
  const pinterestUrl = `http://www.pinterest.com/pin/create/button/?url=${url}&media=${media}`
  window.open(pinterestUrl, '_blank')
}

// for sharing an ItemHeading

export const socialUrls = {
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  twitter: 'https://twitter.com/intent/tweet?text=',
  instagram: 'https://www.instagram.com/sharer.php?u=',
  linkedin: 'https://www.linkedin.com/cws/share?url=',
  pinterest: 'http://pinterest.com/pin/create/button/?url=',
  telegram: 'https://t.me/share/url?url={url}&text=',
}

export const shareOptions = [
  {
    text: 'Share on Facebook',
    img: facebook,
    socailLink: shareOnFacebook,
  },
  // {
  //   text: 'Share on Instagram',
  //   img: instagram,
  //   socailLink: shareOnInstagram,
  // },
  {
    text: 'Share on Twitter',
    img: twitter,
    socailLink: shareOnTwitter,
  },
  // {
  //   text: 'Share on Linkedin',
  //   img: linkedin,
  //   socailLink: shareOnLinkedin,
  // },
  {
    text: 'Share on Pinterest',
    img: pinterest,
    socailLink: shareOnPinterest,
  },
  {
    text: 'Share on Telegram',
    img: telegram_bubble,
    socailLink: shareOnTelegram,
  },
]

export const handlePrecision = (val: any, len: number) => {
  const testVal = String(val)
  if (testVal.length >= len) {
    return `${testVal.slice(0, len)}...`
  }
  return testVal
}

export const commaNumber = (val: string | number) => {
  if (val) {
    return Number(val).toLocaleString('en-US')
  }
  return val
}

export const checkMetamask = () => {
  if (typeof window !== 'undefined') {
    const { web3 } = window as any
    if (typeof web3 !== 'undefined') {
      return true
    } else {
      return false
    }
  }
}

export const capitalize = (data: string) => {
  if (data) {
    return data[0].toUpperCase() + data.slice(1)
  }
  return data
}

// to get the username of the user
export const getUserNameByWalletAddress = (walletAddress: any) => {
  const user: any = useGetUserDetailsQuery(walletAddress)
  return user?.data?.message?.userName?.length > 0
    ? user?.data?.message?.userName
    : shortenAddress(user?.data?.message?.walletAddress)
}

export const nftContentTypeChecking = (contentType: string) => {
  const type = contentType?.split('/')?.[1]
  if (
    // type === 'image' ||
    type === 'png' ||
    type === 'jpg' ||
    type === 'jpeg' ||
    type === 'svg' ||
    type === 'gif' ||
    type === 'svg+xml'
  ) {
    return 'image'
  } else if (type === 'mp4' || type === 'webm') {
    return 'video'
  } else if (type === 'mp3' || type === 'wav' || type === 'mpeg') {
    return 'audio'
  } else {
    return 'image'
  }
}
