import { useAppSelector } from 'hooks/useAppSelector'
import React, { useCallback, useEffect, useState } from 'react'
import CustomModal from 'shared/components/customModal/CustomModal'
import SelectField from 'shared/components/selectField/SelectField'
import { SelectFieldStyle } from 'shared/components/selectField/SelectFieldStyle'
import { FlexBox, PrimaryButton } from 'styles/defaultStyles'
import AlertModal from './AlertModal'
import IdentityVerification from './IdentityVerification'
import { Container, Input, Label } from './PersonalInfo.style'
import DatePicker, { DateObject } from 'react-multi-date-picker'
import { themeDark, themeLight } from 'styles/theme'
import { RiCalendarEventLine } from 'react-icons/ri'
import { date } from 'yup'
import qs from 'qs'
import toast from 'react-hot-toast'
import { useKycSubmitMutation } from 'logic/reactQuery/userService'
import SuccessModal from './FinalModal'
import { countries } from 'modules/kycProcessingDetails/countriesList'
import { useRouter } from 'next/router'
import { SquareCheckedBox } from 'styles/sharedStyles'
import { CheckBoxDiv, Link } from 'modules/nftDetail/components/saleAndOffer/SaleAndOffer.style'
import { useWeb3React } from '@web3-react/core'

interface personalProps {
  show: boolean
  toggle: any
}
const options = [
  {
    value: 'male',
    label: 'Male',
  },
  {
    value: 'female',
    label: 'Female',
  },
]

// let nationalityOptions: any = []

// for (var property in countries) {
//   nationalityOptions.push(
//     {
//       value: property,
//       label: property
//     }
//   )
// }

const PersonalInfo = (props: personalProps) => {
  const defaultDOB = new DateObject().subtract(18, 'years')
  const { theme } = useAppSelector(state => state.theme)
  const { show, toggle } = props
  const [genderValue, setGenderValue] = useState<string>('male')
  const [nationality, setNationality] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [dateSelected, setDateSelected] = useState<any>(defaultDOB)
  const [alertModal, setAlertModal] = useState<boolean>(false)
  const [success, setSuccess] = useState(false)
  const [modalStatus, setModalStatus] = useState<string>('pending')
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const { isAccountLoading } = useAppSelector(state => state.user)
  const { account } = useWeb3React()
  const { mutateAsync } = useKycSubmitMutation()

  const router = useRouter()

  const handleSelect = useCallback(
    (info: any) => {
      setGenderValue(info.value)
    },
    [setGenderValue],
  )

  const handleSelectNationality = useCallback(
    (info: any) => {
      setNationality(info.value)
    },
    [setNationality],
  )

  const [showStep2, setShowStep2] = useState(false)

  const { nftCreateStyles } = SelectFieldStyle()

  const resetValues = () => {
    setFirstName('')
    setLastName('')
    setGenderValue('')
    setNationality('')
    setAddress('')
    setDateSelected(defaultDOB)
    toggle()
    setModalStatus('pending')
    setTermsAndConditions(false)
  }
  const handleDate = (e: any) => {
    setDateSelected(e)
  }

  const KycDetails = async (
    firstName: string,
    lastName: string,
    DOB: string,
    gender: string,
    nationality: string,
    address: string,
  ) => {
    setSuccess(true)

    const comparisonData = {
      firstName: firstName,
      lastName: lastName,
      dob: dateSelected.toString().replaceAll('/', '-'),
      residentialAddress: address,
    }
    const newDetails = {
      requestTypes: {
        idFront: ['camera'],
        idBack: ['camera'],
        faceCompare: ['camera'],
      },
      processingFlow: 'idv',
      locale: 'en-US',
      country: nationality,
      successRedirectUrl: window.location.origin + '/settings?tab=kyc&&success=true',
      errorRedirectUrl: window.location.origin + '/settings?tab=kyc&&success=false',

      shortUrl: 'true',
      mobileSuccessRedirectUrl: window.location.origin + '/settings?tab=kyc&&success=true',
      mobileErrorRedirectUrl: window.location.origin + '/settings?tab=kyc&&success=false',
      mobileRedirectBehavior: 'always',
    }

    const data = qs.stringify({
      user_data: JSON.stringify(comparisonData),
      request_data: JSON.stringify(newDetails),
    })

    const response = await mutateAsync(data)

    if (response) {
      window.open(response.message.sessionUrl, '_self')
    } else {
      setModalStatus('failed')
      toast('Something went wrong. Please try again!')
      // setErrorModal(true)
    }
  }

  const kycDetailsVerify = () => {
    if (
      firstName.trim().length > 0 &&
      lastName.trim().length > 0 &&
      dateSelected &&
      nationality &&
      address.trim().length > 0
    ) {
      KycDetails(firstName, lastName, dateSelected, genderValue, nationality, address)
    }
  }
  useEffect(() => {
    if (router.isReady && router.query.success && (account || isAccountLoading)) {
      if (router.query.success === 'true') {
        setSuccess(true)
        setModalStatus('success')
      } else {
        setSuccess(true)
        setModalStatus('failed')
      }
    }
  }, [router.isReady])

  return (
    <>
      {show && (
        <CustomModal
          show={show}
          toggleModal={toggle}
          alertModal={alertModal}
          setAlertModal={setAlertModal}
          headerText="Personal Information"
        >
          <>
            <Container themeMode={theme} border={true}>
              <Label>
                First Name<span>*</span>
              </Label>
              <Input
                themeMode={theme}
                placeholder="Enter First Name"
                value={firstName}
                onChange={(evt: any) => {
                  const value = evt.target.value
                  const alphabetRegex = /^[a-zA-Z ]*$/
                  if (alphabetRegex.test(value)) {
                    setFirstName(value)
                  }
                }}
              ></Input>

              <Label>
                Last Name<span>*</span>
              </Label>
              <Input
                themeMode={theme}
                placeholder="Enter Last name"
                value={lastName}
                onChange={(evt: any) => {
                  const value = evt.target.value
                  const alphabetRegex = /^[a-zA-Z ]*$/
                  if (alphabetRegex.test(value)) {
                    setLastName(value)
                  }
                }}
              ></Input>
              <Label>
                Date of Birth<span>*</span>
              </Label>
              <div className="clock">
                <RiCalendarEventLine
                  size={20}
                  color={theme === 'light' ? themeLight.text : themeDark.white}
                  // style={{ margin: '0 10px 0 0' }}
                />
                <DatePicker
                  className={
                    theme === 'light' ? 'green bg-gray singledate' : 'green bg-dark singledate'
                  }
                  value={dateSelected}
                  onChange={(e: any) => handleDate(e)}
                  maxDate={defaultDOB}
                />
              </div>
              {/* <Label>
                Gender<span>*</span>
              </Label>
              <SelectField
                options={options}
                handleSelectChange={handleSelect}
                selectedValue={genderValue}
                dropdownIndicator={true}
                customStyles={nftCreateStyles}
                placeholder={'Select'}
              /> */}

              <Label>
                Nationality<span>*</span>
              </Label>
              <SelectField
                options={countries}
                handleSelectChange={handleSelectNationality}
                selectedValue={nationality}
                dropdownIndicator={true}
                customStyles={nftCreateStyles}
                placeholder={'Select'}
                isSearchable={true}
              />

              <Label>
                Residential Address<span>*</span>
              </Label>
              <Input
                themeMode={theme}
                placeholder="Enter Residential Address"
                value={address}
                onChange={(evt: any) => {
                  const value = evt.target.value
                  // const alphabetRegex = /^[a-zA-Z ]*$/;
                  // if (alphabetRegex.test(value)) {
                  setAddress(value)
                  // }
                }}
              ></Input>
              <CheckBoxDiv padding="30px 0px">
                <SquareCheckedBox style={{ display: 'inline-block' }} themeMode={theme}>
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={termsAndConditions}
                    onChange={() => setTermsAndConditions(!termsAndConditions)}
                  />
                  <label htmlFor="checkbox"></label>
                </SquareCheckedBox>
                &nbsp;
                <span>
                  I have read and agree to the{' '}
                  <Link href="/termsOfService" target={'_blank'} themeMode={theme}>
                    Terms of Service Agreement.
                  </Link>
                </span>
              </CheckBoxDiv>
            </Container>
            <FlexBox margin="0 0 40px 0">
              <PrimaryButton
                onClick={kycDetailsVerify}
                disabled={
                  !termsAndConditions ||
                  firstName.trim().length === 0 ||
                  lastName.trim().length === 0 ||
                  !dateSelected ||
                  !nationality ||
                  address.trim().length === 0 ||
                  !(defaultDOB > dateSelected)
                }
              >
                Continue
              </PrimaryButton>
            </FlexBox>
          </>
        </CustomModal>
      )}
      {/* <IdentityVerification show={showStep2} toggle={() => setShowStep2(false)}
        firstName={firstName} lastName={lastName} DOB={dateSelected.toString()} gender={genderValue}
        closePersonalInfoModal={resetValues}
      /> */}

      <AlertModal
        show={alertModal}
        toggle={() => {
          setAlertModal(false)
        }}
        closePersonalInfoModal={resetValues}
      />

      <SuccessModal
        show={success}
        toggle={() => setSuccess(false)}
        closeUploadDocsModal={resetValues}
        modalStatus={modalStatus}
      />
    </>
  )
}

export default PersonalInfo
