import styled, { css } from 'styled-components'
import { customColors, themeDark, themeLight } from 'styles/theme'
import { media } from '../../../../utilities/helpers'

const colors = {
  bgColor: '#A95D5D',
}
export const ContainerDiv = styled.div<any>`
  background: ${({ themeMode }) => (themeMode === 'light' ? themeLight.accordionBodyBg : 'none')};
  margin-bottom: 16px;
  border-radius: 10px;
  border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.border)};
  .flex {
    display: flex;
  }
  .justify-center {
    justify-content: left;
  }

  .py-16 {
    padding: 1rem;
  }
`

export const Title = styled.div`
  padding: 30px 18px 0px 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${themeDark.assetDetail.cardTitle};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Timer = styled.div<any>`
  padding: 0px 18px;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0px;
  padding-bottom: 30px;
  border-bottom: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.border)};
  .content {
    background: ${({ theme, themeMode }) =>
      themeMode === 'light' ? themeLight.assetDetail.background : theme.assetDetail.backArrowBg};
    padding: 22px 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid
      ${({ themeMode }) => (themeMode === 'light' ? themeLight.borderColor : themeDark.border)};
    ${media.xl`
      padding:15px;
      `}
  }
  .value {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: ${({ theme }) => theme.text};
    ${media.lg`
     font-size:18px;
    `}
    ${media.xl`
     font-size:20px;
    `}
  }
  .label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${themeDark.assetDetail.cardTitle};
    padding-left: 12px;
    ${media.xl`
     font-size:14px;
    `}
  }

  ${media.sm`
    flex-direction: column;
  `}
`

export const Footer = styled.div`
  padding: 0 18px 18px 18px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* justify-content: space-between; */
  /* align-items: center; */

  ${media.sm`
    flex-direction: column;
  `}
`
export const ContentLabel = styled.div<any>`
  display: flex;
  flex-direction: column;
  .label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${themeDark.assetDetail.cardTitle};
    margin-bottom: 12px;
  }
  .value {
    display: flex;
    align-items: center;
    flex-direction: row;
    ${media.sm`
    flex-direction:column;
    align-items:start;
    `}
    .token {
      font-weight: 700;
      font-size: 40px;
      line-height: 60px;
      color: ${({ theme }) => theme.text};
      ${media.sm`
       font-size:24px;
      `}
    }
    .usdValue {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: ${({ theme }) => theme.text};
    }
  }
  .gap {
    transform: translateX(5px);
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;

  ${media.sm`
    flex-direction: column;
  `}
`

export const ButtonCss = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 10rem;
  min-height: 3rem;
  color: ${({ theme }) => theme.text};
  padding: 8px 24px;
  outline: none;
  background: transparent;
  border-radius: 50px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
`

export const BuyNowBtn = styled.button`
  ${ButtonCss};
  background: ${({ theme }) => theme.brandColor};
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

export const MakeOfferBtn = styled.button`
  ${ButtonCss};
  border: 2px solid ${customColors.lightGrey};
  :disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  .label {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: ${themeDark.white};
  }
`
export const PriceDiv = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 80px 10px 80px;
  .label {
    color:  ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};;
    margin-bottom: 8px;
  }

  ${media.sm`
    padding: 16px 20px;
  `};
  ${media.s`
  padding:16px 2px;
  `}
`
export const BalanceDiv = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.text};
  padding: 0px 80px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  letter-spacing: -0.333333px;
  .error-text {
    color: ${customColors.errorText};
    font-size: 14px;
  }

  ${media.sm`
    padding: 0px 20px;
    flex-direction:column;
  `};
`
export const ModifiedBalanceDiv = styled(BalanceDiv)`
  flex-direction: column;
  .items-center{
    display: flex;
    justify-content: space-between;
  }
`
export const PriceInputDiv = styled.div<any>`
  width: 100%;
  background: ${themeDark.assetDetail.backArrowBg};
  border-radius: 10px;
  display: flex;
  position: relative;
  background: ${({ themeMode }) => (themeMode === 'light' ? 'rgb(236, 236, 238)' : 'transparent')};
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  ${media.sm`
  flex-direction:column;
  gap:10px;
  `}
  .dropdown {
    color: ${themeDark.white};
    min-width: 8rem;
    background: ${({ theme }) => theme.brandColor};
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.brandColor};
    border-radius: 10px 0px 0px 10px;
    cursor: pointer;
    .arrowIcon {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '')};
    }
    &:disabled {
      pointer-events: none;
    }
    ${media.sm`
    width:100%;
    border-radius:10px;
    `}
  }

  .input-wrapper {
    background-color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.bgTertiary : themeDark.bgTertiary)};
    flex: 1;
    display: flex;
    border-left: none;
    border-radius: 0px 10px 10px 0px;
    ${media.sm`
    width:100%;
    outline:none;
    border-left:1px solid ${themeDark.textTertiary};
    `}
  }

  .error {
    border: 1px solid ${customColors.errorText};
    background: ${({ themeMode }) => (themeMode === 'light' ? '' : colors.bgColor)};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;
  }
  .optionsDiv {
    position: absolute;
    z-index: 3;
    top: 60px;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
    // gap: 12px;
    // width: 25%;
    background: ${({ themeMode }) =>
      themeMode === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.backArrowBg};

    border-radius: 10px;
    border: 1px solid
      ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.border)};
    cursor: pointer;
    transition: all linear 0.2s;

    .option {
      padding: 8px 25px;
      border-radius: 10px;
      display: flex;
      cursor: pointer;
      :hover {
        background: ${themeDark.brandColor};
        color: white;
      }
    }
    ${media.sm`
    top:30%;
    width:90%;
    border-radius:10px;
    `}
  }
  .inputDiv {
    padding: 16px;
    flex-grow: 1;
    input {
      color: ${({ theme }) => theme.text};
      outline: none;
      border: none;
      background-color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.bgTertiary : 'transparent')};
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }
  .label {
    padding: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${themeDark.textTertiary};
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    .line {
      border-left: 1px solid rgba(196, 196, 196, 0.2);
      margin-right: 20px;
    }

    .dollar {
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: right;
    }
  }
`

export const ExpirationDiv = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 80px;
  gap: 10px;
  .label {
    /* margin-bottom: 16px; */
    color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
    margin-top: 25px;
  }

  ${media.sm`
    padding: 16px 20px;
  `};
`
export const PriceDropDownDiv = styled.div<any>`
  display: flex;
  align-items: space-between;
  gap: 20px;
  flex: 1 1;
  .selectField {
    width: 100%;
  }
  .timeField {
    width: 100%;
  }

  ${media.sm`
    flex-direction: column;
  `};
`
export const TimeInputField = styled.input<any>`
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.backArrowBg};
  color: ${({ theme }) => theme.text};
  outline: none;
  width: 100%;
  height: 55px;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  position: relative;
  border: none;
  /* border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.border)}; */
  cursor: pointer;
  transition: all linear 0.2s;
  &:hover {
    /* border: 1px solid
      ${({ themeMode }) =>
        themeMode === 'light' ? themeDark.textTertiary : themeDark.textSecondary}; */
  }
  ::-webkit-calendar-picker-indicator {
    /* color: rgba(0, 0, 0, 0); */
    display: block;
    top: 0;
    left: 0;
    background: transparent;
    position: absolute;
    transform: scale(12);
  }
`

export const ClockDiv = styled.div<any>`
  display: flex;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.backArrowBg};
  color: ${({ theme }) => theme.text};
  outline: none;
  width: 100%;
  height: 60px;
  border-radius: 10px;
  padding: 0 0 0 8px;
  cursor: pointer;
  position: relative;
  border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.border)};
  transition: all linear 0.2s;
  &:hover {
    border: 1px solid
      ${({ themeMode }) =>
        themeMode === 'light' ? themeDark.textTertiary : themeDark.textSecondary};
  }
`;

export const CheckBoxDiv = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${props => (props.padding ? props.padding : '25px 30px')};
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.333333px;
  }

  ${media.sm`
    padding: 16px 20px;
  `};
`
export const Link = styled.a<any>`
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.333333px;
  text-decoration: underline;
`

export const CheckBox = styled.input`
  border: 2px solid ${customColors.lightGrey};
  border-radius: 4px;
  background: transparent;
`

export const ButtonModalContainer = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 25px 0px 35px 0;
  border-top: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.borderColor : themeDark.borderColor)};
  ${media.sm`
  flex-direction:column;
  `}
`

export const ModalHeading = styled.div<any>`
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
`

export const ModalBody = styled.div<any>`
  padding: 30px;
  border-bottom: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.borderColor : themeDark.borderColor)};
`
export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px auto;
  grid-gap: 20px;
  align-items: center;
`

export const ModifiedItemWrapper = styled(ItemWrapper)`
  ${media.sm`
    grid-template-columns: 1fr;
  `}
`

export const ItemId = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: ${themeDark.assetDetail.cardTitle};
  margin: 10px 0;
`
export const ItemHeading = styled.h3<any>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 10px 0;
`

export const Total = styled.div`
  padding: 30px;
`
export const CreatorFee = styled.p<any>`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  margin: 10px 10px 10px 0;
`
export const Amount = styled.p<any>`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  margin: 10px;
`
export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`
