import { useAppSelector } from 'hooks/useAppSelector'
import { colors } from 'react-select/dist/declarations/src/theme'
import styled from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'
export const SelectFieldStyle = () => {
  const { theme } = useAppSelector(state => state.theme)
  const homePageStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected && 'transparent',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '5px',
      borderRadius: '10px',
      color:
        theme === 'light'
          ? state.isSelected
            ? themeDark.brandColor
            : themeDark.textTertiary
          : state.isSelected
          ? themeDark.brandColor
          : themeDark.textSecondary,
      transition: 'all linear 0.2s',
      '&:hover': {
        background: themeDark.brandColor,
        color: themeDark.white,
      },
    }),
    dropdownIndicator: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: () => ({
      cursor: 'pointer',
      padding: '0 15px 0 5px',
      display: 'flex',
      alignItems: 'center',
      width: '164px',
      height: 'auto',
      backgroundColor: theme === 'light' ? themeLight.bgTertiary : themeDark.createAndSellCardBg,
      borderRadius: '6px',
      transition: 'all linear 0.2s',
      border: theme === 'light' ? `1px solid ${themeLight.border}` : 'none',
      '&:hover': {
        border:
          theme === 'light'
            ? `1px solid ${themeLight.textTertiary}`
            : `1px solid ${themeDark.textSecondary}`,
      },
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '42px',
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '400',
      fontSize: '14px',
      width: '164px',
      height: 'auto',
      backgroundColor: theme === 'light' ? themeLight.bgTertiary : themeDark.createAndSellCardBg,
      borderRadius: '10px',
      overflow: 'hidden',
    }),

    container: (provided: any) => ({
      ...provided,
    }),

    valueContainer: (provided: any) => ({
      ...provided,
    }),
  }
  const profileSingleItemStyle = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: theme === 'light' ? themeDark.white : themeDark.assetDetail.background,
      cursor: 'pointer',
      display: 'flex',
      height: '42px',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: '10px',
      transition: 'all linear 0.2s',
      color:
        theme === 'light'
          ? state.isSelected
            ? themeLight.brandColor
            : themeDark.textTertiary
          : state.isSelected
          ? themeDark.brandColor
          : themeDark.textSecondary,
      fontWeight: state.isSelected && '600',
      '&:hover': {
        background: themeDark.brandColor,
        color: themeDark.white,
      },
    }),
    dropdownIndicator: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      marginTop: '5px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: () => ({
      cursor: 'pointer',
      padding: '0 15px 0 5px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '50px',
      backgroundColor: theme === 'light' ? themeDark.white : themeDark.assetDetail.background,
      borderRadius: '10px',
      border: theme === 'light' ? `1px solid ${themeLight.border}` : 'none',
      transition: 'all linear 0.2s',
      '&:hover': {
        border:
          theme === 'light'
            ? `1px solid ${themeLight.textTertiary}`
            : `1px solid ${themeDark.textSecondary}`,
      },
      '@media(max-width: 768px)': {
        height: '45px',
      },
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.assetDetail.cardTitle,
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '42px',
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '500',
      fontSize: '16px',
      width: '100%',
      height: 'auto',
      backgroundColor: theme === 'light' ? themeDark.white : themeDark.assetDetail.background,
      borderRadius: '10px',
      overflow: 'none',
      color: themeDark.textSecondary,
    }),

    container: (provided: any) => ({
      ...provided,
    }),

    valueContainer: (provided: any) => ({
      ...provided,
    }),
  }
  const profileRecentlyStyle = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: theme === 'light' ? themeDark.white : themeDark.assetDetail.background,
      cursor: 'pointer',
      display: 'flex',
      height: '42px',
      flexDirection: 'row',
      alignItems: 'center',
      transition: 'all linear 0.2s',
      color:
        theme === 'light'
          ? state.isSelected
            ? themeLight.brandColor
            : themeDark.textTertiary
          : state.isSelected
          ? themeDark.brandColor
          : themeDark.textSecondary,
      fontWeight: state.isSelected && '600',
      '&:hover': {
        background: themeDark.brandColor,
        color: themeDark.white,
      },
    }),
    dropdownIndicator: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      marginTop: '5px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: () => ({
      cursor: 'pointer',
      padding: '0 15px 0 5px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '50px',
      backgroundColor: theme === 'light' ? themeDark.white : themeDark.assetDetail.background,
      borderRadius: '10px',
      border: theme === 'light' ? `1px solid ${themeLight.border}` : 'none',
      transition: 'all linear 0.2s',
      '&:hover': {
        border:
          theme === 'light'
            ? `1px solid ${themeLight.textTertiary}`
            : `1px solid ${themeDark.textSecondary}`,
      },
      '@media(max-width: 768px)': {
        height: '45px',
      },
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.assetDetail.cardTitle,
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '42px',
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '500',
      fontSize: '16px',
      width: '100%',
      height: '100%',
      backgroundColor: theme === 'light' ? themeDark.white : themeDark.assetDetail.background,
      borderRadius: '10px',
      overflow: 'none',
      color: themeDark.textSecondary,
    }),
    placeholder: (base: any) => ({
      ...base,
      fontSize: '15px',
      color: themeDark.assetDetail.cardTitle,
      fontWeight: 400,
    }),

    container: (provided: any) => ({
      ...provided,
    }),

    valueContainer: (provided: any) => ({
      ...provided,
    }),
    menuList: (provided: any) => ({
      ...provided,
      minHeight: '478px',
      borderRadius: '10px',
      '::-webkit-scrollbar': {
        display: 'none',
      },
    }),
  }
  const sidenavStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected && 'transparent',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color:
        theme === 'light'
          ? state.isSelected
            ? themeDark.brandColor
            : themeDark.textTertiary
          : state.isSelected
          ? themeDark.brandColor
          : themeDark.textSecondary,
      transition: 'all linear 0.2s',
      '&:hover': {
        background: themeDark.brandColor,
        color: themeDark.white,
      },
    }),
    dropdownIndicator: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      marginTop: '5px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: () => ({
      cursor: 'pointer',
      padding: '0 15px 0 5px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 'auto',
      backgroundColor: themeDark.assetDetail.background,
      borderRadius: '6px',
      transition: 'all linear 0.2s',
      '&:hover': {
        border:
          theme === 'light'
            ? `1px solid ${themeLight.textTertiary}`
            : `1px solid ${themeDark.textSecondary}`,
      },
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '42px',
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '400',
      fontSize: '14px',
      width: '100%',
      height: 'auto',
      backgroundColor: themeDark.assetDetail.background,
      borderRadius: '10px',
      overflow: 'hidden',
    }),

    container: (provided: any) => ({
      ...provided,
    }),

    valueContainer: (provided: any) => ({
      ...provided,
    }),
  }
  const nftCreateStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected && 'transparent',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      // marginBottom: '5px',
      color:
        theme === 'light'
          ? state.isSelected
            ? themeDark.brandColor
            : themeDark.body_bg
          : state.isSelected
          ? themeDark.brandColor
          : themeDark.textSecondary,
      textTransform: 'capitalize',
      transition: 'all linear 0.2s',
      borderRadius: '8px',
      '&:hover': {
        background: themeDark.brandColor,
        color: themeDark.white,
      },
    }),
    dropdownIndicator: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      marginTop: '5px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),

    control: () => ({
      cursor: 'pointer',
      padding: '0 15px 0 5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '50px',
      backgroundColor: theme === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg,
      borderRadius: '10px',
      color: themeDark.white,
      textTransform: 'capitalize',
      border: theme === 'light' ? `1px solid ${themeLight.border}` : 'none',
      transition: 'all linear 0.2s',
      '&:hover': {
        border:
          theme === 'light'
            ? `1px solid ${themeLight.textTertiary}`
            : `1px solid ${themeDark.textSecondary}`,
      },
    }),
    placeholder: (base: any) => ({
      ...base,
      fontSize: '15px',
      fontWeight: 400,
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.body_bg : themeDark.white,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '42px',
      textTransform: 'capitalize',
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '400',
      fontSize: '14px',
      width: '100%',
      height: 'auto',
      backgroundColor: theme === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg,
      borderRadius: '10px',
      overflow: 'auto',
    }),

    input: (provided: any) => ({
      ...provided,
      color: theme === 'light' ? themeDark.body_bg : themeDark.white,
    }),

    container: (provided: any) => ({
      ...provided,
      color: themeDark.white,
    }),

    valueContainer: (provided: any) => ({
      ...provided,
      color: themeDark.white,
    }),
  }
  const sellPageStyle = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: theme === 'light' ? themeDark.white : themeDark.assetDetail.background,
      cursor: 'pointer',
      display: 'flex',
      height: '50px',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: '10px',
      // color: theme === 'light' ? themeDark.assetDetail.backArrowBg : themeDark.white,
      color:
        theme === 'light'
          ? state.isSelected
            ? themeDark.brandColor
            : themeDark.textTertiary
          : state.isSelected
          ? themeDark.brandColor
          : themeDark.textSecondary,
      fontWeight: state.isSelected && '600',
      transition: 'all linear 0.2s',
      ':active': {
        backgroundColor: theme === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg,
      },
      '&:hover': {
        background: themeDark.brandColor,
        color: themeDark.white,
      },
    }),
    dropdownIndicator: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      marginTop: '5px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: () => ({
      cursor: 'pointer',
      padding: '0 15px 0 5px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '50px',
      backgroundColor: theme === 'light' ? themeDark.white : themeDark.assetDetail.backArrowBg,
      borderRadius: '10px',
      color: theme === 'light' ? themeDark.assetDetail.backArrowBg : themeDark.white,
      border:
        theme === 'light'
          ? `1px solid ${themeLight.border}`
          : `1px solid ${themeLight.textTertiary}`,
      overflow: 'visible',
      transition: 'all linear 0.2s',
      '&:hover': {
        border:
          theme === 'light'
            ? `1px solid ${themeLight.textTertiary}`
            : `1px solid ${themeDark.textSecondary}`,
      },
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.assetDetail.cardTitle,
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '42px',
      '@media only screen and (max-width: 400px)': {
        fontSize: '14px',
      },
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '500',
      fontSize: '16px',
      width: '100%',
      height: 'fitContent',
      maxHeight: '600px',
      backgroundColor: theme === 'light' ? themeDark.white : themeDark.assetDetail.background,
      borderRadius: '10px',
      // overflow: 'hidden',
      overflowY: 'auto',
      // overflowY: 'scroll',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      zIndex: '999999',
    }),

    container: (provided: any) => ({
      ...provided,
    }),

    valueContainer: (provided: any) => ({
      ...provided,
    }),
    menuList: (provided: any) => ({
      ...provided,
      // minHeight: '478px',
      borderRadius: '10px',
      // '::-webkit-scrollbar': {
      //   display: 'none',
      // },
    }),
  }
  const nftDetailStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected && themeDark.brandColor,
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
      transition: 'all linear 0.2s',
      '&:hover': {
        background: themeDark.brandColor,
        color: themeDark.white,
      },
    }),
    dropdownIndicator: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: () => ({
      cursor: 'pointer',
      padding: '12px 15px 12px 5px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: 'auto',
      backgroundColor: theme === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.background,
      borderRadius: '10px',
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '42px',
    }),

    multiValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
      fontWeight: '400',
      fontSize: '14px',
      // lineHeight: '42px',
      background: theme === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.background,
      border: `1px solid ${themeLight.borderDark}`,
      borderRadius: '40px',
      padding: '4px 12px',
    }),

    multiValueLabel: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
    }),

    multiValueRemove: () => ({
      paddingLeft: '0px',
      paddingRight: '0px',
      boxSizing: 'border-box',
      background: themeDark.white,
      borderRadius: '100px',
      marginLeft: '8px',
      width: '15px',
      height: '15px',
      hover: {
        background: 'none',
        color: 'none',
      },
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '400',
      fontSize: '16px',
      width: '100%',
      height: 'auto',
      backgroundColor: theme === 'light' ? themeLight.bgTertiary : themeDark.bgColor,
      borderRadius: '10px',
      overflow: 'hidden',
      color: themeDark.textSecondary,
    }),

    container: (provided: any) => ({
      ...provided,
    }),

    valueContainer: (provided: any) => ({
      ...provided,
    }),
  }
  const priceHistoryStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected && 'transparent',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: '10px',
      color:
        theme === 'light'
          ? state.isSelected
            ? themeDark.brandColor
            : themeDark.textTertiary
          : state.isSelected
          ? themeDark.brandColor
          : themeDark.textSecondary,
      margin: '5px 0',
      transition: 'all linear 0.2s',
      '&:hover': {
        background: themeDark.brandColor,
        color: themeDark.white,
      },
    }),
    dropdownIndicator: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: () => ({
      cursor: 'pointer',
      padding: '12px 15px 12px 5px',
      display: 'flex',
      alignItems: 'center',
      width: 'fit-content',
      height: '40px',
      minWidth: '164px',
      backgroundColor: theme === 'light' ? themeLight.bgTertiary : themeDark.createAndSellCardBg,
      borderRadius: '10px',
      border: theme === 'light' ? `1px solid ${themeLight.border}` : 'none',
      transition: 'all linear 0.2s',
      '&:hover': {
        border:
          theme === 'light'
            ? `1px solid ${themeLight.textTertiary}`
            : `1px solid ${themeDark.textSecondary}`,
      },
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '42px',
    }),

    multiValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
      fontWeight: '400',
      fontSize: '14px',
      // lineHeight: '42px',
      background: theme === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.background,
      border: `1px solid ${themeLight.borderDark}`,
      borderRadius: '40px',
      padding: '4px 12px',
    }),

    multiValueLabel: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
    }),

    multiValueRemove: () => ({
      paddingLeft: '0px',
      paddingRight: '0px',
      boxSizing: 'border-box',
      background: themeDark.white,
      borderRadius: '100px',
      marginLeft: '8px',
      width: '15px',
      height: '15px',
      hover: {
        background: 'none',
        color: 'none',
      },
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '400',
      fontSize: '16px',
      width: 'fit-content',
      minWidth: '164px',
      backgroundColor: theme === 'light' ? themeLight.bgTertiary : themeDark.createAndSellCardBg,
      borderRadius: '10px',
      overflow: 'hidden',
      color: themeDark.textSecondary,
    }),

    container: (provided: any) => ({
      ...provided,
    }),

    valueContainer: (provided: any) => ({
      ...provided,
    }),
  }
  const chartSectionStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected && 'transparent',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: '10px',
      color:
        theme === 'light'
          ? state.isSelected
            ? themeLight.brandColor
            : themeDark.textTertiary
          : state.isSelected
          ? themeDark.brandColor
          : themeDark.white,
      transition: 'all linear 0.2s',
      '&:hover': {
        background: themeDark.brandColor,
        color: themeDark.white,
      },
    }),
    dropdownIndicator: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: () => ({
      cursor: 'pointer',
      padding: '0 15px 0 5px',
      display: 'flex',
      alignItems: 'center',
      width: '164px',
      height: 'auto',
      backgroundColor: theme === 'light' ? themeLight.bgTertiary : themeDark.bgTertiary,
      borderRadius: '6px',
      border:
        theme === 'light' ? `1px solid ${themeLight.border}` : `1px solid ${themeDark.border}`,
      // border: theme === "light" ? themeLight.border : themeDark.border,
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '42px',
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '400',
      fontSize: '14px',
      width: '250px',
      height: 'auto',
      backgroundColor: theme === 'light' ? themeLight.bgTertiary : themeDark.bgTertiary,
      borderRadius: '10px',
      overflow: 'hidden',
    }),

    container: (provided: any) => ({
      ...provided,
    }),

    valueContainer: (provided: any) => ({
      ...provided,
    }),
  }
  const saleAndOfferStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected && 'transparent',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '5px',
      color:
        theme === 'light'
          ? state.isSelected
            ? themeDark.brandColor
            : themeDark.textTertiary
          : state.isSelected
          ? themeDark.brandColor
          : themeDark.textSecondary,
      '&:hover': {
        background: themeDark.brandColor,
        color: themeDark.white,
      },
    }),
    dropdownIndicator: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: () => ({
      cursor: 'pointer',
      padding: '12px 15px 12px 5px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '60px',
      minWidth: '164px',
      border: theme === 'light' ? `1px solid ${themeLight.border}` : 'none',
      transition: 'all linear 0.2s',
      '&:hover': {
        border:
          theme === 'light'
            ? `1px solid ${themeLight.textTertiary}`
            : `1px solid ${themeDark.textSecondary}`,
      },
      backgroundColor:
        theme === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.backArrowBg,
      borderRadius: '10px',
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.body_bg : themeDark.white,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '42px',
    }),

    multiValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
      fontWeight: '400',
      fontSize: '14px',
      // lineHeight: '42px',
      background: theme === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.background,
      border: `1px solid ${themeLight.borderDark}`,
      borderRadius: '40px',
      padding: '4px 12px',
    }),

    multiValueLabel: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
    }),

    multiValueRemove: () => ({
      paddingLeft: '0px',
      paddingRight: '0px',
      boxSizing: 'border-box',
      background: themeDark.white,
      borderRadius: '100px',
      marginLeft: '8px',
      width: '15px',
      height: '15px',
      hover: {
        background: 'none',
        color: 'none',
      },
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '400',
      fontSize: '16px',
      width: '100%',
      minWidth: '164px',
      backgroundColor:
        theme === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.backArrowBg,
      borderRadius: '10px',
      overflow: 'hidden',
      color: themeDark.textSecondary,
    }),

    container: (provided: any) => ({
      ...provided,
    }),

    valueContainer: (provided: any) => ({
      ...provided,
    }),
  }
  const tokensDropdownStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected && 'transparent',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: '10px',
      color:
        theme === 'light'
          ? state.isSelected
            ? themeDark.brandColor
            : themeDark.textTertiary
          : state.isSelected
          ? themeDark.brandColor
          : themeDark.textSecondary,
      marginBottom: '5px',
      transition: 'all linear 0.2s',
      '&:hover': {
        background: themeDark.brandColor,
        color: themeDark.white,
      },
    }),
    dropdownIndicator: () => ({
      color: theme === 'light' ? themeDark.white : themeDark.white,
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: () => ({
      cursor: 'pointer',
      padding: '12px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '60px',
      minWidth: '100px',
      backgroundColor: theme === 'light' ? 'transparent' : 'transparent',
      borderRadius: '10px',
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.white : themeDark.white,
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '42px',
    }),

    multiValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
      fontWeight: '400',
      fontSize: '14px',
      // lineHeight: '42px',
      background: theme === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.background,
      border: `1px solid ${themeLight.borderDark}`,
      borderRadius: '40px',
      padding: '4px 12px',
    }),

    multiValueLabel: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
    }),

    multiValueRemove: () => ({
      paddingLeft: '0px',
      paddingRight: '0px',
      boxSizing: 'border-box',
      background: themeDark.white,
      borderRadius: '100px',
      marginLeft: '8px',
      width: '15px',
      height: '15px',
      hover: {
        background: 'none',
        color: 'none',
      },
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '400',
      fontSize: '16px',
      width: 'max-content',
      minWidth: '100%',
      backgroundColor:
        theme === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.backArrowBg,
      borderRadius: '10px',
      overflow: 'hidden',
      color: themeDark.white,
      marginTop: '0px',
    }),

    container: (provided: any) => ({
      ...provided,
    }),

    valueContainer: (provided: any) => ({
      ...provided,
    }),
  }
  const mintNewItemStyle = {
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor:
        theme === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.backArrowBg,
      cursor: 'pointer',
      display: 'flex',
      height: '42px',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: '10px',
      color:
        theme === 'light'
          ? state.isSelected
            ? themeDark.brandColor
            : themeDark.textTertiary
          : state.isSelected
          ? themeDark.brandColor
          : themeDark.white,
      fontWeight: state.isSelected && '600',
      '&:hover': {
        background: themeDark.brandColor,
        color: themeDark.white,
      },
    }),
    dropdownIndicator: () => ({
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      marginTop: '5px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: () => ({
      cursor: 'pointer',
      padding: '0 15px 0 5px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '60px',
      backgroundColor:
        theme === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.backArrowBg,
      borderRadius: '10px',
      border: theme === 'light' ? `1px solid ${themeLight.border}` : 'none',
      transition: 'all linear 0.2s',
      marginTop: '0px',
      '&:hover': {
        border:
          theme === 'light'
            ? `1px solid ${themeLight.textTertiary}`
            : `1px solid ${themeDark.textSecondary}`,
      },
    }),

    singleValue: (provided: any, state: any) => ({
      ...provided,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.white,
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '42px',
    }),

    menu: (provided: any, state: any) => ({
      ...provided,
      fontWeight: '500',
      fontSize: '16px',
      width: '100%',
      height: 'auto',
      backgroundColor:
        theme === 'light' ? themeLight.bgTertiary : themeDark.assetDetail.backArrowBg,
      borderRadius: '10px',
      overflow: 'none',
      color: theme === 'light' ? themeDark.textTertiary : themeDark.textSecondary,
      marginTop: '5px',
    }),

    container: (provided: any) => ({
      ...provided,
    }),

    valueContainer: (provided: any) => ({
      ...provided,
    }),
  }
  return {
    homePageStyles,
    profileSingleItemStyle,
    profileRecentlyStyle,
    sidenavStyles,
    nftCreateStyles,
    sellPageStyle,
    nftDetailStyles,
    priceHistoryStyles,
    chartSectionStyles,
    saleAndOfferStyles,
    tokensDropdownStyles,
    mintNewItemStyle,
  }
}

export const IconSingleValueWrapper = styled.div`
  margin-right: 4px;
  height: 20px;
  width: 20px;
  display: flex;
`
export const IconOptionWrapper = styled.div`
  margin-right: 10px;
  display: flex;
  /* align-items: center;
  justify-content: center;
  background-color: ${themeDark.white};
  border-radius: 50%;
  width: 26px;
  height: 26px;
  z-index: 100;
  padding: 3px; */

  img {
    border-radius: 50%;
  }
`
