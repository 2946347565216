import { erc20Abi } from './abi/erc20'
import type web3 from 'web3'

type TGetErc20Balance = {
  library: web3
  userAddress: string
  contractAddress: string
}

export const getErc20Balance = ({ library, userAddress, contractAddress }: TGetErc20Balance) => {
  const erc20Contract = new library.eth.Contract(erc20Abi, contractAddress)
  return erc20Contract.methods.balanceOf(userAddress).call()
}

export const getEthBalance = async ({
  library,
  userAddress,
}: Omit<TGetErc20Balance, 'contractAddress'>) => {
  return library.eth.getBalance(userAddress)
}
