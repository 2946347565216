import Link from 'next/link'
import styled from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'
import { media } from '../../../utilities/helpers'

export const AppFooter = styled.div<any>`
  background: ${({ theme }) => theme.footerBg};
  box-shadow: 0px -4px 21px rgba(149, 149, 149, 0.25);
`

export const FooterWrapper = styled.div<any>`
  width: 100%;
  padding: 50px 0;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1193px) {
    flex-direction: column;
  }
`
export const FooterLeftContainer = styled.div`
  .icon {
    width: 118px;
    height: 45px;
    object-fit: contain;
  }
  @media (max-width: 1193px) {
    display: grid;
    place-items: center;
  }
`
export const FooterRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1193px) {
    margin-top: 25px;
  }
`
export const FooterRightCommunity = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.lg`
      flex-direction : column;
     `}
`
export const FooterRightCommunityText = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  padding-right: 29px;
  ${media.md`
 padding-right : 0;
 `}
`
export const FooterLeftTextBlock = styled.div`
  width: 375px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  margin: 20px 0;
  @media (max-width: 1193px) {
    width: 100%;
    text-align: center;
  }
`
export const FooterRightIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 42px);
  grid-template-rows: 42px;
  grid-gap: 20px;
  a {
    cursor: pointer;
    transition: all linear 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
  ${media.sm`
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    margin:10px 0;
 `}
`

export const TwitterIcon = styled.div`
  background: linear-gradient(
    180deg,
    rgba(29, 49, 61, 0.5) 12.37%,
    #0b273a 60.38%,
    rgba(11, 51, 80, 0.5) 99.28%
  );
  box-shadow: inset 0px -5px 12px rgba(10, 102, 194, 0.35),
    inset 0px 2px 4px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */
  display: flex;
  justify-content: center;
  border-radius: 20px;
  width: 41.45px;
  height: 42px;
`

export const InstagramIcon = styled.div`
  background: linear-gradient(180deg, #31263a 0%, rgba(48, 6, 60, 0.896978) 33.85%, #000000 100%);
  box-shadow: inset 0px -5px 12px rgba(144, 14, 176, 0.35),
    inset 0px 2px 4px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */
  display: flex;
  justify-content: center;
  border-radius: 20px;
  width: 41.45px;
  height: 42px;
`
export const FacebookIcon = styled.div`
  background: rgba(45, 48, 61, 0.2);
  box-shadow: inset 0px -5px 12px rgba(11, 130, 203, 0.35),
    inset 0px 2px 4px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  display: flex;
  justify-content: center;
  border-radius: 20px;
  width: 41.45px;
  height: 42px;
`
export const YoutubeIcon = styled.div`
  background: rgba(45, 48, 61, 0.2);
  box-shadow: inset 0px -5px 12px rgba(186, 14, 14, 0.35),
    inset 0px 2px 4px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  display: flex;
  justify-content: center;
  border-radius: 20px;
  width: 41.45px;
  height: 42px;
`
export const LinkedinIcon = styled.div`
  background: linear-gradient(180deg, #0b1e26 0%, #0b3453 100%);
  box-shadow: inset 0px -5px 12px rgba(10, 102, 194, 0.35),
    inset 0px 2px 4px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  display: flex;
  justify-content: center;
  border-radius: 20px;
  width: 41.45px;
  height: 42px;
`

export const PipIcon = styled.div`
  background: rgba(45, 48, 61, 0.2);
  box-shadow: inset 0px -5px 12px rgba(195, 12, 31, 0.35),
    inset 0px 2px 4px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(30px);
  display: flex;
  justify-content: center;
  border-radius: 20px;
  width: 41.45px;
  height: 42px;
`

export const FooterRightLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  transform: translateX(0px);
  grid-gap: 77px;
  ${media.sm`
  grid-template-columns: repeat(2, 1fr);
  grid-gap:0;
  transform: translateX(0px);
 `}
  @media (max-width: 1193px) {
    transform: translateX(0px);
  }
`
export const FooterLink = styled.div<any>`
  margin-top: 46px;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    transition: all linear 0.2s;
    cursor: pointer;
    margin: 15px 0;
    :hover {
      color: ${themeDark.brandColor};
    }
  }
  ${media.md`
   text-align : center;
   margin-top:20px;
 `}
  a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin: 14px 0;
    text-decoration: none;
    color: ${props => props.theme.text};
    display: block;
    transition: all linear 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
`

export const FooterLinkTitleTile = styled.div`
  margin-top: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${themeDark.brandColor};
  ${media.md`
    margin-top:30px;
    `}
`

export const FooterLinkTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: ${themeDark.brandColor};
  margin-bottom: 15px;
`
export const StyledLink = styled(Link)<any>`
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  &:hover {
    color: ${themeDark.brandColor};
  }
`

export const FooterBottom = styled.div<any>`
  display: flex;
  justify-content: start;
  background: ${({ themeMode }) => (themeMode === 'light' ? themeLight.bgTertiary : 'transparent')};
  padding: 15px 70px;
  gap: 50px;
  border-top: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.borderColor : themeDark.assetDetail.backArrowBg)};
  ${media.md`
  display : block;
  text-align : center;
  padding:10px 0;
 `}
  a {
    font-weight: 500;
    font-size: 14px;
    color: ${themeDark.textSecondary};
    text-decoration: none;
    cursor: pointer;
  }
  .flex-box {
    /* display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 17px;

    ${media.md`
     grid-template-columns: repeat(1, auto);
     grid-gap:5px;
     margin:10px 0;
 `} */
  }
  @media (max-width: 1193px) {
    margin-top: 0px;
  }
`

export const FooterBottomFlexBox = styled.div<any>`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 17px;
  ${media.md`
    grid-template-columns: repeat(1, auto);
    grid-gap:5px;
    margin:10px 0;
 `}
  p {
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    color: ${({ themeMode }) =>
      themeMode === 'light' ? themeDark.textTertiary : themeDark.textSecondary};
    transition: all linear 0.2s;
    :hover {
      color: ${themeDark.brandColor};
    }
  }
`

export const FooterBottomTitle = styled.div<any>`
  font-weight: 500;
  font-size: 14px;
  color: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.textTertiary : themeDark.textSecondary};
  text-decoration: none;
`
