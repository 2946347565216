import { createSlice, current } from '@reduxjs/toolkit'

const filterValues = (array: any, value: string) => {
  const newFilters = array.filter((info: any) => info !== value)
  return newFilters
}

interface I_profileFilterState {
  eventType: Array<string>,
  collections: Array<Object>,
  chains: Array<Object>
}

const initialState: I_profileFilterState = {
  eventType: ['Sales'],
  collections: [],
  chains: [],
}

const activityFilterSlice = createSlice({
  name: 'activityFilters',
  initialState,
  reducers: {
    setEventTypeValues: (state: any, { payload }: any) => {
      const eventType = current(state.eventType)
      const response = eventType?.includes(payload)
      if (response) {
        const res = filterValues(eventType, payload)
        state.eventType = [...res]
      } else {
        state.eventType = [...state.eventType, payload]
      }
    },
    setCollectionValues: (state: any, { payload }: any) => {
      const collections = current(state.collections)
      const response = collections?.includes(payload)
      if (response) {
        const res = filterValues(collections, payload)
        state.collections = [...res]
      } else {
        state.collections = [...state.collections, payload]
      }
    },
    setChainValues: (state: any, { payload }: any) => {
      const chains = current(state.chains)
      const response = chains?.includes(payload)

      if (response) {
        const res = filterValues(chains, payload)
        state.chains = [...res]
      } else {
        state.chains = [...state.chains, payload]
      }
    },
    clearAllFilterValues: (state: any, { payload }: any) => {
      state.eventType = []
      state.collections = []
      state.chains = []
    },
  },
})

export const {
  setEventTypeValues,
  setChainValues,
  setCollectionValues,
  clearAllFilterValues
} = activityFilterSlice.actions
export default activityFilterSlice.reducer
