import {
  resource_discord,
  resource_instagram,
  resource_reddit,
  resource_twitter,
  resource_youtube,
} from 'shared/icons'
import { resourcesUrl } from 'shared/routes/routes'

export interface MenuDropDownsProps {
  title?: string
  link?: URL | any
}

export interface resourcesDropDownsProps {
  title?: string
  link?: URL | any
  isHasLink: boolean
}

export const menuLinks: MenuDropDownsProps[] = [
  {
    title: 'Rankings',
    link: '/rankings',
  },
  {
    title: 'Activity',
    link: '/activity',
  },
]
export const exploreLinks: MenuDropDownsProps[] = [
  {
    title: 'All NFTs',
    link: '/assets',
  },
  {
    title: 'Art',
    link: 'collection/art',
  },
  {
    title: 'Music',
    link: 'collection/music',
  },
  {
    title: 'Domain names',
    link: 'collection/domain-names',
  },
  {
    title: 'Photography',
    link: 'collection/photography',
  },
  {
    title: 'Sports',
    link: 'collection/sports',
  },
  {
    title: 'Trading Cards',
    link: 'collection/trading-cards',
  },
  {
    title: 'Utility',
    link: 'collection/utility',
  },
  {
    title: 'Virtual Worlds',
    link: 'collection/virtual-worlds',
  },
]

export const resourcesLinks: resourcesDropDownsProps[] = [
  {
    title: 'Help Center ',
    link: `${resourcesUrl.helpCenter}`,
    isHasLink: false,
  },
  {
    title: ' Platform Status',
    isHasLink: false,
    link: `${resourcesUrl.platformStatusUrl}`,
  },
  {
    title: ' Partners',
    link: '/partner',
    isHasLink: true,
  },
  {
    title: 'Gas-Free Marketplace',
    link: '/gasFree',
    isHasLink: true,
  },
  {
    title: ' Newsletter',
    link: '/newsLetter',
    isHasLink: true,
  },
]

export const resourcesShareIcon = [
  {
    img: resource_instagram,
    link: resourcesUrl.instagram,
  },
  {
    img: resource_twitter,
    link: resourcesUrl.twitter,
  },
  {
    img: resource_youtube,
    link: resourcesUrl.youtube,
  },
  {
    img: resource_discord,
    link: resourcesUrl.discord,
  },
  // {
  //   img: resource_reddit,
  //   link: resourcesUrl.reddit,
  // },
]
