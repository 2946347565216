import { warningRed } from 'shared/icons'
import styled, { keyframes } from 'styled-components'
import { media } from '../utilities/helpers'
import { customColors, themeDark, themeLight } from './theme'

export const FlexBox = styled.div<any>`
  display: flex;
  align-items: ${({ align }) => align || 'center'};
  justify-content: ${({ justify }) => justify || 'center'};
  width: ${({ width }) => width || 'auto'};
  margin: ${({ margin }) => margin || '0'};
`
export const Container = styled.div<any>`
  position: relative;
  width: ${props => (props.width ? props.width : '100%')};
  padding-right: var(1.5rem, 0.75rem);
  padding-left: var(1.5rem, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  padding: 0 10px;
  @media (min-width: 576px) {
    max-width: 90%;
  }
  @media (min-width: 768px) {
    max-width: 90%;
  }
  @media (min-width: 992px) {
    max-width: 90%;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media (min-width: 1400px) {
    max-width: 1320px;
  }
`

export const SectionHeader = styled.h1<any>`
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  color: ${({ theme }) => theme.sectionTitleColor};
  ${media.sm`
  font-size:32px;
  `}
`

export const FixDiv = styled.div<any>``
export const Arrows = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 120px !important;
`
export const ArrowIcon = styled.div<any>`
  width: 50px;
  height: 50px;
  border: ${({ isDisable, themeMode }) =>
    themeMode === 'light'
      ? isDisable
        ? `2px solid ${themeDark.textColor}`
        : `2px solid ${themeDark.brandColor}`
      : isDisable
      ? `2px solid ${themeDark.buttonColor}`
      : `2px solid ${themeDark.brandColor}`};
  border-radius: 40px;
  display: grid;
  place-items: center;
  cursor: ${({ isDisable }) => (isDisable ? 'not-allowed' : 'pointer')};
  position: absolute;
  top: ${({ topPosition }) => (topPosition === 'top' ? '-58px' : '-105px')};
  right: ${({ arrowType }) => (arrowType === 'left' ? '70px' : 0)};
  transition: all linear 0.2s;
  :hover {
    background: ${themeDark.brandColor};
    & > * {
      color: ${themeDark.white}!important;
    }
  }

  ${media.md`
 display : none;
  `}
`

export const PrimaryButton = styled.button<any>`
  width: ${props => (props.width ? props.width : '207px')};
  height: ${props => (props.height ? props.height : '60px')};
  background: ${({ theme }) => theme.brandColor};
  border-radius: ${props => (props.br ? props.br : '50px')};
  border: 0;
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  color: ${themeDark.white};
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  min-width: 100px;
  margin: ${props => props.margin || '0'};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: 0 20px;
  &:hover {
    box-shadow: ${({ theme, disabled }) => (disabled ? '' : theme.buttonShadow)};
  }

  ${media.sm`
   min-width:150px;
    width:max-content;
   `}
  ${media.s`
    min-width:120px;
    width:max-content;
   `}
  &:disabled {
    cursor: not-allowed;
  }
  & > a {
    color: ${themeDark.white};
    text-decoration: none;
    width: 100%;
    height: 100%;
  }
`

export const SecondaryButton = styled(PrimaryButton)<any>`
  width: ${props => props.width};
  height: ${props => props.height};
  background: transparent;
  border: 1px solid ${customColors.black};
  color: ${customColors.black};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-around;
  &:hover {
    box-shadow: ${({ disabled }) => (disabled ? '' : themeDark.brandColor)};
  }
`
export const WarningButton = styled(PrimaryButton)<any>`
  background: ${({ theme }) => theme.warning};
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-around;
  &:disabled {
    cursor: not-allowed;
  }
  /* &:hover {
    box-shadow: ${({ theme, disabled }) => (disabled ? '' : '0 1px red')};
  } */
`
export const CancelSecondaryButton = styled(SecondaryButton)<any>`
  background: ${({ theme }) => theme.textTertiary};
  border: none;
  color: ${({ theme }) => theme.white};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: ${({ hide }) => (hide ? 'none' : 'flex')};
  align-items: center;
  justify-content: space-around;
  /* &:hover {
    box-shadow: ${({ theme, disabled }) => (disabled ? '' : theme.buttonShadow)};
  } */
`
export const Heading = styled.h1<any>`
  font-weight: 600;
  font-size: 40px;
  order: -1;

  ${media.sm`
      font-size: 30px;
      margin-bottom: 30px;
  `}
`
export const SkeletonLoadingAnimation = (theme: any) => keyframes`
  0% {
    background: ${theme === 'light' ? 'lightgrey' : '#2b3641'};
  }
  100% {
    background: ${theme === 'light' ? themeLight.bgTertiary : themeDark.textTertiary};
  }
`

export const LoadingCard = styled.div<any>`
  border: 2px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.trendingCardBg)};
  border-radius: 20px;
  width: ${({ width }) => `${width}px`} !important;
  height: ${({ height }) => `${height}px`} !important;
  overflow: hidden !important;
  position: relative;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.bgTertiary : themeDark.trendingCardBg};
  animation: ${props => SkeletonLoadingAnimation(props.themeMode)} 1s linear infinite alternate !important;

  ${media.sm`
      width: 100% !important;
  `}
`

export const LoadingCardThree = styled.div<any>`
  border: 2px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.trendingCardBg)};
  border-radius: 5px;
  width: ${({ width }) => `${width}`} !important;
  height: ${({ height }) => `${height}`} !important;
  overflow: hidden !important;
  position: relative;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.bgTertiary : themeDark.trendingCardBg};
  animation: ${props => SkeletonLoadingAnimation(props.themeMode)} 1s linear infinite alternate !important;
`

export const LoadingCardTwo = styled.div<any>`
  border: 2px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.trendingCardBg)};
  border-radius: 10px;
  height: ${props => (props.layout === 4 ? '396px' : '350px')};
  overflow: hidden !important;
  position: relative;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeLight.bgTertiary : themeDark.trendingCardBg};
  /* animation: ${SkeletonLoadingAnimation} 1s linear infinite alternate !important; */
  animation: ${props => SkeletonLoadingAnimation(props.themeMode)} 1s linear infinite alternate !important;
`
export const TwoColumns = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(2, 1fr);
  margin: 0;
`
export const ThreeColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0;
  gap: 10px;
  @media (max-width: 992px) {
    grid-template-columns: repeat(1, 1fr);
  }
`