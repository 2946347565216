import { useFormik } from 'formik'
import * as yup from 'yup'

import { useSubscribeMutation } from 'logic/reactQuery/userService'
import { useState, useEffect } from 'react'
import { AiOutlineArrowDown, AiOutlineArrowRight } from 'react-icons/ai'
import { Container } from 'styles/defaultStyles'
import { customColors } from 'styles/theme'
import {
  ErrorText,
  InputFieldButtonWrapper,
  SignUpButton,
  SignUpFooterContainer,
  SignUpFooterFAQArrowIcon,
  SignUpFooterFAQContainer,
  SignUpFooterFAQQuestion,
  SignUpFooterFAQWrapper,
  SignUpFooterLayout,
  SignUpFooterLeftHeader,
  SignUpFooterLeftParagraph,
  SignUpFooterRightHeader,
  SignUpInputField,
  StyledSpinner,
} from './SignUpFooter.style'
import CustomModal from '../customModal/CustomModal'
import { ModalFlex, SuccessText } from 'modules/newsLetter/NewsLetter.style'
import NextImage from '../nextImage/NextImage'
import { success } from 'shared/icons'
import { ImSpinner2 } from 'react-icons/im'
import { useFrequentQuestionsQuery } from 'logic/reactQuery/helpCenterService'
interface I_FAQ {
  question: string
  answer: string
}
const SignUpFooter = () => {
  const { data } = useFrequentQuestionsQuery()
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [previous, setPrevious] = useState<number | null>(null)
  const OpenDropDown = (index: number) => {
    setPrevious(activeIndex)
    setActiveIndex(index)
    activeIndex === previous && setPrevious(null)
  }
  const formik = useFormik({
    initialValues: { userEmail: '' },
    validationSchema: yup.object().shape({
      userEmail: yup.string().required('Email is required').email('Should be a valid email'),
    }),
    onSubmit: (values: any) => {
      mutate({ userEmail: values.userEmail })
    },
  })
  const { values, handleChange, handleSubmit, handleBlur, touched, errors, resetForm } = formik
  const { isLoading, mutate, status } = useSubscribeMutation()
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (status === 'success') {
      setShow(true)
      resetForm()
    } else if (status === 'error') {
      resetForm()
      // Show Error popup or message
    }
  }, [status])
  return (
    <SignUpFooterLayout>
      {show && (
        <CustomModal show={show} toggleModal={() => setShow(!show)}>
          <ModalFlex>
            <NextImage src={success} alt="" />
            <SuccessText>Thanks for signing up</SuccessText>
          </ModalFlex>
        </CustomModal>
      )}
      <Container>
        <SignUpFooterContainer>
          <div>
            <SignUpFooterLeftHeader>
              Get the Latest
              <br /> Jungle NFT Updates
            </SignUpFooterLeftHeader>
            <SignUpFooterLeftParagraph>
              Sign up for news about the hottest drops, marketplace
              <br /> trends, and much more
            </SignUpFooterLeftParagraph>
            <InputFieldButtonWrapper>
              <form onSubmit={handleSubmit}>
                <SignUpInputField
                  type="text"
                  name="userEmail"
                  placeholder="Enter Email Address Here"
                  value={values.userEmail}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <SignUpButton type="submit" disabled={!(formik.isValid && formik.dirty)}>
                  {isLoading ? (
                    <StyledSpinner>
                      <ImSpinner2 size={14} color={customColors.white} />
                    </StyledSpinner>
                  ) : (
                    'Sign Up'
                  )}
                </SignUpButton>
              </form>
            </InputFieldButtonWrapper>
            {touched.userEmail && errors.userEmail ? (
              <ErrorText>{errors.userEmail}</ErrorText>
            ) : null}
          </div>
          <div>
            <SignUpFooterRightHeader>
              Some answered FAQs to get you started:
            </SignUpFooterRightHeader>
            {data?.map((val: I_FAQ, index: number) => (
              <>
                <SignUpFooterFAQWrapper key={index}>
                  <SignUpFooterFAQContainer onClick={() => OpenDropDown(index)}>
                    <SignUpFooterFAQQuestion>{val?.question}</SignUpFooterFAQQuestion>
                    <SignUpFooterFAQArrowIcon>
                      {activeIndex === index && activeIndex !== previous ? (
                        <AiOutlineArrowDown size={20} color={customColors.white} />
                      ) : (
                        <AiOutlineArrowRight size={20} color={customColors.white} />
                      )}
                    </SignUpFooterFAQArrowIcon>
                  </SignUpFooterFAQContainer>
                  {activeIndex === index && activeIndex !== previous && (
                    <SignUpFooterFAQQuestion>{val?.answer}</SignUpFooterFAQQuestion>
                  )}
                </SignUpFooterFAQWrapper>
              </>
            ))}
          </div>
        </SignUpFooterContainer>
      </Container>
    </SignUpFooterLayout>
  )
}

export default SignUpFooter
