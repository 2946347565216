import { useAppSelector } from 'hooks/useAppSelector'
import React from 'react'
import Lottie from 'react-lottie'
import CustomModal from 'shared/components/customModal/CustomModal'
import { Container, FlexBox, SuccessMessage, SuccessText } from './PersonalInfo.style'
import success from '../../../public/assets/json/successMsg.json'
import pending from '../../../public/assets/json/pendingMsg.json'
import error from '../../../public/assets/json/errorMsg.json'
import { useRouter } from 'next/router'

interface I_Props {
  show: boolean
  toggle: any
  closeUploadDocsModal: any
  modalStatus: string
}
const SuccessModal = (props: I_Props) => {
  const { theme } = useAppSelector(state => state.theme)
  const { show, toggle, closeUploadDocsModal, modalStatus } = props
  const router = useRouter()

  const resetValues = () => {

    closeUploadDocsModal()
    toggle()
    router.push("/settings?tab=kyc")
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: modalStatus === 'pending' ? pending : modalStatus === 'success' ? success : error,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <>
      {show && (
        <CustomModal show={show} toggleModal={resetValues}>
          <>
            <Container>
              <Lottie options={defaultOptions} width={modalStatus === 'failed' ? 150 : 200} height={modalStatus === 'failed' ? 150 : 300} />
              <SuccessMessage themeMode={theme}> {
                modalStatus === 'pending' ? 'KYC session creation is on process' :
                  modalStatus === 'success' ? 'Request successfully submitted' :
                    router.query.success === 'false' ? 'Request Failed' :
                      'KYC session creation failed'
              } </SuccessMessage>
              <FlexBox justify="center">
                <SuccessText themeMode={theme}>
                  {
                    modalStatus === 'pending' ? 'It will take few momements to verify. Please dont close the page untill its completed.' :
                      modalStatus === 'success' ? 'Your identity verification process has been successfully submitted. You can check KYC status on your profile.' :
                        router.query.success === 'false' ? `Your identity verification process has been rejected.
                      Please try again.` :
                          'Please try again.'
                  }

                </SuccessText>
              </FlexBox>
            </Container>
          </>
        </CustomModal>
      )}
    </>
  )
}

export default SuccessModal
