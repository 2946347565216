import styled from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'

export const ToastWrapper = styled.div<any>`
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 30vw;
  padding: 15px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 10px;
  box-shadow: -10px 0px ${themeDark.brandColor};
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: all linear 0.2s;
  color: ${({ themes }) => (themes.light ? themeLight.text : themeDark.white)};
  background: ${({ themes }) => (themes.light ? themeDark.white : '#262626')};
`

export const ToasterContainer = styled.div`
  position: fixed;
  z-index: 10000 !important;
  bottom: 80px;
  right: 50px;
`
