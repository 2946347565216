import { useAppSelector } from 'hooks/useAppSelector'
import NextImage from 'shared/components/nextImage/NextImage'
import Link from 'next/link'
import React, { useState } from 'react'
import { valueContainerCSS } from 'react-select/dist/declarations/src/components/containers'
import {
  add,
  blueLeftArrow,
  platformImg,
  platformResponseTime,
  platformResponseTimeDark,
  supportServices,
  transactionProcessingTime,
  transactionProcessingTimeDark,
} from 'shared/icons'
import styled from 'styled-components'
import { Container, FlexBox, PrimaryButton } from 'styles/defaultStyles'
import { media, screenSizes } from 'utilities/helpers'
import {
  DateText,
  ImageWrap,
  InvestigationWrap,
  MainBody,
  PageHeader,
  PerformanceDiv,
  PerformanceWrapper,
  SubmissionWrap,
  IconImageWrap,
  DropDownWrapper,
  DropDownDiv,
  PageHeading,
  DateHeading,
  InfoText,
  DateInfoWrapper,
  ErrorHeading,
  ErrorSubHeading,
  ErrorInfoText,
  IncidentHistoryWrap,
  ChartWrapper,
  ChartInfoText,
} from './PlatformStatus.style'
import { themeDark } from 'styles/theme'

export const ModalWrapper = styled.div`
  padding: 20px;
  text-align: center;
`
export const SwitchModalText = styled.p<any>`
  font-weight: 500;
  font-size: 16px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.333333px;
  color: ${({ themeMode }) => (themeMode === 'light' ? '#606060' : themeDark.white)};
  margin-top: 15px;
  ${media.sm`
    font-size: 14px;
    line-height: 35px;
  `}
`
export const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
  flex-direction: column;
  gap: 10px;
  @media (min-width: ${screenSizes.sm}px) {
    flex-direction: row;
  }
`

const PlatformStatusScreen = () => {
  const [dropDown, setDropDown] = useState(false)
  const [network, setNetwork] = useState(false)

  const OpenSwitchNetworkModal = () => {
    setNetwork(!network)
  }
  const OpenDropDown = () => {
    setDropDown(!dropDown)
  }
  interface I_Error {
    date: string
    description: string
    error: string
    resolved: string
    resolved_description: string
    investigation: string
    investigation_description: string
    solvedDate: string
    monitoring: string
    monitoring_description: string
    update: string
    update_description: string
    identified: string
    identified_description: string
  }
  const data: I_Error[] = [
    {
      date: 'May 31,2022',
      description: 'No incidents reported today',
      error: '',
      resolved: '',
      resolved_description: '',
      investigation: '',
      investigation_description: '',
      solvedDate: '',
      monitoring: '',
      monitoring_description: '',
      update: '',
      update_description: '',
      identified: '',
      identified_description: '',
    },
    {
      date: 'May 30,2022',
      description: '',
      error: 'Programmatic order submission disabled',
      resolved: 'Resolved -',
      resolved_description: 'This incident has been resolved',
      investigation: 'Investigating -',
      investigation_description:
        'We have temporarily disabled API-based order submission on the platform while we investigate site-wide issues',
      solvedDate: 'May 26, 20:23 UTC',
      monitoring: '',
      monitoring_description: '',
      update: '',
      update_description: '',
      identified: '',
      identified_description: '',
    },
    {
      date: 'May 29,2022',
      description: 'No incidents reported today',
      error: '',
      resolved: '',
      resolved_description: '',
      investigation: '',
      investigation_description: '',
      solvedDate: '',
      monitoring: '',
      monitoring_description: '',
      update: '',
      update_description: '',
      identified: '',
      identified_description: '',
    },
    {
      date: 'May 28,2022',
      description: 'No incidents reported today',
      error: '',
      resolved: '',
      resolved_description: '',
      investigation: '',
      investigation_description: '',
      solvedDate: '',
      monitoring: '',
      monitoring_description: '',
      update: '',
      update_description: '',
      identified: '',
      identified_description: '',
    },
    {
      date: 'May 27,2022',
      description: 'No incidents reported today',
      error: '',
      resolved: '',
      resolved_description: '',
      investigation: '',
      investigation_description: '',
      solvedDate: '',
      monitoring: '',
      monitoring_description: '',
      update: '',
      update_description: '',
      identified: '',
      identified_description: '',
    },
    {
      date: 'May 30,2022',
      description: '',
      error: 'Programmatic order submission disabled',
      resolved: 'Resolved -',
      resolved_description: 'This incident has been resolved',
      investigation: 'Investigating -',
      investigation_description:
        'We have temporarily disabled API-based order submission on the platform while we investigate site-wide issues',
      solvedDate: 'May 26, 20:23 UTC',
      monitoring: 'Monitoring -',
      monitoring_description: ' A fix has been implemented and we are monitoring the results ',
      update: 'Update -',
      update_description: 'We are continuing to work on a fix for this issue',
      identified: 'Identified -',
      identified_description: 'An issue has been identified and we are implementing a fix ',
    },
  ]
  const { theme } = useAppSelector(state => state.theme)
  return (
    <>
      <PageHeader themeMode={theme}>
        <ImageWrap>
          <NextImage onClick={OpenSwitchNetworkModal} src={platformImg} />
        </ImageWrap>
      </PageHeader>
      <Container width="90%">
        <MainBody>
          <SubmissionWrap>
            <p>Programmatic order submission disabled</p>
            <p>Subscribe</p>
          </SubmissionWrap>
          <InvestigationWrap themeMode={theme}>
            <div>
              <h3>Investigating</h3>
              <DateText>May 29, 13:59 UTC</DateText>
            </div>
            <p>We are currently investigating this issue.</p>
          </InvestigationWrap>
          <PerformanceWrapper>
            <PerformanceDiv themeMode={theme}>
              <p>API</p>
              <span style={{ color: '#edc242' }}>Degraded Performance</span>
            </PerformanceDiv>
            <PerformanceDiv themeMode={theme}>
              <p>Alchemy API</p>
              <span>Operational</span>
            </PerformanceDiv>
            <PerformanceDiv themeMode={theme}>
              <p>Website</p>
              <span>Operational</span>
            </PerformanceDiv>
            <PerformanceDiv themeMode={theme}>
              <p>Mobile Application</p>
              <span>Operational</span>
            </PerformanceDiv>
            <PerformanceDiv themeMode={theme}>
              <FlexBox justify="space-between">
                <p>Support Services</p>
                <IconImageWrap>
                  <NextImage src={supportServices} />
                </IconImageWrap>
              </FlexBox>
              <span>Operational</span>
            </PerformanceDiv>
            <PerformanceDiv themeMode={theme}>
              <p>Email</p>
              <span>Operational</span>
            </PerformanceDiv>
            <PerformanceDiv themeMode={theme} onClick={OpenDropDown}>
              <FlexBox justify="space-between">
                <FlexBox>
                  <IconImageWrap>
                    <NextImage src={add} />
                  </IconImageWrap>
                  <p>Developer Testnets</p>
                </FlexBox>
                <span>{dropDown ? '' : 'Operational'}</span>
              </FlexBox>
              {dropDown && (
                <DropDownWrapper>
                  <DropDownDiv themeMode={theme}>
                    <p>Testnets API</p>
                    <span>Operational</span>
                  </DropDownDiv>
                  <DropDownDiv themeMode={theme}>
                    <p>Chain - Mumbai</p>
                    <span>Operational</span>
                  </DropDownDiv>
                  <DropDownDiv themeMode={theme}>
                      <p>Chain - Goerli</p>
                    <span>Operational</span>
                  </DropDownDiv>
                  <DropDownDiv themeMode={theme}>
                    <p>Chain -Baobab</p>
                    <span>Operational</span>
                  </DropDownDiv>
                </DropDownWrapper>
              )}
            </PerformanceDiv>
          </PerformanceWrapper>
          <ChartWrapper>
            <FlexBox justify="space-between">
              <PageHeading themeMode={theme}>System Metrics</PageHeading>
              <FlexBox>
                <ChartInfoText themeMode={theme}>Day</ChartInfoText>
                <ChartInfoText themeMode={theme}>Week</ChartInfoText>
                <ChartInfoText themeMode={theme}>Month</ChartInfoText>
              </FlexBox>
            </FlexBox>
            <NextImage src={theme === 'light' ? platformResponseTime : platformResponseTimeDark} />
            <NextImage
              src={theme === 'light' ? transactionProcessingTime : transactionProcessingTimeDark}
            />
          </ChartWrapper>
          <PageHeading themeMode={theme}>Past Incidents</PageHeading>
          {data.map((values: I_Error, index: number) => {
            return (
              <DateInfoWrapper>
                <DateHeading themeMode={theme}>{values.date}</DateHeading>
                <InfoText>{values.description}</InfoText>
                <Link href="/platformStatus/incidents">
                  {values?.error && (
                    <div>
                      <ErrorHeading>{values.error}</ErrorHeading>
                    </div>
                  )}
                </Link>
                {values?.resolved && (
                  <div>
                    <ErrorInfoText themeMode={theme}>
                      <ErrorSubHeading themeMode={theme}>{values.resolved}</ErrorSubHeading>
                      {values.resolved_description}
                    </ErrorInfoText>
                    <DateText themeMode={theme}>{values.solvedDate}</DateText>
                  </div>
                )}
                {values?.investigation && (
                  <div>
                    <ErrorInfoText themeMode={theme}>
                      <ErrorSubHeading themeMode={theme}>{values.investigation}</ErrorSubHeading>
                      {values.investigation_description}
                    </ErrorInfoText>
                    <DateText themeMode={theme}>{values.solvedDate}</DateText>
                  </div>
                )}
                {values?.update && (
                  <div>
                    <ErrorInfoText themeMode={theme}>
                      <ErrorSubHeading themeMode={theme}>{values.update}</ErrorSubHeading>
                      {values.update_description}
                    </ErrorInfoText>
                    <DateText themeMode={theme}>{values.solvedDate}</DateText>
                  </div>
                )}
                {values?.identified && (
                  <div>
                    <ErrorInfoText themeMode={theme}>
                      <ErrorSubHeading themeMode={theme}>{values.identified}</ErrorSubHeading>
                      {values.identified_description}
                    </ErrorInfoText>
                    <DateText themeMode={theme}>{values.solvedDate}</DateText>
                  </div>
                )}
              </DateInfoWrapper>
            )
          })}
          <IncidentHistoryWrap>
            <IconImageWrap>
              <NextImage src={blueLeftArrow} />
            </IconImageWrap>
            <p>Incident History</p>
          </IncidentHistoryWrap>
        </MainBody>
      </Container>
    </>
  )
}

export default PlatformStatusScreen
