import { createSlice } from '@reduxjs/toolkit'

interface I_UserState {
  isLoggedIn: boolean
  chainType: string | number
  isAccountLoading: boolean
  userFromLoginPage: boolean
  userFromSidebar: boolean
  isUserSwitchedWallet: boolean
}

const initialState: I_UserState = {
  isLoggedIn: false,
  chainType: '',
  isAccountLoading: false,
  userFromLoginPage: false,
  userFromSidebar: false,
  isUserSwitchedWallet: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoggedIn: (state: I_UserState, { payload }: { payload: boolean }) => {
      state.isLoggedIn = payload
    },

    setChainType: (state: I_UserState, { payload }: { payload: string | number }) => {
      state.chainType = payload
    },
    setAccountLoading: (state: I_UserState, { payload }: { payload: boolean }) => {
      state.isAccountLoading = payload
    },
    setUserFromLoginPage: (state: I_UserState, { payload }: { payload: boolean }) => {
      state.userFromLoginPage = payload
    },
    setUserFromSidebar: (state: I_UserState, { payload }: { payload: boolean }) => {
      state.userFromSidebar = payload
    },
    setIsUserSwitchedWallet: (state: I_UserState, { payload }: { payload: boolean }) => {
      state.isUserSwitchedWallet = payload
    },
  },
})
export const {
  setIsLoggedIn,
  setChainType,
  setAccountLoading,
  setUserFromLoginPage,
  setUserFromSidebar,
  setIsUserSwitchedWallet,
} = userSlice.actions
export default userSlice.reducer
