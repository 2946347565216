import { Text } from 'modules/sell/Sell.style'
import { ErrorCardContainer, ErrorImageContainer, ErrorText } from './ErrorBoundary.style'
import Lottie from 'react-lottie'
import Oops from '../../public/assets/svg/errorBoundary/oops.json'
import { useAppSelector } from 'hooks/useAppSelector'
import { themeDark, themeLight } from 'styles/theme'
import NextImage from 'shared/components/nextImage/NextImage'
import { errorBoundary } from 'shared/icons'
const ErrorCard = () => {
  const { theme } = useAppSelector(state => state.theme)
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Oops,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <ErrorCardContainer themeMode={theme}>
      <ErrorImageContainer>
        <NextImage src={errorBoundary} layout="responsive" objectFit="cover" />
      </ErrorImageContainer>
      <ErrorText
        themeMode={theme}
        fs="32px"
        fw="500"
        clr={theme === 'light' ? themeLight.text : themeDark.white}
        ta="center"
      >
        Sorry Request Failed
      </ErrorText>
      <ErrorText
        themeMode={theme}
        fs="24px"
        fw="500"
        clr={theme === 'light' ? themeLight.text : themeDark.white}
        ta="center"
      >
        It seems like we encountering some issue at our side. Please try again
      </ErrorText>
    </ErrorCardContainer>
  )
}

export default ErrorCard
