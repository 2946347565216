import { createSlice } from '@reduxjs/toolkit'

interface I_NetworkType {
  networkType: string, // testnet and mainnet
}

const initialState: I_NetworkType = {
  networkType: 'testnet',
}

const mintCollectionSlice = createSlice({
  name: 'mintCollection',
  initialState,
  reducers: {
    setNetworkType: (state: I_NetworkType, { payload }: { payload: string }) => {
      state.networkType = payload
    },
  },
})

export const { setNetworkType } = mintCollectionSlice.actions
export default mintCollectionSlice.reducer
