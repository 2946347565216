export const countries = [
  { label: 'Afghanistan', let2: ' AF', value: 'AFG' },
  { label: 'Albania', let2: 'AL', value: 'ALB' },
  { label: 'Algeria', let2: 'DZ', value: 'DZA' },
  { label: 'American Samoa', let2: 'AS', value: 'ASM' },
  { label: 'Andorra', let2: 'AD', value: 'AND' },
  { label: 'Angola', let2: 'AO', value: 'AGO' },
  { label: 'Anguilla', let2: 'AI', value: 'AIA' },
  { label: 'Antigua and Barbuda', let2: 'AG', value: 'ATG' },
  { label: 'Argentina', let2: 'AR', value: 'ARG' },
  { label: 'Armenia', let2: 'AM', value: 'ARM' },
  { label: 'Aruba', let2: 'AW', value: 'ABW' },
  { label: 'Australia', let2: 'AU', value: 'AUS' },
  { label: 'Austria', let2: 'AT', value: 'AUT' },
  { label: 'Azerbaijan', let2: 'AZ', value: 'AZE' },
  { label: 'Bahamas', let2: 'BS', value: 'BHS' },
  { label: 'Bahrain', let2: 'BH', value: 'BHR' },
  { label: 'Bangladesh', let2: 'BD', value: 'BGD' },
  { label: 'Barbados', let2: 'BB', value: 'BRB' },
  { label: 'Belarus', let2: 'BY', value: 'BLR' },
  { label: 'Belgium', let2: 'BE', value: 'BEL' },
  { label: 'Belize', let2: 'BZ', value: 'BLZ' },
  { label: 'Benin', let2: 'BJ', value: 'BEN' },
  { label: 'Bermuda', let2: 'BM', value: 'BMU' },
  { label: 'Bhutan', let2: 'BT', value: 'BTN' },
  { label: 'Bolivia', let2: 'BO', value: 'BOL' },
  { label: 'Bosnia and Herzegovina', let2: 'BA', value: 'BIH' },
  { label: 'Botswana', let2: 'BW', value: 'BWA' },
  { label: 'Brazil', let2: 'BR', value: 'BRA' },
  { label: 'British Virgin Islands', let2: 'VG', value: 'VGB' },
  { label: 'Brunei Darussalam', let2: 'BN', value: 'BRN' },
  { label: 'Bulgaria', let2: 'BG', value: 'BGR' },
  { label: 'Burkina Faso', let2: 'BF', value: 'BFA' },
  { label: 'Burundi', let2: 'BI', value: 'BDI' },
  { label: 'Cambodia', let2: 'KH', value: 'KHM' },
  { label: 'Cameroon', let2: 'CM', value: 'CMR' },
  { label: 'Canada', let2: 'CA', value: 'CAN' },
  { label: 'Cape Verde', let2: 'CV', value: 'CPV' },
  { label: 'Central African Republic', let2: 'CF', value: 'CAF' },
  { label: 'Chad', let2: 'TD', value: 'TCD' },
  { label: 'Chile', let2: 'CL', value: 'CHL' },
  { label: 'China', let2: 'CN', value: 'CHN' },
  { label: 'Hong Kong', let2: 'HK', value: 'HKG' },
  { label: 'Macao', let2: 'MO', value: 'MAC' },
  { label: 'Colombia', let2: 'CO', value: 'COL' },
  { label: 'Comoros', let2: 'KM', value: 'COM' },
  { label: 'Congo', let2: 'CG', value: 'COG' },
  { label: 'Costa Rica', let2: 'CR', value: 'CRI' },
  { label: "Côte d'Ivoire", let2: 'CI', value: 'CIV' },
  { label: 'Croatia', let2: 'HR', value: 'HRV' },
  { label: 'Cuba', let2: 'CU', value: 'CUB' },
  { label: 'Cyprus', let2: 'CY', value: 'CYP' },
  { label: 'Czech Republic', let2: 'CZ', value: 'CZE' },
  { label: 'Denmark', let2: 'DK', value: 'DNK' },
  { label: 'Djibouti', let2: 'DJ', value: 'DJI' },
  { label: 'Dominica', let2: 'DM', value: 'DMA' },
  { label: 'Dominican Republic', let2: 'DO', value: 'DOM' },
  { label: 'Ecuador', let2: 'EC', value: 'ECU' },
  { label: 'Egypt', let2: 'EG', value: 'EGY' },
  { label: 'El Salvador', let2: 'SV', value: 'SLV' },
  { label: 'Equatorial Guinea', let2: 'GQ', value: 'GNQ' },
  { label: 'Eritrea', let2: 'ER', value: 'ERI' },
  { label: 'Estonia', let2: 'EE', value: 'EST' },
  { label: 'Ethiopia', let2: 'ET', value: 'ETH' },
  { label: 'Faroe Islands', let2: 'FO', value: 'FRO' },
  { label: 'Fiji', let2: 'FJ', value: 'FJI' },
  { label: 'Finland', let2: 'FI', value: 'FIN' },
  { label: 'France', let2: 'FR', value: 'FRA' },
  { label: 'French Guiana', let2: 'GF', value: 'GUF' },
  { label: 'French Polynesia', let2: 'PF', value: 'PYF' },
  { label: 'Gabon', let2: 'GA', value: 'GAB' },
  { label: 'Gambia', let2: 'GM', value: 'GMB' },
  { label: 'Georgia', let2: 'GE', value: 'GEO' },
  { label: 'Germany', let2: 'DE', value: 'DEU' },
  { label: 'Ghana', let2: 'GH', value: 'GHA' },
  { label: 'Greece', let2: 'GR', value: 'GRC' },
  { label: 'Greenland', let2: 'GL', value: 'GRL' },
  { label: 'Grenada', let2: 'GD', value: 'GRD' },
  { label: 'Guadeloupe', let2: 'GP', value: 'GLP' },
  { label: 'Guam', let2: 'GU', value: 'GUM' },
  { label: 'Guatemala', let2: 'GT', value: 'GTM' },
  { label: 'Guinea-Bissau', let2: 'GW', value: 'GNB' },
  { label: 'Haiti', let2: 'HT', value: 'HTI' },
  { label: 'Honduras', let2: 'HN', value: 'HND' },
  { label: 'Iceland', let2: 'IS', value: 'ISL' },
  { label: 'Indonesia', let2: 'ID', value: 'IDN' },
  { label: 'India', let2: 'IN', value: 'IND' },
  { label: 'Iraq', let2: 'IQ', value: 'IRQ' },
  { label: 'Italy', let2: 'IT', value: 'ITA' },
  { label: 'Japan', let2: 'JP', value: 'JPN' },
  { label: 'Jordan', let2: 'JO', value: 'JOR' },
  { label: 'Kazakhstan', let2: 'KZ', value: 'KAZ' },
  { label: 'Kenya', let2: 'KE', value: 'KEN' },
  { label: 'Kiribati', let2: 'KI', value: 'KIR' },
  { label: 'Korea', let2: 'KP', value: 'PRK' },
  { label: 'Korea', let2: 'KR', value: 'KOR' },
  { label: 'Kuwait', let2: 'KW', value: 'KWT' },
  { label: 'Kyrgyzstan', let2: 'KG', value: 'KGZ' },
  { label: 'Lao PDR', let2: 'LA', value: 'LAO' },
  { label: 'Latvia', let2: 'LV', value: 'LVA' },
  { label: 'Lebanon', let2: 'LB', value: 'LBN' },
  { label: 'Lesotho', let2: 'LS', value: 'LSO' },
  { label: 'Liberia', let2: 'LR', value: 'LBR' },
  { label: 'Libya', let2: 'LY', value: 'LBY' },
  { label: 'Liechtenstein', let2: 'LI', value: 'LIE' },
  { label: 'Lithuania', let2: 'LT', value: 'LTU' },
  { label: 'Luxembourg', let2: 'LU', value: 'LUX' },
  { label: 'Madagascar', let2: 'MG', value: 'MDG' },
  { label: 'Malawi', let2: 'MW', value: 'MWI' },
  { label: 'Malaysia', let2: 'MY', value: 'MYS' },
  { label: 'Maldives', let2: 'MV', value: 'MDV' },
  { label: 'Mali', let2: 'ML', value: 'MLI' },
  { label: 'Malta', let2: 'MT', value: 'MLT' },
  { label: 'Marshall Islands', let2: 'MH', value: 'MHL' },
  { label: 'Martinique', let2: 'MQ', value: 'MTQ' },
  { label: 'Mauritania', let2: 'MR', value: 'MRT' },
  { label: 'Mauritius', let2: 'MU', value: 'MUS' },
  { label: 'Mexico', let2: 'MX', value: 'MEX' },
  { label: 'Micronesia, Federated States of', let2: 'FM', value: 'FSM' },
  { label: 'Moldova', let2: 'MD', value: 'MDA' },
  { label: 'Monaco', let2: 'MC', value: 'MCO' },
  { label: 'Mongolia', let2: 'MN', value: 'MNG' },
  { label: 'Montenegro', let2: 'ME', value: 'MNE' },
  { label: 'Montserrat', let2: 'MS', value: 'MSR' },
  { label: 'Morocco', let2: 'MA', value: 'MAR' },
  { label: 'Mozambique', let2: 'MZ', value: 'MOZ' },
  { label: 'Myanmar', let2: 'MM', value: 'MMR' },
  { label: 'Namibia', let2: 'NA', value: 'NAM' },
  { label: 'Nauru', let2: 'NR', value: 'NRU' },
  { label: 'Nepal', let2: 'NP', value: 'NPL' },
  { label: 'Netherlands', let2: 'NL', value: 'NLD' },
  { label: 'Netherlands Antilles', let2: 'AN', value: 'ANT' },
  { label: 'New Caledonia', let2: 'NC', value: 'NCL' },
  { label: 'New Zealand', let2: 'NZ', value: 'NZL' },
  { label: 'Nicaragua', let2: 'NI', value: 'NIC' },
  { label: 'Niger', let2: 'NE', value: 'NER' },
  { label: 'Nigeria', let2: 'NG', value: 'NGA' },
  { label: 'Northern Mariana Islands', let2: 'MP', value: 'MNP' },
  { label: 'Norway', let2: 'NO', value: 'NOR' },
  { label: 'Oman', let2: 'OM', value: 'OMN' },
  { label: 'Pakistan', let2: 'PK', value: 'PAK' },
  { label: 'Palau', let2: 'PW', value: 'PLW' },
  { label: 'Palestinian Territory', let2: 'PS', value: 'PSE' },
  { label: 'Panama', let2: 'PA', value: 'PAN' },
  { label: 'Papua New Guinea', let2: 'PG', value: 'PNG' },
  { label: 'Paraguay', let2: 'PY', value: 'PRY' },
  { label: 'Peru', let2: 'PE', value: 'PER' },
  { label: 'Philippines', let2: 'PH', value: 'PHL' },
  { label: 'Pitcairn', let2: 'PN', value: 'PCN' },
  { label: 'Poland', let2: 'PL', value: 'POL' },
  { label: 'Portugal', let2: 'PT', value: 'PRT' },
  { label: 'Puerto Rico', let2: 'PR', value: 'PRI' },
  { label: 'Qatar', let2: 'QA', value: 'QAT' },
  { label: 'Réunion', let2: 'RE', value: 'REU' },
  { label: 'Romania', let2: 'RO', value: 'ROU' },
  { label: 'Russian Federation', let2: 'RU', value: 'RUS' },
  { label: 'Rwanda', let2: 'RW', value: 'RWA' },
  { label: 'Saint Kitts and Nevis', let2: 'KN', value: 'KNA' },
  { label: 'Saint Lucia', let2: 'LC', value: 'LCA' },
  { label: 'Saint Vincent and Grenadines', let2: 'VC', value: 'VCT' },
  { label: 'Samoa', let2: 'WS', value: 'WSM' },
  { label: 'San Marino', let2: 'SM', value: 'SMR' },
  { label: 'Sao Tome and Principe', let2: 'ST', value: 'STP' },
  { label: 'Saudi Arabia', let2: 'SA', value: 'SAU' },
  { label: 'Senegal', let2: 'SN', value: 'SEN' },
  { label: 'Serbia', let2: 'RS', value: 'SRB' },
  { label: 'Seychelles', let2: 'SC', value: 'SYC' },
  { label: 'Sierra Leone', let2: 'SL', value: 'SLE' },
  { label: 'Singapore', let2: 'SG', value: 'SGP' },
  { label: 'Slovakia', let2: 'SK', value: 'SVK' },
  { label: 'Slovenia', let2: 'SI', value: 'SVN' },
  { label: 'Solomon Islands', let2: 'SB', value: 'SLB' },
  { label: 'Somalia', let2: 'SO', value: 'SOM' },
  { label: 'South Africa', let2: 'ZA', value: 'ZAF' },
  { label: 'Spain', let2: 'ES', value: 'ESP' },
  { label: 'Sri Lanka', let2: 'LK', value: 'LKA' },
  { label: 'Sudan', let2: 'SD', value: 'SDN' },
  { label: 'Suriname', let2: 'SR', value: 'SUR' },
  { label: 'Swaziland', let2: 'SZ', value: 'SWZ' },
  { label: 'Sweden', let2: 'SE', value: 'SWE' },
  { label: 'Switzerland', let2: 'CH', value: 'CHE' },
  { label: 'Syrian Arab Republic', let2: 'SY', value: 'SYR' },
  { label: 'Tajikistan', let2: 'TJ', value: 'TJK' },
  { label: 'Tanzania', let2: 'TZ', value: 'TZA' },
  { label: 'Thailand', let2: 'TH', value: 'THA' },
  { label: 'Timor-Leste', let2: 'TL', value: 'TLS' },
  { label: 'Togo', let2: 'TG', value: 'TGO' },
  { label: 'Tonga', let2: 'TO', value: 'TON' },
  { label: 'Trinidad and Tobago', let2: 'TT', value: 'TTO' },
  { label: 'Tunisia', let2: 'TN', value: 'TUN' },
  { label: 'Turkey', let2: 'TR', value: 'TUR' },
  { label: 'Turkmenistan', let2: 'TM', value: 'TKM' },
  { label: 'Tuvalu', let2: 'TV', value: 'TUV' },
  { label: 'Uganda', let2: 'UG', value: 'UGA' },
  { label: 'Ukraine', let2: 'UA', value: 'UKR' },
  { label: 'United Arab Emirates', let2: 'AE', value: 'ARE' },
  { label: 'United Kingdom', let2: 'GB', value: 'GBR' },
  { label: 'United States of America', let2: 'US', value: 'USA' },
  { label: 'Uruguay', let2: 'UY', value: 'URY' },
  { label: 'Uzbekistan', let2: 'UZ', value: 'UZB' },
  { label: 'Vanuatu', let2: 'VU', value: 'VUT' },
  { label: 'Venezuela', let2: 'VE', value: 'VEN' },
  { label: 'Viet Nam', let2: 'VN', value: 'VNM' },
  { label: 'Virgin Islands, US', let2: 'VI', value: 'VIR' },
  { label: 'Yemen', let2: 'YE', value: 'YEM' },
  { label: 'Zambia', let2: 'ZM', value: 'ZMB' },
  { label: 'Zimbabwe', let2: 'ZW', value: 'ZWE' },
]
