import styled from 'styled-components'
import { themeDark, themeLight } from 'styles/theme'
import { media } from '../../utilities/helpers'

export const textColor = {
  text: '#696b6c',
  light: '#373840',
  dark: '#646677',
  bgDark: '#1E2123',
  border: '#edc242'
} 

export const PageHeader = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ themeMode }) => (themeMode === 'light' ? themeLight.bgTertiary : textColor.bgDark)};
`
export const ImageWrap = styled.div``

export const MainBody = styled.div`
  max-width: 850px;
  width: 100%;
  margin: 50px auto;
`
export const SubmissionWrap = styled.div`
  background: ${textColor.border};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  p {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: ${themeDark.white};

    ${media.sm`
     font-size: 12px;
     line-height: 15px;
    `}
  }
`
export const InvestigationWrap = styled.div<any>`
  display: flex;
  padding: 20px;
  border: 1px solid ${textColor.border};
  P {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${({ themeMode }) =>
      themeMode === 'light' ? themeDark.assetDetail.background : themeDark.white};

    ${media.sm`
     font-size: 10px;
     line-height: unset;
    `}
  }
  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: ${({ themeMode }) =>
      themeMode === 'light' ? themeDark.assetDetail.background : themeDark.white};

    ${media.sm`
     font-size: 10px;
    `}
  }
`
export const DateText = styled.p<any>`
  font-weight: 500;
  font-size: 14px !important;
  line-height: 21px !important;
  color: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.assetDetail.cardTitle : themeDark.accordionBodyBg};
  margin: 5px 0;
  word-break: break-word;
`
export const PerformanceWrapper = styled.div`
  margin: 50px 0;
`
export const PerformanceDiv = styled.div<any>`
  border: 1px solid
    ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : themeDark.accordionBodyBg)};
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
  :nth-child(7) {
    cursor: pointer;
    display: block;
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${({ themeMode }) =>
      themeMode === 'light' ? themeDark.assetDetail.background : themeDark.white};
    margin: 0 5px;

    ${media.sm`
      font-size: 12px;
      line-height: unset;
    `}
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${themeDark.brandColor};
    :nth-child(1) {
      color: ${textColor.border};
    }

    ${media.sm`
      font-size: 12px;
    `}
  }
`
export const PerformanceText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${themeDark.brandColor};
`
export const IconImageWrap = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DropDownWrapper = styled.div`
  padding: 20px;
  padding-right: 0;

  ${media.sm`
    padding: 20px 0px;
  `}
`
export const DropDownDiv = styled.div<any>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  P {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: ${({ themeMode }) =>
      themeMode === 'light' ? themeDark.assetDetail.background : themeDark.white};
    margin: 0 5px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: ${themeDark.brandColor};
    :nth-child(1) {
      color: ${textColor.border};
    }
  }
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const PageHeading = styled.h1<any>`
  font-weight: 700;
  font-size: 28px;
  line-height: 42px;
  color: ${({ themeMode }) => (themeMode === 'light' ? textColor.light : themeDark.white)};
  margin: 20px 0;

  ${media.sm`
    font-size: 14px;
    line-height: unset;
  `}
`

export const DateHeading = styled.h1<any>`
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: ${({ themeMode }) => (themeMode === 'light' ? textColor.light : textColor.dark)};
  padding: 10px 0;
  border-bottom: 1px solid ${themeLight.border};

  ${media.sm`
      font-size: 12px;
    `}
`
export const InfoText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0;
  color: ${textColor.text};

  ${media.sm`
      font-size: 12px;
    `}
`
export const DateInfoWrapper = styled.div`
  margin-top: 30px;
`
export const ErrorHeading = styled.h1`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${textColor.border};
  margin: 10px 0;
  padding: 10px 0;
  cursor: pointer;

  ${media.sm`
      font-size: 12px;
    `}
`
export const ErrorSubHeading = styled.span<any>`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${({ themeMode }) =>
    themeMode === 'light' ? textColor.text : themeDark.assetDetail.cardTitle};
  padding: 10px 0;

  ${media.sm`
      font-size: 12px;
    `}
`
export const ErrorInfoText = styled(InfoText)<any>`
  padding: 0;
  margin: 10px 0;
  color: ${({ themeMode }) =>
    themeMode === 'light' ? textColor.text : themeDark.assetDetail.cardTitle};

  ${media.sm`
      font-size: 12px;
    `}
`
export const IncidentHistoryWrap = styled.div<any>`
  border-top: 1px solid ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : ' #4B4B57')};
  margin: 50px 0;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #379fff;
    margin: 0 5px;
    cursor: pointer;
    word-break: break-word;
  }
`

export const ErrorBody = styled.div`
  max-width: 850px;
  margin: 50px auto;
  width: 100%;
`
export const IncidentHeading = styled.h1`
  font-weight: 600;
  font-size: 46px;
  line-height: 65px;
  text-align: center;
  color: ${textColor.border};
  word-break: break-word;

  ${media.sm`
      font-size: 36px;
      line-height: 45px;
  `}
`
export const IncidentSubHeading = styled.h3<any>`
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  color: ${({ themeMode }) => (themeMode === 'light' ? ' #505054' : themeDark.white)};
  margin: 10px 0;
  word-break: break-word;
  text-align: center;

  ${media.sm`
      font-size: 25px;
      line-height: 40px;
  `}
`

export const GridBox = styled.div`
  display: grid;
  grid-template-columns: 2fr 10fr;
  grid-gap: 20px;
  align-items: start;
  margin: 20px 0;
  width: 100%;

  ${media.md`
     grid-template-columns: 1fr; 
  `}
`
export const ErrorStatus = styled.h3<any>`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${themeDark.assetDetail.background};
  color: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.assetDetail.background : themeDark.assetDetail.cardTitle};

  word-break: break-word;
`
export const IncidentInfo = styled.p<any>`
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: ${themeDark.assetDetail.background};
  color: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.assetDetail.background : themeDark.assetDetail.cardTitle};
  word-break: break-word;
`
export const AffectedWrap = styled.div<any>`
  border: 1px solid ${({ themeMode }) => (themeMode === 'light' ? themeLight.border : '#4B4B57')};
  background: ${({ themeMode }) =>
    themeMode === 'light' ? themeDark.white : themeDark.assetDetail.background};
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 30px 0;
  width: 100%;
  word-break: break-word;
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #848484;
  }
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const ChartWrapper = styled.div``

export const ChartInfoText = styled.p<any>`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 0 10px;
  color: ${({ themeMode }) => (themeMode === 'light' ? textColor.light : themeDark.white)};
  border-right: 1px solid ${themeLight.border};
  cursor: pointer;
  :hover {
  }
  :nth-child(3) {
    border-right: none;
  }

  ${media.sm`
      font-size: 12px;
    `}
`
export const ErrorFooter = styled.div`
  padding: 0 50px;

  ${media.sm`
      padding: 0 20px;
  `}
`
