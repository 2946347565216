import styled from 'styled-components'
import { customColors, themeDark, themeLight } from 'styles/theme'
import { media } from '../../utilities/helpers'

const color = {
  bg: '#0d0d0d',
  border: '#322323',
}
export const LoginLayout = styled.div<any>`
  margin: 80px 0 110px 0;
  ${media.sm`
   margin:20px 0 100px 0;
  `}
`

export const LoginContainer = styled.div<any>`
  max-width: 850px;
  margin: auto;
`

export const LoginTitle = styled.h1<any>`
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  letter-spacing: 0.909091px;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};

  ${media.sm`
    font-size: 24px;
    line-height: 36px;
    width: 90%;
    text-align: left;
    padding-left: 19px;
  `}
`

export const LoginDescription = styled.p<any>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.909091px;
  color: ${({ themeMode }) => (themeMode === 'light' ? themeLight.text : themeDark.white)};
  margin: 15px 0;
  span {
    color: ${themeDark.brandColor};
    cursor: pointer;
    transition: all linear 0.2s;
    :hover {
      color: ${themeLight.TextHover};
    }
  }

  ${media.sm`
    font-size: 12px;
    line-height: 18px;
    width: 90%;
    text-align: left;
    padding-left: 19px;
  `}
`

export const LoginWallets = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 30px 0 30px 0;
  height: ${({ showMoreOptions }) => (showMoreOptions ? 'auto' : '190px')};
  overflow: hidden;
`
export const LoginWalletProvider = styled.div<any>`
  width: 410px;
  height: 80px;
  background: ${({ themeMode }) => (themeMode === 'light' ? themeLight.bgTertiary : color.bg)};
  border: ${({ themeMode }) =>
    themeMode === 'light' ? `2px solid ${themeDark.brandColor}` : `1px solid ${color.border}`};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin: auto auto 20px auto;
  cursor: pointer;
  position: relative;
  transition: all linear 0.2s;
  :hover {
    background: ${({ themeMode }) =>
      themeMode === 'light' ? themeLight.inputHover : themeDark.assetDetail.backArrowBg};
    box-shadow: ${({ themeMode }) =>
      themeMode === 'light' ? 'none' : '0px 0px 10px rgba(0, 0, 0, 0.25)'};
  }
  ${media.sm`
    width: 90%;
  `}
`
export const LoginWalletProviderName = styled.h4<any>`
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.909091px;
  margin-left: 15px;
  flex: 1;

  ${media.sm`
    font-size: 16px;
  `}
`

export const LoginWalletProviderDescription = styled.p<any>`
  font-weight: 500;
  font-size: 14px;
  float: right;
  letter-spacing: 0.909091px;
  color: ${customColors.textColor};
`
export const ShowMoreButton = styled.div<any>`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoginWalletsLayer = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: ${color.bg}; */
  background: ${({themeMode}) => themeMode === 'light' ?  themeLight.border: color.bg};
  opacity: 0.4;
  z-index: 4;
  border-radius: 10px;
  cursor: not-allowed;
  border-radius: 18px;
`
