import { configureStore } from '@reduxjs/toolkit'
import userReducer from './slices/userSlice'
import themeReducer from './slices/themeSlice'
import profileFilterReducer from './slices/profileFiltersSlice'
import activityFilterReducer from './slices/activityFiltersSlice'
import mintCollectionReducer from './slices/mintCollectionSlice'
import counterOfferReducer from './slices/counterOfferSlice'

const store = configureStore({
  reducer: {
    /* The name of the reducer. */
    /* The name of the reducer. */
    user: userReducer,
    theme: themeReducer,
    profileFilters: profileFilterReducer,
    activityFilters: activityFilterReducer,
    mintCollection: mintCollectionReducer,
    counterOffer: counterOfferReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
})

export default store
