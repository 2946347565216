import { useAppSelector } from 'hooks/useAppSelector'
import Image from 'next/image'
import { useState } from 'react'
import { logo_dark, logo_light } from 'shared/icons'

interface I_MyImage {
  src: string
  alt?: string
  width?: number
  height?: number
  quality?: number
  layout?: any
  objectFit?: any
}

const NextImage = (props: any) => {
  const { src, alt, width, height, quality, layout, objectFit, ...other } = props
  const { theme } = useAppSelector(state => state.theme)

  const [imageUrl, setImageUrl] = useState<string>('')
  const [imageError, setImageError] = useState<boolean>(false)


  return src ? (
    <Image
      src={imageError ? (theme === 'light' ? logo_dark : logo_light) : src}
      alt={alt || ''}
      {...(width && width > 35 ? { placeholder: 'blur' } : {})}
      {...(width && width > 35 ? { blurDataURL: `${src}?w=${width}&q=${quality || 50}` } : {})}
      {...(width ? { width } : {})}
      {...(height ? { height } : {})}
      {...(objectFit ? { objectFit } : {})}
      {...(layout ? { layout } : {})}
      {...other}
      onError={(e: any) => {
        setImageError(true)
        console.log("imageError", e)
      }}
      onLoadStart={(e: any) => {
        setImageError(false)
      }}
    />
  ) : (
    <></>
  )
}

export default NextImage
