import { createSlice } from '@reduxjs/toolkit'

interface I_Counter {
  isCounterOffer: boolean
  buyerAddress: string
  isBundle: boolean
  bundleItems: []
  bundleInfo: {
    name: string
    description: string
  }
}

const initialState: I_Counter = {
  isCounterOffer: false,
  buyerAddress: '',
  isBundle: false,
  bundleItems: [],
  bundleInfo: {
    name: '',
    description: '',
  },
}

const counterOfferSlice = createSlice({
  name: 'counterOffer',
  initialState,
  reducers: {
    setIsCounterOffer: (state: I_Counter, payload: any) => {
      state.isCounterOffer = payload?.payload
    },
    setBuyerAddress: (state: I_Counter, payload: any) => {
      state.buyerAddress = payload?.payload
    },
    setBundleCounterInfo: (state: I_Counter, payload: any) => {
      state.isBundle = payload?.payload.isBundle
      state.bundleInfo = payload?.payload.bundleInfo
      state.bundleItems = payload?.payload.bundleItems
    },
  },
})

export const { setIsCounterOffer, setBuyerAddress, setBundleCounterInfo } =
  counterOfferSlice.actions
export default counterOfferSlice.reducer
