import { useAppSelector } from '../../../hooks/useAppSelector'
import React, { useState } from 'react'
import Lottie from 'react-lottie'
import CustomModal from '../../../shared/components/customModal/CustomModal'
import { Container, FlexBox, SuccessText } from './PersonalInfo.style'
import verification from '../../../public/assets/json/verification.json'
import { PrimaryButton } from '../../../styles/defaultStyles'
import PersonalInfo from './PersonalInfo'
// import PersonalInfo from './PersonalInfo'

interface I_Props {
  show: boolean
  toggle: any
}
const PendingVerification = (props: I_Props) => {
  const { theme } = useAppSelector(state => state.theme)
  const { show, toggle } = props
  const [verifyNow, setVerifyNow] = useState(false)
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: verification,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const resetValues = () => {
    toggle()

    setVerifyNow(false)
  }
  return (
    <>
      {show && (
        <CustomModal show={show} toggleModal={toggle} headerText="Identity Verification">
          <>
            <Container themeMode={theme} border={true}>
              <Lottie options={defaultOptions} width={300} height={250} />
              <SuccessText themeMode={theme}>
                Please submit your KYC for verification. Once your KYC is verified, you can proceed further.
              </SuccessText>
            </Container>
            <FlexBox margin="0 0 24px 0">
              <PrimaryButton onClick={() => setVerifyNow(true)}>Verify Now</PrimaryButton>
            </FlexBox>
          </>
        </CustomModal>
      )}
      <PersonalInfo show={verifyNow} toggle={resetValues} />

    </>
  )
}

export default PendingVerification
