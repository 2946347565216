import { createSlice } from '@reduxjs/toolkit'

type TThemeType = string
interface I_ThemeState {
  theme: TThemeType
}

const initialState: I_ThemeState = {
  theme: 'light',
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state: I_ThemeState, { payload }: { payload: TThemeType }) => {
      state.theme = payload
    },
  },
})

export const { setTheme } = themeSlice.actions
export default themeSlice.reducer
